import { RepeatOneSharp } from "@mui/icons-material"
import chalk, { Chalk } from "chalk"

export const timeSince = (date: Date): string => {
    const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000)
    let interval = Math.floor(seconds / 31536000)

    if (interval >= 1) {
        return `${interval} year${interval > 1 ? 's' : ''} ago`
    }

    interval = Math.floor(seconds / 2592000)
    if (interval >= 1) {
        return `${interval} month${interval > 1 ? 's' : ''} ago`
    }

    interval = Math.floor(seconds / 86400)
    if (interval >= 1) {
        return `${interval} day${interval > 1 ? 's' : ''} ago`
    }

    interval = Math.floor(seconds / 3600)
    if (interval >= 1) {
        return `${interval} hour${interval > 1 ? 's' : ''} ago`
    }

    interval = Math.floor(seconds / 60)
    if (interval >= 1) {
        return `${interval} minute${interval > 1 ? 's' : ''} ago`
    }

    return `${Math.floor(seconds)} seconds ago`
}

interface ErrorType {
    message: string
    code: string
}

export const formatLocalDate = (date: Date) => {
    return date.toLocaleString()
}

const stylizeValue = (value: any): any => {
    if (typeof value === 'string') {
        // const keyColorized = `\x1b[38591m${value}\x1b[0m`
        // return keyColorized
        return chalk.hex('#FFA500')(value)
    }
    return value
}

const stylizeKey = (key: string): string => {
    // xterm color the key
    // const keyColorized = `\x1b[38595m${key}\x1b[0m`
    // return keyColorized
    return chalk.blue(key)
}

type ObjType = {
    [key: string]: any
}

export const stylizeYaml = (obj: any): any => {
    // console.log({ obj }, 'stylizeYaml')
    if (Array.isArray(obj)) {
        return obj.map(stylizeYaml)
    }
    if (typeof obj === 'object' && obj !== null) {
        return Object.entries(obj).reduce((acc: ObjType, [key, value]) => {
            acc[stylizeKey(key)] = stylizeYaml(value)
            return acc
        }, {})
    }
    return stylizeValue(obj)
}

export const stylizeJson = (obj: any): any => {
    // console.log({ obj }, 'stylizeJson')
    if (Array.isArray(obj)) {
        return obj.map(stylizeJson)
    }
    if (typeof obj === 'object' && obj !== null) {
        return Object.entries(obj).reduce((acc: ObjType, [key, value]) => {
            acc[stylizeKey(key)] = stylizeJson(value)
            return acc
        }, {})
    }
    return stylizeValue(obj)
}

export const debug = (obj: any, label?: string): void => {
    if (process.env.REACT_APP_DEBUG !== 'true' || process.env.NODE_ENV === 'production') {
        return
    }
    if (label) {
        console.log(chalk.green(label))
    }
    console.log(stylizeJson(obj))
}

export async function fetcher(url: string, options = {}) {
    let response
    if (!options) {
        response = await fetch(url)
    } else {
        response = await fetch(url, options)
    }

    if (response.status === 400) {
        console.log('Bad Request')
        console.log(response.statusText)
        const responseJson = await response.json()
        const error = new CustomError('An error occurred while fetching the data.', response.status, responseJson)
        throw error
        // Handle 400 error
    } else if (response.status === 500) {
        console.log('Internal Server Error')
        // Handle 500 error
    } else if (!response.ok) {
        console.log(response.statusText)
        const error = new Error('An error occurred while fetching the data.')
        // Attach extra info to the error object.
        error.message = await response.json()
        throw error
    }
    const data = await response.json()
    return data
}

export const token = process.env.REACT_APP_TOKEN
export const sendfoxToken = process.env.REACT_SENDFOX_TOKEN