import emailjs from '@emailjs/browser';
const { Modal } = require("@mui/material");
const React = require("react");
// @ts-ignore
const bg = require("../../assets/img/bg/about-bg.jpeg");

// @ts-ignore
function ContactModal({ isOpen, handleClose }) {
    const [fromName, setFromName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [errors, setErrors] = React.useState({ name: '', email: '', message: '' });

    const validateForm = () => {
        let isValid = true;
        let errors = {};

        if (!fromName.trim()) {
            isValid = false;
            errors["name"] = "Please enter your name.";
        }

        if (!email.trim()) {
            isValid = false;
            errors["email"] = "Please enter your email.";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            isValid = false;
            errors["email"] = "Email address is invalid.";
        }

        if (!message.trim()) {
            isValid = false;
            errors["message"] = "Please enter your message.";
        }

        setErrors(errors);
        return isValid;
    };
    // @ts-ignore
    const handleSubmit = (event) => {
        event.preventDefault();

        if (validateForm()) {
            // example from "Test Your EmailJS Integration" in "EmailJS Dashboard"
            // emailjs.send("emailjs_la_rebelion_2403","template_contact_k1st",{
            //     fromName: "A user",
            //     email: "user@email.com",
            //     message: "I have these questions...",
            //     });

            // Other examples at https://www.emailjs.com/docs/examples/reactjs/

            // sending an email using the React.useEffect hook
            emailjs.send(
                'emailjs_la_rebelion_2403',
                'template_contact_k1st',
                { from_name: fromName, reply_to: email, message: message },
                'tbnaHa-9r08C32KIk'
            )
                .then((result) => {
                    console.log(result.text);
                    alert('Your message has been sent successfully!');
                }, (error) => {
                    console.log('FAILED sending email...', error.text);
                });
            setFromName('');
            setEmail('');
            setMessage('');
            handleClose();
        }
    };

    return (
        <Modal
            open={isOpen}
            onClose={() => handleClose()}
            style={{
                display: "flex",
                alignItems: "center", justifyContent: "center",
                background: `url(${bg}) no-repeat center center/cover`,
            }}
            className="pt-120 pb-60"
        >
            <div className="col-xl-6 col-lg">
                <div className="aai-contact-form mt-5">
                    <form>
                        <div className="row align-items-center justify-content-between">
                            <div className="col-xl-6">
                                <div className="aai-form-input">
                                    <input
                                        type="text"
                                        className="form-control shadow-none"
                                        placeholder="Full Name"
                                        value={fromName}
                                        onChange={(e) => setFromName(e.target.value)}
                                    />
                                    {errors["name"] && <p>{errors["name"]}</p>}
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="aai-form-input">
                                    <input
                                        type="email"
                                        className="form-control shadow-none"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {errors["email"] && <p>{errors["email"]}</p>}
                                </div>
                            </div>
                            <div className="col-xl-12">
                                <div className="aai-form-input">
                                    <textarea
                                        className="form-control shadow-none text-area"
                                        placeholder="Message"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                    />
                                    {errors["message"] && <p>{errors["message"]}</p>}
                                </div>
                            </div>
                            <div className="col-xl-6 mt-2 mb-5">
                                <button className="aai-btn btn-pill-solid" onClick={(e) => handleSubmit(e)}>
                                    Send Us A Message
                                </button>
                                <button className="aai-gradient-outline-btn ms-5" onClick={() => handleClose()}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
}

export default ContactModal;