"use client"
import React, { useEffect, useState } from "react";
import ProtoTypes from "prop-types";
import Link from "@mui/material/Link";
import bg from "../../assets/img/bg/content-bg.jpeg";

function Faq({ className }) {
  const [activeCollapse, setActiveCollapse] = useState("flush-collapseOne")
  useEffect(() => {
    const accrodionBtn = document.getElementsByClassName("accordion-button")
    for(let i=0; i<accrodionBtn.length;i++){
      if(accrodionBtn[i].attributes.name.nodeValue===activeCollapse){
        document.getElementById(activeCollapse)?.classList.add("accordion-collapse-show")
        document.getElementsByName(activeCollapse)[0]?.classList.add("accordion-button-show")
      }else{
        document.getElementById(accrodionBtn[i].attributes.name.nodeValue)?.classList.remove("accordion-collapse-show")
        document.getElementsByName(accrodionBtn[i].attributes.name.nodeValue)[0]?.classList.remove("accordion-button-show")
      }

    }
  }, [activeCollapse])

  const handleAccordion = (e)=>{
setActiveCollapse(activeCollapse===e.target.attributes.name.nodeValue?false:e.target.attributes.name.nodeValue)
  }

  const questions = [
    {
      question: "Why should I use K1sT?",
      answer: "K1sT is a terminal cloud native ready to use. Allows you to interact with your kubernetes clusters segregated in different environments and hybrid clouds. It is a simple and easy to use that allows you to start using Kubernetes clusters in 1 second, just access the terminal and start using it.",
    },
    {
      question: "Is it secure to use K1sT?",
      answer: "By default your cluster data, and connection settings won't leave your machine. Learn more about the architecture and security of K1sT to achive the best of your enterprise security and our cloud native terminal.",
      references: [{ description: "Architecture", reference: "https://docs.k1s.sh"}],
    },
    {
      question: "How can I access K1sT?",
      answer: "Just visit the k1s.sh homepage and start typing in the terminal, that's it!",
      references: [{ description: "Visit K1sT", reference: "https://k1s.sh"}],
    },
    {
      question: "Why is K1sT free?",
      answer: "We believe in the democratization of technology, and we want to make it accessible to everyone. We also have a paid version with more features and support, if you would like to support our mission consider upgrade your account or donate caffeine to our team.",
      references: [
        { description: "Upgrade", reference: "#get-started"},
        { description: "Donate Caffeine", reference: "https://www.buymeacoffee.com/larebelion"}
      ],
    },
    {
      question: "What is the difference between kubectl and K1sT?",
      answer: "K1sT is all about KX (Kubernetes eXperience) with developer and user experience in mind. We want to make it easy for everyone to use Kubernetes, even those without a technical background on Kubernetes. K1sT is accessible from any device, with NO installation required for end-users.",
      references: [
        { description: "Acces K1sT", reference: "https://k1s.sh"},
        { description: "KX?", reference: "https://docs.k1s.sh/kx"}
      ],
    },
    {
      question: "Can I use it on-premises?",
      answer: "Yes, we have a plan for on-premises installations. Contact us for more information.",
      // references: [{ description: "Contact Us", reference: "#contact"}],
    }
  ];
  return (

    <section className={`aai-faq pb-120 ${className}`}
    style={{
      background: `url(${bg}) no-repeat center center/cover`,
    }}>
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-xl-5 col-lg">
            <div>
              <h2 className="section-title mb-4">
                Any <span className="gradient-text"> Questions? </span> <br />
                We have Answers!
              </h2>
              <p className="section-desc">
                Don't find your answer here? just send us a message for any
                query.
              </p>
              <div className="mt-5">
                <Link href="https://github.com/la-rebelion/k1ssh/discussions/new?category=q-a" className="aai-gradient-outline-btn">
                  New Question
                </Link>
              </div>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg mt-5 mt-xl-0"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="aai-accordions">
              <div
                className="accordion accordion-flush"
              >
                {questions.map((qa, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header">
                      <button
                        name={`flush-collapse${index}`}
                        className="accordion-button"
                        type="button"
                        onClick={(e)=>handleAccordion(e)}
                      >
                        {qa.question}
                      </button>
                    </h2>
                    <div
                      id={`flush-collapse${index}`}
                      className="accordion-collapse"
                    >
                      <div className="accordion-body">
                        <p className="aai-accordion-text">
                          {qa.answer}
                        </p>
                      </div>
                      { qa.references && (<div className="d-flex flex-column flex-md-row aai-btns-group">
                          {qa.references?.map((ref, index) => (
                            <Link href={ref.reference} className="aai-gradient-outline-btn">{ref.description}</Link>
                          ))}
                      </div>)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
const Reference = (reference, description) => {
  return (
    <Link href={reference} className="aai-gradient-outline-btn">{description}</Link>
  );
};
Reference.propTypes = {
  description: ProtoTypes.string,
  reference: ProtoTypes.string,
};
Faq.propTypes = {
  className: ProtoTypes.string,
  question: ProtoTypes.string,
  answer: ProtoTypes.string,
  references: ProtoTypes.arrayOf(ProtoTypes.Reference),
};
export default Faq;
