"use client";
import React, { useEffect, useState } from "react";
import Link from "@mui/material/Link";
// @ts-ignore
import logo from "../../assets/img/logo/favicon-32x32.png";
import StickyMenu from "../../lib/StickyMenu";
//import Image from "next/image";

function Header({handleModal}) {
  useEffect(() => {
    StickyMenu();
  }, []);
  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <header className="header-primary aai-sticky">
      <div className="container">
        <nav className="navbar navbar-expand-xl justify-content-between">
          <Link href="/">
            <img
                    src={logo}
                    height={logo.height}
                    width={logo.width}
              alt="K1s Terminal"
            />
          </Link>
          <div
            className={`collapse navbar-collapse  ${mobileMenu ? "show" : ""}`}
            id="navbarNav"
          >
            <ul className="navbar-nav mx-auto">
              <li className="d-block d-xl-none">
                <div className="logo">
                  <Link href="/">
                    <img
                      src='/favicon-32x32.png'
                      height='32'
                      width='32'
                      alt="K1s Terminal"
                    />
                  </Link>
                </div>
              </li>
              {/* <li className="nav-item dropdown"> */}
              <li className="nav-item">
                {/* <Link
                  className="nav-link dropdown-toggle"
                  href="/"
                  role="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                > */}                
                <Link className="nav-link" href="/">
                  K1s Terminal
                </Link>
                {/* <div className="dropdown-menu">
                  <div className="d-flex flex-column flex-xl-row">
                    <ul>
                      <li>
                        <Link href="/" className="dropdown-item">
                          <span>Home Main</span>
                        </Link>
                      </li>

                      <li>
                        <Link href="/home-two" className="dropdown-item">
                          <span>Home Two</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="https://docs.k1s.me">
                  K1s Docs
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="#get-started">
                  Pricing
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" href="https://rebelion.la">
                  K1s Serverless
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" href="https://rebelion.la">
                  Blog
                </Link>
              </li>
              {/* <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  Blog
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link href="/blog" className="dropdown-item">
                      <span>Blog</span>
                    </Link>
                  </li>
                  <li>
                    <Link href="/blog-details" className="dropdown-item">
                      <span>Blog Details</span>
                    </Link>
                  </li>
                </ul>
              </li> */}
              <li className="nav-item">
                <Link className="aai-footer-nav-link nav-link" onClick={handleModal} >
                  Contact
                </Link>
              </li>
            </ul>
            <div className="d-flex align-items-center gap-4 mt-4">
              <div className="align-items-center aai-signup-in-links d-flex d-lg-none">
                <Link href="/login">Login</Link>
                <Link href="/register" className="aai-gradient-outline-btn">
                  Signup
                </Link>
              </div>
            </div>
          </div>
          <div className="navbar-right d-flex align-items-center gap-4">
            <div className="align-items-center aai-signup-in-links d-none d-lg-flex">
              <Link href="/login">Login</Link>
              <Link href="/register" className="aai-gradient-outline-btn">
                Signup{" "}
              </Link>
            </div>
            <button
              className={`navbar-toggler d-block d-xl-none ${
                mobileMenu ? "collapsed" : ""
              }`}
              onClick={() => setMobileMenu(!mobileMenu)}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded={mobileMenu}
              aria-label="Toggle navigation"
            >
              <span></span>
            </button>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
