import React, { useEffect } from "react"
import { Helmet } from "react-helmet-async"
// const HomeLandingPage = require("./landing-page/HomeLandingPage")
//import HomeLandingPage from "@/pages/landing-page/app/(sharedLayout)/page"
import "./landing-page/assets/css/font-awesome-all.min.css";
import "./landing-page/assets/css/bootstrap.min.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import AOS from "aos";
import "aos/dist/aos.css";
import './landing-page/assets/css/style.css'
import HomeLandingPage from "./landing-page/app/(sharedLayout)/page"

const AboutPage = (): JSX.Element => {
    useEffect(() => {
      AOS.init();
    }, []);
    return (
        <>
            <Helmet>
                <title> K1s, Simplyfing Kubernetes in 1 second </title>
                <script
                data-name="BMC-Widget"
                data-cfasync="false"
                src="https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js"
                data-id="larebelion"
                data-description="Support me on Buy me a coffee!"
                data-message="Thanks for your visit, you can support us with caffeine ☕"
                data-color="#BD5FFF"
                data-position="Right"
                data-x_margin="18"
                data-y_margin="18"
                ></script>
            </Helmet>
            <HomeLandingPage />
        </>
    )
}

export default AboutPage