import { CssBaseline } from '@mui/material'
import React from 'react'
import K1sAppBar from './K1sAppBar'
import K1sBottomNav from './K1sBottomNav'
import { Outlet } from 'react-router-dom'

// ==============================|| MINIMAL LAYOUT ||============================== //

const MainLayout = () => (
  <>
    <CssBaseline />
    <K1sAppBar />
    <Outlet />
    <K1sBottomNav />
  </>
)

export default MainLayout
