import React from "react";

interface IFrameFormProps {
  src: string;
  width: string;
  height: string;
  title?: string;
}

const IFrameForm: React.FC<IFrameFormProps> = ({ src, width, height, title }) => {
  return (
    <iframe
      src={src}
      width={width}
      height={height}
      title={title==="undefined" ? "" : title}
      scrolling="no"
      style={{ border: "none", borderRadius: "6px" }}
    />
  );
};

export default IFrameForm;