import { ClusterExt, SSLClientCertificate, createDefaultClusterExt } from '../model/ClusterModel'

export const clustersData: ClusterExt[] = [
  createDefaultClusterExt({
    name: 'k1s',
    serverName: 'k1s',
    hostname: 'api.k1s.me',
    port: 443,
    path: '/kubernetes',
    description: "K1s serverles cluster 🚀",
    masterNodes: 10,
    workerNodes: 100,
    status: "Ready",
    state: "Running",
    pods: 2,
    memory: "32 GB",
    cpu: "12 vCPU",
    storage: "1 TB",
    isFavorite: true,
    tags: ['HomeLab', 'Dev', 'QA'],
  }),
  createDefaultClusterExt({
    name: 'docker-desktop',
    serverName: 'docker-desktop',
    hostname: 'kubernetes.docker.internal',
    description: "Home lab cluster with Kubernetes in Docker Desktop 🚀",
    avatar2x: "https://www.vectorlogo.zone/logos/docker/docker-icon.svg",
    status: "Ready",
    state: "Running",
    pods: 2,
    memory: "8 GB",
    cpu: "4 vCPU",
    storage: "10 GB",
    isFavorite: true,
    tags: ['HomeLab', 'Dev', 'QA'],
  }),
  createDefaultClusterExt({
    name: 'k8s-local',    // through nginx proxy manager (to handle CORS)
    serverName: 'k8s-local',    // through nginx proxy manager (to handle CORS)
    protocol: 'http',
    hostname: 'localhost',
    port: 8080, //1443, //7443,
    description: "Home lab cluster with Kubernetes through a proxy",
    status: "Ready",
    state: "Running",
    pods: 2,
    memory: "3 GB",
    cpu: "2 vCPU",
    storage: "5 TB",
    isFavorite: true,
    tags: ['HomeLab', 'Dev', 'QA'],
  }),
  createDefaultClusterExt({
    // id: 1, // autoincrement
    name: "kube-cluster-1",
    description: "Cluster 1 at East Region",
    masterNodes: 5,
    workerNodes: 30,
    hostname: "10.0.0.1",
    connectionStatus: "InUse",
    pods: 10,
    memory: "300 GB",
    cpu: "400 vCPU",
    storage: "1000 GB",
    tags: ['East', 'Dev', 'QA'],
  }),
  createDefaultClusterExt({
    // id: 2,
    name: "cluster-2",
    description: "Cluster 2",
    avatar2x: "https://www.vectorlogo.zone/logos/rancher/rancher-icon.svg",
    masterNodes: 10,
    workerNodes: 50,
    hostname: "10.0.0.2",
    status: "Not Ready",
    connectionStatus: "Connected",
    state: "Stopped",
    pods: 5,
    memory: "500 GB",
    cpu: "1000 vCPU",
    storage: "5 TB",
    isFavorite: true,
    tags: ['West', 'Prod'],
  }),
  createDefaultClusterExt({
    // id: 3,
    name: "kube-cluster-3",
    description: "Cluster 3 for QA",
    masterNodes: 3,
    workerNodes: 10,
    hostname: "10.0.0.3",
    status: "Ready",
    state: "Running",
    connectionStatus: "Disconnected",
    pods: 2,
    memory: "200 GB",
    cpu: "100 vCPU",
    storage: "600 GB",
    tags: ['East', 'Dev'],
  }),
  createDefaultClusterExt({
    name: "k0s-rebelion",
    serverName: "k0s-rebelion",
    description: "Home lab cluster with k0s",
    avatar2x: "https://k0sproject.io/images/k0s-logo.svg",
    workerNodes: 2,
    // hostname: "k0s1-rebelion.mshome.net",
    hostname: "k0s",    // through nginx proxy manager (to handle CORS)
    protocol: 'http',   // through nginx proxy manager (to handle CORS)
    port: 8080,         // through nginx proxy manager (to handle CORS)
    status: "Ready",
    state: "Running",
    pods: 2,
    memory: "2 GB",
    cpu: "1 vCPU",
    storage: "5 GB",
    tags: ['HomeLab', 'Dev'],
  }),
  createDefaultClusterExt({
    name: "k3s-rebelion",
    serverName: "k3s-rebelion",
    description: "Home lab cluster with k3s",
    avatar2x: "https://k3s.io/img/k3s-logo-light.svg",
    workerNodes: 2,
    hostname: "k3s1-rebelion",
    status: "Ready",
    state: "Running",
    pods: 2,
    memory: "2 GB",
    cpu: "1 vCPU",
    storage: "5 GB",
    tags: ['HomeLab', 'Dev'],
  }),
  createDefaultClusterExt({
    name: "microk8s-rebelion",
    serverName: "microk8s-rebelion",
    description: "Home lab cluster with microk8s",
    avatar2x: "https://assets.ubuntu.com/v1/82818827-CoF_white.svg",
    workerNodes: 2,
    // hostname: "rebel.k1s-sh",
    hostname: "mk8s",
    // port: 16443,
    protocol: 'http',   // through nginx proxy manager (to handle the certiface in proxy)
    port: 80, //16443, // through nginx proxy manager (to handle CORS)
    status: "Ready",
    state: "Running",
    pods: 2,
    memory: "2 GB",
    cpu: "1 vCPU",
    storage: "5 GB",
    tags: ['HomeLab', 'Dev'],
    // create a SSLClientCertificate object instance for this cluster
    authMethod: {
      name: "microk8s",
      createdAt: new Date(),
      updatedAt: new Date(),
      ca: process.env.REACT_APP_MICROK8S_CA,
      certificate: process.env.REACT_APP_MICROK8S_CERT,
      key: process.env.REACT_APP_MICROK8S_KEY,
    } as SSLClientCertificate
  }),
  createDefaultClusterExt({
    name: "minikube-rebelion",
    serverName: "minikube-rebelion",
    description: "Home lab cluster with minikube",
    workerNodes: 2,
    hostname: "minikube-rebelion",
    status: "Ready",
    state: "Running",
    pods: 2,
    memory: "2 GB",
    cpu: "1 vCPU",
    storage: "5 GB",
    tags: ['HomeLab', 'Dev'],
  }),
]