"use client";
import React, { useEffect, useMemo, useState } from "react";
// @ts-ignore
import bg from "../../assets/img/hero/hero-bg-1.jpeg";
// @ts-ignore
// import dashboard from "../../assets/img/hero/dashboard-img.png";
import dashboard from "../../assets/img/hero/K1s-v0.2.0.gif";
// @ts-ignore
import dashboardTwo from "../../assets/img/hero/dashboard-img-2.png";
import Link from "@mui/material/Link";
//import Image from "next/image";

function Hero() {
  const content = useMemo(
    () => ["Define Clusters", "Manage Apps", "DevOps Kubepilot", "Run SOPs", "Knowledge Mgnt"],
    []
  );
  const [contents, setContents] = useState("");
  const [num, setNum] = useState(0);
  const [jumping, setJumping] = useState(false);

  useEffect(() => {
    let timeOut = 100;
    if (content[num][contents.length] === " ") {
      timeOut = 0;
    } else if (content[num].length === contents.length) {
      timeOut = 3000;
      setJumping(true);
    } else {
      timeOut = 100;
      setJumping(false);
    }
    setTimeout(() => {
      if (content[num].length <= contents.length) {
        setContents("");
        if (content[num + 1]) {
          setNum(num + 1);
        } else {
          setNum(0);
        }
      } else {
        setContents(contents + content[num][contents.length]);
        if (contents.length + 1 === content[num].length) {
          setJumping(true);
        } else if (content[num][contents.length] !== " ") {
          setJumping(!jumping);
        }
      }
    }, timeOut);
  }, [contents, content, num, jumping]);
  return (
    <section
      className="aai-hero-one position-relative"
      style={{
        background: `url(${bg}) no-repeat center center/cover`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-xl-7">
            <div className="aai-hero-one-content">
              <h1 className="aai-hero-one-title">
                Simplifying Kubernetes<br />
                <span className="gradient-text typing-animation">
                  {contents}
                  <span
                    style={{
                      opacity: 0,
                    }}
                  ></span>
                </span>
                <span
                  className={jumping ? "cursor" : ""}
                  style={{ background: "transparent", color: "white" }}
                >
                  |
                </span>
              </h1>
              <p className="aai-hero-one-desc">
                Elevate Cloud and DevOps with K1s Serverless, K1s Terminal, and K1s QBot.
                <br /> 
                <span className="gradient-text">K1s</span> is a Cloud Native
                Platform that provides a complete solution for accessing
                Kubernetes Clusters, Manage Apps, Accelerate DevOps, 
                Keep SOPs live, and Optimize Knowledge Management.
              </p>
              <div className="mt-5 d-flex flex-column flex-md-row aai-btns-group">
                <Link href="#get-started" className="aai-btn btn-pill-solid">
                  Get Started
                </Link>
                <Link href="#features" className="aai-gradient-outline-btn">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-10 col-xl-5 mt-5 mt-lg-0">
            <div className="aai-hero-one-img">
              <img
                // height={dashboard.height}
                // width={dashboard.width}
                height={951}
                width={704}
                src={dashboard}
                className="img-fluid aai-hero-img d-none d-xl-block"
                alt=""
              />
              <img
                height={dashboardTwo.height}
                width={dashboardTwo.width}
                src={dashboardTwo}
                className="img-fluid aai-hero-img d-xl-none"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
