import './App.css'
import ErrorBoundary from './components/ErrorBoundary'
import { Suspense } from 'react'
import React, { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { LearnByExamplesDrawerProps } from './components/LearnByExamplesDrawer'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './routes'
import K1sAppContext from './AppContext'
import { debug } from './Util'
import { HelmetProvider } from 'react-helmet-async'
import { UserProvider } from './context/AuthContext'
import { ServerProvider } from './context/TerminalContext'
// Use only in production mode
// import * as amplitude from '@amplitude/analytics-browser'
let amplitude: any
if (process.env.NODE_ENV === 'production') {
  amplitude = require('@amplitude/analytics-browser')
}

const App = (): JSX.Element => {
  const [open, setOpen] = useState(false)
  // to show welcome message only once per user if toggled
  const [dontShowWelcomeAgain, setDontShowWelcomeAgain] = useState(false)
  const [isHistoryDrawerOpen, setIsHistoryDrawerOpen] = useState(false)
  const [isBotDrawerOpen, setIsBotDrawerOpen] = useState(false)
  const [drawerWidth, setDrawerWidth] = useState(340)

  const theme = useTheme()
  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean
  }>(({ theme, open }) => ({
    flexGrow: 1,
    // padding: theme.spacing(5),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }))

  const handleDrawerOpen = () => {
    debug('handleDrawerOpen')
    setOpen(true)
  }
  const handleDrawerClose = () => {
    debug('handleDrawerClose')
    setOpen(false)
  }

  // initialize amplitude with user's api key on app load
  React.useEffect(() => {
    // track only if in production mode
    if (process.env.NODE_ENV === 'production') {
      amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY!, {
        defaultTracking: true,
      })
      amplitude.track('Page View')
    }
  }, [])

  const drawerHistoryProps = (): LearnByExamplesDrawerProps => {
    return {
      open: isHistoryDrawerOpen,
      handleDrawerOpen,
      handleDrawerClose,
      drawerWidth: 340,
    }
  }
  const drawerBotProps = (): LearnByExamplesDrawerProps => {
    return {
      open: isBotDrawerOpen,
      handleDrawerOpen,
      handleDrawerClose,
      drawerWidth: 500,
    }
  }

  // depending on the drawer name, we can have different actions
  const drawerNames = {
    'history': (open?: boolean) => {
      debug('drawerNames.history')
      debug(`open: ${open} isHistoryDrawerOpen: ${isHistoryDrawerOpen} isBotDrawerOpen: ${isBotDrawerOpen}`)
      if (open !== undefined) {
        setIsHistoryDrawerOpen!(open)
      }
      else {
        setIsHistoryDrawerOpen!(isHistoryDrawerOpen => !isHistoryDrawerOpen)
      }
      if (isHistoryDrawerOpen) {
        // if the history drawer is open, close the bot drawer
        setIsBotDrawerOpen!(false)
      }
      debug(`open: ${open} isHistoryDrawerOpen: ${isHistoryDrawerOpen} isBotDrawerOpen: ${isBotDrawerOpen}`)
      setDrawerWidth(drawerHistoryProps().drawerWidth)
    },
    'bot': (open?: boolean) => {
      debug('drawerNames.bot')
      if (open !== undefined) {
        setIsBotDrawerOpen!(open)
      }
      else {
        setIsBotDrawerOpen!(isBotDrawerOpen => !isBotDrawerOpen)
      }
      if (isBotDrawerOpen) {
        // if the bot drawer is open, close the history drawer
        setIsHistoryDrawerOpen!(false)
      }
      setDrawerWidth(drawerBotProps().drawerWidth)
    },
  }


  return (
    // To ensure that the components do not unmount when navigating between routes, Context proviers should be placed outside of the Router component and near the root. @LessonLearned
    <K1sAppContext.Provider
      value={{
        dontShowWelcomeAgain,
        setDontShowWelcomeAgain,
        isHistoryDrawerOpen,
        setIsHistoryDrawerOpen,
        isBotDrawerOpen,
        setIsBotDrawerOpen,
        drawerHistoryProps,
        drawerBotProps,
        drawerNames,
      }}
    >
      <UserProvider>
        <ServerProvider>
          <Router>
            <HelmetProvider>
              <ErrorBoundary fallback={<div>Something went wrong!</div>}>
                <Suspense fallback={<div>Loading...</div>}>
                  <Main open={open}
                    sx={{
                      left: 5, width: '100%',
                      ...(open && {
                        width: `calc(100% - ${drawerWidth}px)`,
                        transition: theme.transitions.create(['margin', 'width'], {
                          easing: theme.transitions.easing.easeOut,
                          duration: theme.transitions.duration.enteringScreen,
                        }),

                        marginRight: drawerWidth,
                      })
                    }}
                  >
                    <Routes />
                  </Main>
                </Suspense>
              </ErrorBoundary>
            </HelmetProvider>
          </Router>
        </ServerProvider>
      </UserProvider>
    </K1sAppContext.Provider>
  )
}

export default App
