import { PromiseExtended } from 'dexie'
import db from '../DBConfig'

export interface KubernetesOutput {
    id?: number
    line?: number // line represents the line number in the Terminal's buffer - can be deleted on every refresh/session
    kind: string
    name: string
    namespace?: string
    updatedAt: Date // last time the line was updated
    // @todo @idea updatedAt could be used to determine what was the server used to get the output based on the sessions
}

export class KubernetesOutputDAO implements KubernetesOutput {
    id?: number | undefined
    line?: number | undefined
    kind: string
    name: string
    namespace?: string | undefined
    updatedAt: Date = new Date()

    // kind and name are the composite key and are required mandatory
    constructor(kind: string, name: string, kubernetesOutput: Partial<KubernetesOutput> = {}) {
        this.kind = kind
        this.name = name
        Object.assign(this, kubernetesOutput)
    }

    // get the last line number for a given kind and name
    public static async getLastLineNumber(kind: string, name: string): Promise<number> {
        try {
            const kubernetesOutput = await db.kubernetesOutput
                .where(['kind', 'name'])
                .equals([kind, name])
                .last()
            return kubernetesOutput?.line ?? -1
        } catch (error) {
            console.error('❌ error getting last line number', error)
            return -1
        }
    }

    // get output for a given line number
    public static async getOutputForLine(line: number): Promise<KubernetesOutput[]> {
        try {
            const kubernetesOutput = await db.kubernetesOutput
                .where('line')
                .equals(line)
                .toArray()
            return kubernetesOutput
        } catch (error) {
            console.error('❌ error getting output for line', error)
            return []
        }
    }

    // Prototype method
    async save(): Promise<number> {
        try {
            // Will only save own props.
            return await db.kubernetesOutput.put(this)
        } catch (error: any) {
            if (error.name === 'ConstraintError') {
                console.log('ConstraintError (ignored), command output already exists. Updating the line number')
                // update the existing object (based on composite key)
                return await db.kubernetesOutput.where(['kind', 'name']).equals([this.kind, this.name]).modify({
                    line: this.line,    // @todo do we need line? Maybe we can just parse the output with mouseover
                    namespace: this.namespace,
                    updatedAt: this.updatedAt
                })
            }
            console.error('❌ error adding to db', error)
            return Promise.resolve(-1) as PromiseExtended<number>
        }
    }
}