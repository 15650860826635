'use client'

import React, { useContext, lazy, useEffect } from 'react'

// project import
import Loadable from '../../components/Loadable'
import { Link, useLocation, useMatch,
          useNavigate
        } from 'react-router-dom'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import * as MUIIcon from '@mui/icons-material'
import Paper from '@mui/material/Paper'
import { LearnByExamplesDrawerProps } from '../../components/LearnByExamplesDrawer'
import { useTheme } from '@mui/material/styles'
import ChallengesPage from '../../pages/ChallengesPage'
import ClustersPage from '../../pages/ClustersPage'
import SettingsPage from '../../pages/SettingsPage'
import K1sAppContext from '../../AppContext'
import {
  Divider,
  Modal,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  ModalDialog
} from '@mui/joy'
import { useUser } from '../../context/AuthContext'

const HomePage = Loadable(lazy(() => import('../../pages/HomePage')))
const TerminalPage = Loadable(lazy(() => import('../../pages/TerminalPage')))

interface NavItemProps {
  name: string
  icon: React.ElementType //IconType
  // component: NavProps     // @fixme - this is the generic type or NavItems, but it's not working - I need to find how differentiate between the two types of components
  component: NavComponent
}

interface NavProps {
}
interface NavComponent extends NavProps {
  component: React.ComponentType<any>
  page: string
}

interface NavDrawerProps extends NavProps {
  open: boolean
  onClose: () => void
}

export const LinkItems: Array<NavItemProps> = [
  { name: 'Home', icon: MUIIcon.HomeOutlined, component: { page: '/', component: TerminalPage } },
  { name: 'Challenges', icon: MUIIcon.EmojiEventsOutlined, component: { page: '/challenges', component: ChallengesPage } },
  { name: 'My Clusters', icon: MUIIcon.DatasetOutlined, component: { page: '/clusters', component: ClustersPage } },
  { name: "Favorites", icon: MUIIcon.Favorite, component: { page: '/favorites', component: HomePage } },
  // @fixme -change the page to the teams page and the component to the teams component, same for History
  { name: "Teams", icon: MUIIcon.GroupsSharp, component: { page: '/teams', component: HomePage } },
  { name: 'Settings', icon: MUIIcon.SettingsApplicationsOutlined, component: { page: '/settings', component: SettingsPage } },
]

const K1sBottomNav = (): JSX.Element => {
  const location = useLocation()
  const [value, setValue] = React.useState(location.pathname)
  const ref = React.useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const { isHistoryDrawerOpen,
    isBotDrawerOpen,
    drawerBotProps,
    drawerHistoryProps,
    drawerNames,
  } = useContext(K1sAppContext)
  const [openDialog, setOpenDialog] = React.useState(false)
  const navigate = useNavigate()
  const { isAuthenticated } = useUser()

  const matchRoot = useMatch('/')
  const matchTerminal = useMatch('/terminal')
  const isTerminalPage = matchRoot || matchTerminal

  useEffect(() => {
    setValue(location.pathname)
  }, [location.pathname])

  const handleDrawerOpen = (drawer: string) => {
    // callback function to open the drawer
    const callbackDrawerAction = drawerNames[drawer]
    try {
      callbackDrawerAction()//(isBotDrawerOpen)
    } catch (error) {
      console.error('Status Unknown')
    }
  }

  const handleLoggedInClose = () => {
    setOpenDialog(false)
    // if the Dialog is closed, we always want to send the user to login
    navigate('/login')
  }
  const handleLoggedInOpen = () => {
    setOpenDialog(true)
  }
  const handleChange = (event: any, newValue: React.SetStateAction<string>) => {
    if (isAuthenticated || newValue === '/' || newValue === '/terminal') {
      setValue(newValue)
    } else {
      handleLoggedInOpen()
    }
  }

  /**
   * Determines which drawer (bot, history commands) is open and returns the drawer properties
   * @returns the drawer properties
   */
  const getDrawer = (): LearnByExamplesDrawerProps => {
    const drawer = isBotDrawerOpen ? drawerBotProps() : isHistoryDrawerOpen ? drawerHistoryProps() : { drawerWidth: 0, open: false }
    return drawer
  }

  return (
    <Paper sx={
      {
        position: 'fixed', bottom: 0, left: 0, right: 0,
        // is either one of the drawers open? if so, we need to adjust the width of the bottom nav
        ...((isBotDrawerOpen || isHistoryDrawerOpen) && {
          width: `calc(100% - ${getDrawer().drawerWidth}px)`,
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),

          marginRight: getDrawer().drawerWidth,
        })
      }
    } elevation={20}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={handleChange}
      >
        {LinkItems.map((item, index) => (
          <BottomNavigationAction
            key={index}
            label={item.name}
            icon={<item.icon />}            
            // selected={item.component.page === useLocation().pathname}
            component={Link}
            to={item.component.page}
            value={item.component.page}
          />
        ))}
        {isTerminalPage && (
          <>
            <BottomNavigationAction key="historyNavAction" label="History" value="history" icon={<MUIIcon.Restore />} onClick={() => handleDrawerOpen("history")} /> {/* onClick handler to open the Drawer */}
            <BottomNavigationAction key="botNavAction" label="Assistant" value="assistant" icon={<MUIIcon.SmartToyOutlined />} onClick={() => handleDrawerOpen("bot")} /> {/* onClick handler to open the Drawer */}
          </>
        )}
      </BottomNavigation>

      <Modal
        open={openDialog}
        onClose={handleLoggedInClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClick={handleLoggedInClose}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            < MUIIcon.KeyRounded />
            Restricted Access
          </DialogTitle>
          <Divider />
          <DialogContent>
            These options are available only to logged in users. You can continue with Home/Terminal page.
          </DialogContent>
          <DialogActions>
            <Button variant="plain" color="neutral" onClick={() => setOpenDialog(false)}>
              OK
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </Paper>
  )
}

export default K1sBottomNav