import React, { createContext, useContext, useState } from 'react'

interface UserContextValue {
    role: string
    setRole: React.Dispatch<React.SetStateAction<string>>
    isAuthenticated: boolean
    setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
    user: {
        username: string
        email: string
        provider: string
    } | null
    setUser: React.Dispatch<React.SetStateAction<any>>        
}

// Default user is anonymous
export const anonymousUser = {
    username: 'anonymous-user-k1s-reserved',
    email: 'anonymous@rebelion.la',
    provider: 'email'
}

// I prefer to use the following line instead of the one below it, to create the context instance with a default value
// const UserContext = createContext<UserContextValue | null>(null)
const UserContext = 
        createContext<UserContextValue>({
            role: 'guest', 
            setRole: () => { }, 
            isAuthenticated: false, 
            setIsAuthenticated: () => { }, 
            user: null,
            setUser: () => { }
        })

// Store the user's authentication in the state
const UserProvider = ({ children }: { children: React.ReactNode }) => {
    const [role, setRole] = useState<string>('guest') // Default role is 'guest'
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [user, setUser] = useState(anonymousUser)
    
    // const addRole = (newRole: string) => {
    //     setRole(prevArray => [...prevArray, newRole])
    //   }

    return (
        <UserContext.Provider 
            value={{ 
                    role, 
                    setRole, 
                    isAuthenticated, 
                    setIsAuthenticated,
                    user,
                    setUser
            }}>
            {children}
        </UserContext.Provider>
    )
}

const useUser = () => useContext(UserContext)

export { UserProvider, useUser }