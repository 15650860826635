import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
  <Suspense>
    <Outlet />
  </Suspense>
)

export default MinimalLayout
