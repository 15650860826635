import { Suspense } from 'react'

// project import
import Loader from './Loader'
import React from 'react'

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (Component: React.ComponentType<any>) => (props: any) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  )

export default Loadable
