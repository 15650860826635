import React from 'react'
import { useUser } from '../context/AuthContext'
import { useNavigate, Route, useLocation } from 'react-router-dom'
import {
  Divider,
  Modal,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  ModalDialog
} from '@mui/joy'
import { OpenInBrowserRounded, CheckBoxRounded, GamesRounded } from '@mui/icons-material'

interface PrivateRouteProps {
  roles: string[]
  children: React.ReactNode
}

const PrivateRoute = ({ roles, ...props }: PrivateRouteProps) => {
  const user = useUser()
  const navigate = useNavigate()
  const location = useLocation()
  const [openDialog, setOpenDialog] = React.useState(false)

  const handleUpgrade = () => {
    setOpenDialog(false)
    navigate('/upgrade')
  }

  let params = new URLSearchParams()
  params.set("from", location.pathname)
  if (!user.isAuthenticated) {
    // is not authenticated, redirect to login page with state to show the user that they need to login to access the page
    navigate('/login?' + params.toString(), { state: { fromPrivateRoute: true } })
    return null
  }
  // does the user have the role needed to access the page?
  else if (roles.includes(user.role)) {
    return <Route {...props} />
  }
  // no role found, is authenticated, but not authorized, let the user know
  setOpenDialog(true)

  return (<Modal
    open={openDialog}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <ModalDialog variant="outlined" role="alertdialog">
      <DialogTitle>
        < GamesRounded />
        More Features
      </DialogTitle>
      <Divider />
      <DialogContent>
        You can access more features by upgrading your account.
      </DialogContent>
      <DialogActions>
        <Button variant="solid" onClick={() => setOpenDialog(false)}>
          < CheckBoxRounded />
          OK
        </Button>
        <Button variant="solid" color="success" onClick={handleUpgrade}>
          < OpenInBrowserRounded />
          I want to upgrade!
        </Button>
      </DialogActions>
    </ModalDialog>
  </Modal>)

}


export default PrivateRoute