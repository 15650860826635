import React from "react";
import ProtoTypes from "prop-types";
import Link from "@mui/material/Link";
// @ts-ignore
import bg from "../../assets/img/bg/aai-feature-bg.jpeg";
// @ts-ignore
import cardImg from "../../assets/img/features/fi-1.svg";
// @ts-ignore
import cardImg2 from "../../assets/img/features/fi-2.svg";
// @ts-ignore
import cardImg3 from "../../assets/img/features/fi-3.svg";
// @ts-ignore
import cardImg4 from "../../assets/img/features/fi-4.svg";
// @ts-ignore
import cardImg5 from "../../assets/img/features/fi-5.svg";
// @ts-ignore
import cardImg6 from "../../assets/img/features/fi-6.svg";
import FeatureCard from "../cards/FeatureCard";

// @ts-ignore
function Features({ className }) {
  return (
    <section
      className={className ? `aai-features ${className}` : "aai-features"}
      style={{ background: `url(${bg}) no-repeat center center/cover` }}
      id="features"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="aai-section-title mb-3 text-center mb-5">
              <h2 className="section-title">Kubernetes <span className="position-relative aai-cta-line z-3">in 1 second</span></h2>
              <p className="section-desc">
                With K1s, Kubernetes and DevOps tools are accessible to everyone whithout the need to install anything.
                It's a simple and easy to use that allows you to start using
                Kubernetes clusters in 1 second, just access the terminal and start using it.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <FeatureCard
            img={cardImg}
            title="Simplifying Kubernetes"
            desc="Democratize Kubernetes, making it accessible to all engineers."
          />
          <FeatureCard
            img={cardImg2}
            title="K8s eXperience (KX)"
            desc="Accelerate your operations with better KX, (DX + UX)."
          />
          <FeatureCard
            img={cardImg3}
            title="SOP Generator"
            desc="Say goodbye to the manual and repetitive tasks."
          />
          <FeatureCard
            img={cardImg4}
            title="Learn by Doing"
            desc="Practice and learn Kubernetes in a simulator and real environments."
          />
          <FeatureCard
            img={cardImg5}
            title="Real Collaboration"
            desc="No more silos, Dev and Ops working truly together."
          />
          <FeatureCard
            img={cardImg6}
            title="Kubepilot Assistant"
            desc="Your personal assistant for Kubernetes and Cloud Native."
          />
        </div>
        <div
          className="text-center mt-5"
          data-aos="fade-up"
          data-aos-delay="110"
        >
          <Link href="/" className="aai-btn btn-pill-solid">
            Check it live
          </Link>
        </div>
      </div>
    </section>
  );
}

Features.propTypes = {
  className: ProtoTypes.string,
};

export default Features;
