// https://dexie.org/docs/Tutorial/React

import Dexie, { DexieError, Table } from 'dexie'
import { Command, CommandDAO } from './model/CommandModel'
import { KubernetesOutput, KubernetesOutputDAO } from './model/KubernetesOutputModel'
import { populate } from './model/populate'
import { ClusterExt, ClusterDAO } from './model/ClusterModel'
import { UserOptions } from './model/UserOptionsModel'

// interface to store the sessions in the database
export interface Session {
  id?: number
  name?: string
  clusterId: number // Define the foreign key column here
  createdAt: Date
  updatedAt: Date
}

class K1sDexie extends Dexie {
  // 'commands' is added by dexie when declaring the stores()
  // We just tell the system this is the case
  commands!: Table<Command, number>
  // the cache table is used to store the output of kubectl commands
  kubernetesOutput!: Table<KubernetesOutput, number>
  // the sessions table is used to store the sessions in the database
  sessions!: Table<Session, number>
  // the clusters table is used to store the clusters in the database
  clusters!: Table<ClusterExt, number>
  userOptions!: Table<UserOptions, number>

  constructor() {
    super('k1sDatabase')
    this.init()
  }

  private init() {
    try {
      // Define the database schema - https://dexie.org/docs/Version/Version.stores()#schema-syntax
      // ++	Auto-incremented primary key
      // &	Unique index
      // *	Multi-entry index
      // [A+B]	Compound index or primary key
      const version = this.version(1).stores({ 
        // Define the stores property for the current version
        // only indexed fields can be used in where() queries
        commands: '++id, type, verb, resource, [command+namespace]',
        // Dexie will throw a ConstraintError with the message "Key already exists in the object store"
        kubernetesOutput: '++id, line, kind, name',
        // name is unique
        sessions: '++id, name&, clusterId',
        clusters: '++id, name&, protocol, hostname&, port, connectionStatus',
        userOptions: '++id, username&'
      })
      if (version.stores.length > 0) {
        console.log('📅 A database already exists %s', version.stores.name)
        console.log('Tables %s', this.tables.map(store => store.name).join(', '))
      }
      // mapping tables to class for data access
      this.sessions = this.table('sessions')
      this.clusters = this.table('clusters')
      this.kubernetesOutput = this.table('kubernetesOutput')
      this.commands = this.table('commands')
      this.userOptions = this.table('userOptions')
      console.log('🏁 database initiated %s', this.name)
      this.on('ready', () => {
        console.log('🚀 database ready %s', this.name)
        // mapping tables to class for data access
        this.commands.mapToClass(CommandDAO)
        this.kubernetesOutput.mapToClass(KubernetesOutputDAO)
        this.clusters.mapToClass(ClusterDAO)
        this.kubernetesOutput.clear()
      })
    } catch (error) {
      if (error instanceof TypeError) {
        console.error('❌ error opening db %s', error)
        return
      }
      // cast error to DexieError
      const dexieError = error as DexieError
      console.error('❌ error \'%s\' opening db %s', dexieError.name, error)
    }
    // this.sessions.clear()
    // this.clusters.clear()
  }

  ready(): Promise<void> {
    return new Promise<void>((resolve) => {
      // mapping tables to class for data access
      this.commands.mapToClass(CommandDAO)
      this.kubernetesOutput.mapToClass(KubernetesOutputDAO)
    })
  }

} // end of class K1sDexie

const db = new K1sDexie()

export default db
db.on('populate', populate)

export function resetDatabase() {
  // return db.transaction('rw', db.kubernetesOutput, async () => {
  return async () => {
    db.kubernetesOutput.clear()
    // await Promise.all(db.tables.map(dbTable => dbTable.clear()))
    await populate()
  }
}
