'use strict';

export interface Route {
  method: string;
  path: string;
  handler: string;
  config: {
    auth: boolean;
    policies: string[];
  };
}

// Controller k1s - #TODO use a controller depending on the path for: core, rbac, batch, etc.
const routes = [
    {
      method: 'GET',
      path: '/.well-known/openid-configuration',
      handler: 'k1sControllers.getServiceAccountIssuerOpenIDConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api',
      handler: 'k1sControllers.getCoreAPIVersionsCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1',
      handler: 'k1sControllers.getCoreV1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/componentstatuses',
      handler: 'k1sControllers.listCoreV1ComponentStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/componentstatuses/:name',
      handler: 'k1sControllers.readCoreV1ComponentStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/configmaps',
      handler: 'k1sControllers.listCoreV1ConfigMapForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/endpoints',
      handler: 'k1sControllers.listCoreV1EndpointsForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/events',
      handler: 'k1sControllers.listCoreV1EventForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/limitranges',
      handler: 'k1sControllers.listCoreV1LimitRangeForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces',
      handler: 'k1sControllers.listCoreV1NamespaceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces',
      handler: 'k1sControllers.createCoreV1NamespaceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/bindings',
      handler: 'k1sControllers.createCoreV1NamespacedBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/configmaps',
      handler: 'k1sControllers.deleteCoreV1CollectionNamespacedConfigMapCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/configmaps',
      handler: 'k1sControllers.listCoreV1NamespacedConfigMapCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/configmaps',
      handler: 'k1sControllers.createCoreV1NamespacedConfigMapCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/configmaps/:name',
      handler: 'k1sControllers.deleteCoreV1NamespacedConfigMapCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/configmaps/:name',
      handler: 'k1sControllers.readCoreV1NamespacedConfigMapCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/configmaps/:name',
      handler: 'k1sControllers.patchCoreV1NamespacedConfigMapCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/configmaps/:name',
      handler: 'k1sControllers.replaceCoreV1NamespacedConfigMapCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/endpoints',
      handler: 'k1sControllers.deleteCoreV1CollectionNamespacedEndpointsCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/endpoints',
      handler: 'k1sControllers.listCoreV1NamespacedEndpointsCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/endpoints',
      handler: 'k1sControllers.createCoreV1NamespacedEndpointsCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/endpoints/:name',
      handler: 'k1sControllers.deleteCoreV1NamespacedEndpointsCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/endpoints/:name',
      handler: 'k1sControllers.readCoreV1NamespacedEndpointsCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/endpoints/:name',
      handler: 'k1sControllers.patchCoreV1NamespacedEndpointsCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/endpoints/:name',
      handler: 'k1sControllers.replaceCoreV1NamespacedEndpointsCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/events',
      handler: 'k1sControllers.deleteCoreV1CollectionNamespacedEventCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/events',
      handler: 'k1sControllers.listCoreV1NamespacedEventCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/events',
      handler: 'k1sControllers.createCoreV1NamespacedEventCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/events/:name',
      handler: 'k1sControllers.deleteCoreV1NamespacedEventCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/events/:name',
      handler: 'k1sControllers.readCoreV1NamespacedEventCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/events/:name',
      handler: 'k1sControllers.patchCoreV1NamespacedEventCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/events/:name',
      handler: 'k1sControllers.replaceCoreV1NamespacedEventCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/limitranges',
      handler: 'k1sControllers.deleteCoreV1CollectionNamespacedLimitRangeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/limitranges',
      handler: 'k1sControllers.listCoreV1NamespacedLimitRangeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/limitranges',
      handler: 'k1sControllers.createCoreV1NamespacedLimitRangeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/limitranges/:name',
      handler: 'k1sControllers.deleteCoreV1NamespacedLimitRangeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/limitranges/:name',
      handler: 'k1sControllers.readCoreV1NamespacedLimitRangeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/limitranges/:name',
      handler: 'k1sControllers.patchCoreV1NamespacedLimitRangeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/limitranges/:name',
      handler: 'k1sControllers.replaceCoreV1NamespacedLimitRangeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/persistentvolumeclaims',
      handler: 'k1sControllers.deleteCoreV1CollectionNamespacedPersistentVolumeClaimCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/persistentvolumeclaims',
      handler: 'k1sControllers.listCoreV1NamespacedPersistentVolumeClaimCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/persistentvolumeclaims',
      handler: 'k1sControllers.createCoreV1NamespacedPersistentVolumeClaimCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/persistentvolumeclaims/:name',
      handler: 'k1sControllers.deleteCoreV1NamespacedPersistentVolumeClaimCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/persistentvolumeclaims/:name',
      handler: 'k1sControllers.readCoreV1NamespacedPersistentVolumeClaimCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/persistentvolumeclaims/:name',
      handler: 'k1sControllers.patchCoreV1NamespacedPersistentVolumeClaimCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/persistentvolumeclaims/:name',
      handler: 'k1sControllers.replaceCoreV1NamespacedPersistentVolumeClaimCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/persistentvolumeclaims/:name/status',
      handler: 'k1sControllers.readCoreV1NamespacedPersistentVolumeClaimStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/persistentvolumeclaims/:name/status',
      handler: 'k1sControllers.patchCoreV1NamespacedPersistentVolumeClaimStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/persistentvolumeclaims/:name/status',
      handler: 'k1sControllers.replaceCoreV1NamespacedPersistentVolumeClaimStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/pods',
      handler: 'k1sControllers.deleteCoreV1CollectionNamespacedPodCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/pods',
      handler: 'k1sControllers.listCoreV1NamespacedPodCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/pods',
      handler: 'k1sControllers.createCoreV1NamespacedPodCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/pods/:podname',
      handler: 'k1sControllers.deleteCoreV1NamespacedPodCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/pods/:podname',
      handler: 'k1sControllers.readCoreV1NamespacedPodCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/pods/:podname',
      handler: 'k1sControllers.patchCoreV1NamespacedPodCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/pods/:podname',
      handler: 'k1sControllers.replaceCoreV1NamespacedPodCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/pods/:podname/attach',
      handler: 'k1sControllers.connectCoreV1GetNamespacedPodAttachCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/pods/:podname/attach',
      handler: 'k1sControllers.connectCoreV1PostNamespacedPodAttachCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/pods/:podname/binding',
      handler: 'k1sControllers.createCoreV1NamespacedPodBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/pods/:podname/ephemeralcontainers',
      handler: 'k1sControllers.readCoreV1NamespacedPodEphemeralcontainersCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/pods/:podname/ephemeralcontainers',
      handler: 'k1sControllers.patchCoreV1NamespacedPodEphemeralcontainersCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/pods/:podname/ephemeralcontainers',
      handler: 'k1sControllers.replaceCoreV1NamespacedPodEphemeralcontainersCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/pods/:podname/eviction',
      handler: 'k1sControllers.createCoreV1NamespacedPodEvictionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/pods/:podname/exec',
      handler: 'k1sControllers.connectCoreV1GetNamespacedPodExecCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/pods/:podname/exec',
      handler: 'k1sControllers.connectCoreV1PostNamespacedPodExecCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/pods/:podname/log',
      handler: 'k1sControllers.readCoreV1NamespacedPodLogCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/pods/:podname/portforward',
      handler: 'k1sControllers.connectCoreV1GetNamespacedPodPortforwardCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/pods/:podname/portforward',
      handler: 'k1sControllers.connectCoreV1PostNamespacedPodPortforwardCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/pods/:podname/proxy',
      handler: 'k1sControllers.connectCoreV1DeleteNamespacedPodProxyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/pods/:podname/proxy',
      handler: 'k1sControllers.connectCoreV1GetNamespacedPodProxyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/pods/:podname/proxy',
      handler: 'k1sControllers.connectCoreV1PatchNamespacedPodProxyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/pods/:podname/proxy',
      handler: 'k1sControllers.connectCoreV1PostNamespacedPodProxyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/pods/:podname/proxy',
      handler: 'k1sControllers.connectCoreV1PutNamespacedPodProxyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/pods/:podname/proxy/:path',
      handler: 'k1sControllers.connectCoreV1DeleteNamespacedPodProxyWithPathCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/pods/:podname/proxy/:path',
      handler: 'k1sControllers.connectCoreV1GetNamespacedPodProxyWithPathCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/pods/:podname/proxy/:path',
      handler: 'k1sControllers.connectCoreV1PatchNamespacedPodProxyWithPathCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/pods/:podname/proxy/:path',
      handler: 'k1sControllers.connectCoreV1PostNamespacedPodProxyWithPathCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/pods/:podname/proxy/:path',
      handler: 'k1sControllers.connectCoreV1PutNamespacedPodProxyWithPathCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/pods/:podname/status',
      handler: 'k1sControllers.readCoreV1NamespacedPodStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/pods/:podname/status',
      handler: 'k1sControllers.patchCoreV1NamespacedPodStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/pods/:podname/status',
      handler: 'k1sControllers.replaceCoreV1NamespacedPodStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/podtemplates',
      handler: 'k1sControllers.deleteCoreV1CollectionNamespacedPodTemplateCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/podtemplates',
      handler: 'k1sControllers.listCoreV1NamespacedPodTemplateCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/podtemplates',
      handler: 'k1sControllers.createCoreV1NamespacedPodTemplateCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/podtemplates/:name',
      handler: 'k1sControllers.deleteCoreV1NamespacedPodTemplateCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/podtemplates/:name',
      handler: 'k1sControllers.readCoreV1NamespacedPodTemplateCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/podtemplates/:name',
      handler: 'k1sControllers.patchCoreV1NamespacedPodTemplateCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/podtemplates/:name',
      handler: 'k1sControllers.replaceCoreV1NamespacedPodTemplateCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/replicationcontrollers',
      handler: 'k1sControllers.deleteCoreV1CollectionNamespacedReplicationControllerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/replicationcontrollers',
      handler: 'k1sControllers.listCoreV1NamespacedReplicationControllerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/replicationcontrollers',
      handler: 'k1sControllers.createCoreV1NamespacedReplicationControllerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/replicationcontrollers/:name',
      handler: 'k1sControllers.deleteCoreV1NamespacedReplicationControllerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/replicationcontrollers/:name',
      handler: 'k1sControllers.readCoreV1NamespacedReplicationControllerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/replicationcontrollers/:name',
      handler: 'k1sControllers.patchCoreV1NamespacedReplicationControllerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/replicationcontrollers/:name',
      handler: 'k1sControllers.replaceCoreV1NamespacedReplicationControllerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/replicationcontrollers/:name/scale',
      handler: 'k1sControllers.readCoreV1NamespacedReplicationControllerScaleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/replicationcontrollers/:name/scale',
      handler: 'k1sControllers.patchCoreV1NamespacedReplicationControllerScaleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/replicationcontrollers/:name/scale',
      handler: 'k1sControllers.replaceCoreV1NamespacedReplicationControllerScaleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/replicationcontrollers/:name/status',
      handler: 'k1sControllers.readCoreV1NamespacedReplicationControllerStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/replicationcontrollers/:name/status',
      handler: 'k1sControllers.patchCoreV1NamespacedReplicationControllerStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/replicationcontrollers/:name/status',
      handler: 'k1sControllers.replaceCoreV1NamespacedReplicationControllerStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/resourcequotas',
      handler: 'k1sControllers.deleteCoreV1CollectionNamespacedResourceQuotaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/resourcequotas',
      handler: 'k1sControllers.listCoreV1NamespacedResourceQuotaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/resourcequotas',
      handler: 'k1sControllers.createCoreV1NamespacedResourceQuotaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/resourcequotas/:name',
      handler: 'k1sControllers.deleteCoreV1NamespacedResourceQuotaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/resourcequotas/:name',
      handler: 'k1sControllers.readCoreV1NamespacedResourceQuotaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/resourcequotas/:name',
      handler: 'k1sControllers.patchCoreV1NamespacedResourceQuotaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/resourcequotas/:name',
      handler: 'k1sControllers.replaceCoreV1NamespacedResourceQuotaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/resourcequotas/:name/status',
      handler: 'k1sControllers.readCoreV1NamespacedResourceQuotaStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/resourcequotas/:name/status',
      handler: 'k1sControllers.patchCoreV1NamespacedResourceQuotaStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/resourcequotas/:name/status',
      handler: 'k1sControllers.replaceCoreV1NamespacedResourceQuotaStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/secrets',
      handler: 'k1sControllers.deleteCoreV1CollectionNamespacedSecretCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/secrets',
      handler: 'k1sControllers.listCoreV1NamespacedSecretCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/secrets',
      handler: 'k1sControllers.createCoreV1NamespacedSecretCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/secrets/:name',
      handler: 'k1sControllers.deleteCoreV1NamespacedSecretCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/secrets/:name',
      handler: 'k1sControllers.readCoreV1NamespacedSecretCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/secrets/:name',
      handler: 'k1sControllers.patchCoreV1NamespacedSecretCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/secrets/:name',
      handler: 'k1sControllers.replaceCoreV1NamespacedSecretCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/serviceaccounts',
      handler: 'k1sControllers.deleteCoreV1CollectionNamespacedServiceAccountCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/serviceaccounts',
      handler: 'k1sControllers.listCoreV1NamespacedServiceAccountCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/serviceaccounts',
      handler: 'k1sControllers.createCoreV1NamespacedServiceAccountCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/serviceaccounts/:name',
      handler: 'k1sControllers.deleteCoreV1NamespacedServiceAccountCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/serviceaccounts/:name',
      handler: 'k1sControllers.readCoreV1NamespacedServiceAccountCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/serviceaccounts/:name',
      handler: 'k1sControllers.patchCoreV1NamespacedServiceAccountCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/serviceaccounts/:name',
      handler: 'k1sControllers.replaceCoreV1NamespacedServiceAccountCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/serviceaccounts/:name/token',
      handler: 'k1sControllers.createCoreV1NamespacedServiceAccountTokenCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/services',
      handler: 'k1sControllers.deleteCoreV1CollectionNamespacedServiceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/services',
      handler: 'k1sControllers.listCoreV1NamespacedServiceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/services',
      handler: 'k1sControllers.createCoreV1NamespacedServiceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/services/:name',
      handler: 'k1sControllers.deleteCoreV1NamespacedServiceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/services/:name',
      handler: 'k1sControllers.readCoreV1NamespacedServiceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/services/:name',
      handler: 'k1sControllers.patchCoreV1NamespacedServiceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/services/:name',
      handler: 'k1sControllers.replaceCoreV1NamespacedServiceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/services/:name/proxy',
      handler: 'k1sControllers.connectCoreV1DeleteNamespacedServiceProxyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/services/:name/proxy',
      handler: 'k1sControllers.connectCoreV1GetNamespacedServiceProxyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/services/:name/proxy',
      handler: 'k1sControllers.connectCoreV1PatchNamespacedServiceProxyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/services/:name/proxy',
      handler: 'k1sControllers.connectCoreV1PostNamespacedServiceProxyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/services/:name/proxy',
      handler: 'k1sControllers.connectCoreV1PutNamespacedServiceProxyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:namespace/services/:name/proxy/:path',
      handler: 'k1sControllers.connectCoreV1DeleteNamespacedServiceProxyWithPathCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/services/:name/proxy/:path',
      handler: 'k1sControllers.connectCoreV1GetNamespacedServiceProxyWithPathCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/services/:name/proxy/:path',
      handler: 'k1sControllers.connectCoreV1PatchNamespacedServiceProxyWithPathCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/namespaces/:namespace/services/:name/proxy/:path',
      handler: 'k1sControllers.connectCoreV1PostNamespacedServiceProxyWithPathCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/services/:name/proxy/:path',
      handler: 'k1sControllers.connectCoreV1PutNamespacedServiceProxyWithPathCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:namespace/services/:name/status',
      handler: 'k1sControllers.readCoreV1NamespacedServiceStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:namespace/services/:name/status',
      handler: 'k1sControllers.patchCoreV1NamespacedServiceStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:namespace/services/:name/status',
      handler: 'k1sControllers.replaceCoreV1NamespacedServiceStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/namespaces/:name',
      handler: 'k1sControllers.deleteCoreV1NamespaceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:name',
      handler: 'k1sControllers.readCoreV1NamespaceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:name',
      handler: 'k1sControllers.patchCoreV1NamespaceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:name',
      handler: 'k1sControllers.replaceCoreV1NamespaceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:name/finalize',
      handler: 'k1sControllers.replaceCoreV1NamespaceFinalizeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/namespaces/:name/status',
      handler: 'k1sControllers.readCoreV1NamespaceStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/namespaces/:name/status',
      handler: 'k1sControllers.patchCoreV1NamespaceStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/namespaces/:name/status',
      handler: 'k1sControllers.replaceCoreV1NamespaceStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/nodes',
      handler: 'k1sControllers.deleteCoreV1CollectionNodeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/nodes',
      handler: 'k1sControllers.listCoreV1NodeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/nodes',
      handler: 'k1sControllers.createCoreV1NodeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/nodes/:name',
      handler: 'k1sControllers.deleteCoreV1NodeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/nodes/:name',
      handler: 'k1sControllers.readCoreV1NodeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/nodes/:name',
      handler: 'k1sControllers.patchCoreV1NodeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/nodes/:name',
      handler: 'k1sControllers.replaceCoreV1NodeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/nodes/:name/proxy',
      handler: 'k1sControllers.connectCoreV1DeleteNodeProxyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/nodes/:name/proxy',
      handler: 'k1sControllers.connectCoreV1GetNodeProxyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/nodes/:name/proxy',
      handler: 'k1sControllers.connectCoreV1PatchNodeProxyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/nodes/:name/proxy',
      handler: 'k1sControllers.connectCoreV1PostNodeProxyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/nodes/:name/proxy',
      handler: 'k1sControllers.connectCoreV1PutNodeProxyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/nodes/:name/proxy/:path',
      handler: 'k1sControllers.connectCoreV1DeleteNodeProxyWithPathCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/nodes/:name/proxy/:path',
      handler: 'k1sControllers.connectCoreV1GetNodeProxyWithPathCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/nodes/:name/proxy/:path',
      handler: 'k1sControllers.connectCoreV1PatchNodeProxyWithPathCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/nodes/:name/proxy/:path',
      handler: 'k1sControllers.connectCoreV1PostNodeProxyWithPathCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/nodes/:name/proxy/:path',
      handler: 'k1sControllers.connectCoreV1PutNodeProxyWithPathCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/nodes/:name/status',
      handler: 'k1sControllers.readCoreV1NodeStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/nodes/:name/status',
      handler: 'k1sControllers.patchCoreV1NodeStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/nodes/:name/status',
      handler: 'k1sControllers.replaceCoreV1NodeStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/persistentvolumeclaims',
      handler: 'k1sControllers.listCoreV1PersistentVolumeClaimForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/persistentvolumes',
      handler: 'k1sControllers.deleteCoreV1CollectionPersistentVolumeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/persistentvolumes',
      handler: 'k1sControllers.listCoreV1PersistentVolumeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/api/v1/persistentvolumes',
      handler: 'k1sControllers.createCoreV1PersistentVolumeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/api/v1/persistentvolumes/:name',
      handler: 'k1sControllers.deleteCoreV1PersistentVolumeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/persistentvolumes/:name',
      handler: 'k1sControllers.readCoreV1PersistentVolumeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/persistentvolumes/:name',
      handler: 'k1sControllers.patchCoreV1PersistentVolumeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/persistentvolumes/:name',
      handler: 'k1sControllers.replaceCoreV1PersistentVolumeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/persistentvolumes/:name/status',
      handler: 'k1sControllers.readCoreV1PersistentVolumeStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/api/v1/persistentvolumes/:name/status',
      handler: 'k1sControllers.patchCoreV1PersistentVolumeStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/api/v1/persistentvolumes/:name/status',
      handler: 'k1sControllers.replaceCoreV1PersistentVolumeStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      // @done
      // @todo - Needs to filter if the user has defined a namespace (in the context)
      method: 'GET',
      path: '/api/v1/pods',
      handler: 'k1sControllers.listCoreV1PodForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/podtemplates',
      handler: 'k1sControllers.listCoreV1PodTemplateForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/replicationcontrollers',
      handler: 'k1sControllers.listCoreV1ReplicationControllerForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/resourcequotas',
      handler: 'k1sControllers.listCoreV1ResourceQuotaForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/secrets',
      handler: 'k1sControllers.listCoreV1SecretForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/serviceaccounts',
      handler: 'k1sControllers.listCoreV1ServiceAccountForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/services',
      handler: 'k1sControllers.listCoreV1ServiceForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/configmaps',
      handler: 'k1sControllers.watchCoreV1ConfigMapListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/endpoints',
      handler: 'k1sControllers.watchCoreV1EndpointsListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/events',
      handler: 'k1sControllers.watchCoreV1EventListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/limitranges',
      handler: 'k1sControllers.watchCoreV1LimitRangeListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces',
      handler: 'k1sControllers.watchCoreV1NamespaceListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/configmaps',
      handler: 'k1sControllers.watchCoreV1NamespacedConfigMapListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/configmaps/:name',
      handler: 'k1sControllers.watchCoreV1NamespacedConfigMapCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/endpoints',
      handler: 'k1sControllers.watchCoreV1NamespacedEndpointsListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/endpoints/:name',
      handler: 'k1sControllers.watchCoreV1NamespacedEndpointsCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/events',
      handler: 'k1sControllers.watchCoreV1NamespacedEventListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/events/:name',
      handler: 'k1sControllers.watchCoreV1NamespacedEventCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/limitranges',
      handler: 'k1sControllers.watchCoreV1NamespacedLimitRangeListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/limitranges/:name',
      handler: 'k1sControllers.watchCoreV1NamespacedLimitRangeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/persistentvolumeclaims',
      handler: 'k1sControllers.watchCoreV1NamespacedPersistentVolumeClaimListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/persistentvolumeclaims/:name',
      handler: 'k1sControllers.watchCoreV1NamespacedPersistentVolumeClaimCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/pods',
      handler: 'k1sControllers.watchCoreV1NamespacedPodListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/pods/:podname',
      handler: 'k1sControllers.watchCoreV1NamespacedPodCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/podtemplates',
      handler: 'k1sControllers.watchCoreV1NamespacedPodTemplateListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/podtemplates/:name',
      handler: 'k1sControllers.watchCoreV1NamespacedPodTemplateCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/replicationcontrollers',
      handler: 'k1sControllers.watchCoreV1NamespacedReplicationControllerListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/replicationcontrollers/:name',
      handler: 'k1sControllers.watchCoreV1NamespacedReplicationControllerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/resourcequotas',
      handler: 'k1sControllers.watchCoreV1NamespacedResourceQuotaListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/resourcequotas/:name',
      handler: 'k1sControllers.watchCoreV1NamespacedResourceQuotaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/secrets',
      handler: 'k1sControllers.watchCoreV1NamespacedSecretListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/secrets/:name',
      handler: 'k1sControllers.watchCoreV1NamespacedSecretCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/serviceaccounts',
      handler: 'k1sControllers.watchCoreV1NamespacedServiceAccountListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/serviceaccounts/:name',
      handler: 'k1sControllers.watchCoreV1NamespacedServiceAccountCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/services',
      handler: 'k1sControllers.watchCoreV1NamespacedServiceListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:namespace/services/:name',
      handler: 'k1sControllers.watchCoreV1NamespacedServiceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/namespaces/:name',
      handler: 'k1sControllers.watchCoreV1NamespaceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/nodes',
      handler: 'k1sControllers.watchCoreV1NodeListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/nodes/:name',
      handler: 'k1sControllers.watchCoreV1NodeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/persistentvolumeclaims',
      handler: 'k1sControllers.watchCoreV1PersistentVolumeClaimListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/persistentvolumes',
      handler: 'k1sControllers.watchCoreV1PersistentVolumeListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/persistentvolumes/:name',
      handler: 'k1sControllers.watchCoreV1PersistentVolumeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/pods',
      handler: 'k1sControllers.watchCoreV1PodListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/podtemplates',
      handler: 'k1sControllers.watchCoreV1PodTemplateListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/replicationcontrollers',
      handler: 'k1sControllers.watchCoreV1ReplicationControllerListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/resourcequotas',
      handler: 'k1sControllers.watchCoreV1ResourceQuotaListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/secrets',
      handler: 'k1sControllers.watchCoreV1SecretListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/serviceaccounts',
      handler: 'k1sControllers.watchCoreV1ServiceAccountListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/api/v1/watch/services',
      handler: 'k1sControllers.watchCoreV1ServiceListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis',
      handler: 'k1sControllers.getAPIVersionsCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/admissionregistration.k8s.io',
      handler: 'k1sControllers.getAdmissionregistrationAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/admissionregistration.k8s.io/v1',
      handler: 'k1sControllers.getAdmissionregistrationV1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/admissionregistration.k8s.io/v1/mutatingwebhookconfigurations',
      handler: 'k1sControllers.deleteAdmissionregistrationV1CollectionMutatingWebhookConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/admissionregistration.k8s.io/v1/mutatingwebhookconfigurations',
      handler: 'k1sControllers.listAdmissionregistrationV1MutatingWebhookConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/admissionregistration.k8s.io/v1/mutatingwebhookconfigurations',
      handler: 'k1sControllers.createAdmissionregistrationV1MutatingWebhookConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/admissionregistration.k8s.io/v1/mutatingwebhookconfigurations/:name',
      handler: 'k1sControllers.deleteAdmissionregistrationV1MutatingWebhookConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/admissionregistration.k8s.io/v1/mutatingwebhookconfigurations/:name',
      handler: 'k1sControllers.readAdmissionregistrationV1MutatingWebhookConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/admissionregistration.k8s.io/v1/mutatingwebhookconfigurations/:name',
      handler: 'k1sControllers.patchAdmissionregistrationV1MutatingWebhookConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/admissionregistration.k8s.io/v1/mutatingwebhookconfigurations/:name',
      handler: 'k1sControllers.replaceAdmissionregistrationV1MutatingWebhookConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/admissionregistration.k8s.io/v1/validatingwebhookconfigurations',
      handler: 'k1sControllers.deleteAdmissionregistrationV1CollectionValidatingWebhookConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/admissionregistration.k8s.io/v1/validatingwebhookconfigurations',
      handler: 'k1sControllers.listAdmissionregistrationV1ValidatingWebhookConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/admissionregistration.k8s.io/v1/validatingwebhookconfigurations',
      handler: 'k1sControllers.createAdmissionregistrationV1ValidatingWebhookConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/admissionregistration.k8s.io/v1/validatingwebhookconfigurations/:name',
      handler: 'k1sControllers.deleteAdmissionregistrationV1ValidatingWebhookConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/admissionregistration.k8s.io/v1/validatingwebhookconfigurations/:name',
      handler: 'k1sControllers.readAdmissionregistrationV1ValidatingWebhookConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/admissionregistration.k8s.io/v1/validatingwebhookconfigurations/:name',
      handler: 'k1sControllers.patchAdmissionregistrationV1ValidatingWebhookConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/admissionregistration.k8s.io/v1/validatingwebhookconfigurations/:name',
      handler: 'k1sControllers.replaceAdmissionregistrationV1ValidatingWebhookConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/admissionregistration.k8s.io/v1/watch/mutatingwebhookconfigurations',
      handler: 'k1sControllers.watchAdmissionregistrationV1MutatingWebhookConfigurationListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/admissionregistration.k8s.io/v1/watch/mutatingwebhookconfigurations/:name',
      handler: 'k1sControllers.watchAdmissionregistrationV1MutatingWebhookConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/admissionregistration.k8s.io/v1/watch/validatingwebhookconfigurations',
      handler: 'k1sControllers.watchAdmissionregistrationV1ValidatingWebhookConfigurationListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/admissionregistration.k8s.io/v1/watch/validatingwebhookconfigurations/:name',
      handler: 'k1sControllers.watchAdmissionregistrationV1ValidatingWebhookConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/admissionregistration.k8s.io/v1alpha1',
      handler: 'k1sControllers.getAdmissionregistrationV1alpha1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/admissionregistration.k8s.io/v1alpha1/validatingadmissionpolicies',
      handler: 'k1sControllers.deleteAdmissionregistrationV1alpha1CollectionValidatingAdmissionPolicyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/admissionregistration.k8s.io/v1alpha1/validatingadmissionpolicies',
      handler: 'k1sControllers.listAdmissionregistrationV1alpha1ValidatingAdmissionPolicyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/admissionregistration.k8s.io/v1alpha1/validatingadmissionpolicies',
      handler: 'k1sControllers.createAdmissionregistrationV1alpha1ValidatingAdmissionPolicyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/admissionregistration.k8s.io/v1alpha1/validatingadmissionpolicies/:name',
      handler: 'k1sControllers.deleteAdmissionregistrationV1alpha1ValidatingAdmissionPolicyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/admissionregistration.k8s.io/v1alpha1/validatingadmissionpolicies/:name',
      handler: 'k1sControllers.readAdmissionregistrationV1alpha1ValidatingAdmissionPolicyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/admissionregistration.k8s.io/v1alpha1/validatingadmissionpolicies/:name',
      handler: 'k1sControllers.patchAdmissionregistrationV1alpha1ValidatingAdmissionPolicyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/admissionregistration.k8s.io/v1alpha1/validatingadmissionpolicies/:name',
      handler: 'k1sControllers.replaceAdmissionregistrationV1alpha1ValidatingAdmissionPolicyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/admissionregistration.k8s.io/v1alpha1/validatingadmissionpolicybindings',
      handler: 'k1sControllers.deleteAdmissionregistrationV1alpha1CollectionValidatingAdmissionPolicyBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/admissionregistration.k8s.io/v1alpha1/validatingadmissionpolicybindings',
      handler: 'k1sControllers.listAdmissionregistrationV1alpha1ValidatingAdmissionPolicyBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/admissionregistration.k8s.io/v1alpha1/validatingadmissionpolicybindings',
      handler: 'k1sControllers.createAdmissionregistrationV1alpha1ValidatingAdmissionPolicyBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/admissionregistration.k8s.io/v1alpha1/validatingadmissionpolicybindings/:name',
      handler: 'k1sControllers.deleteAdmissionregistrationV1alpha1ValidatingAdmissionPolicyBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/admissionregistration.k8s.io/v1alpha1/validatingadmissionpolicybindings/:name',
      handler: 'k1sControllers.readAdmissionregistrationV1alpha1ValidatingAdmissionPolicyBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/admissionregistration.k8s.io/v1alpha1/validatingadmissionpolicybindings/:name',
      handler: 'k1sControllers.patchAdmissionregistrationV1alpha1ValidatingAdmissionPolicyBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/admissionregistration.k8s.io/v1alpha1/validatingadmissionpolicybindings/:name',
      handler: 'k1sControllers.replaceAdmissionregistrationV1alpha1ValidatingAdmissionPolicyBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/admissionregistration.k8s.io/v1alpha1/watch/validatingadmissionpolicies',
      handler: 'k1sControllers.watchAdmissionregistrationV1alpha1ValidatingAdmissionPolicyListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/admissionregistration.k8s.io/v1alpha1/watch/validatingadmissionpolicies/:name',
      handler: 'k1sControllers.watchAdmissionregistrationV1alpha1ValidatingAdmissionPolicyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/admissionregistration.k8s.io/v1alpha1/watch/validatingadmissionpolicybindings',
      handler: 'k1sControllers.watchAdmissionregistrationV1alpha1ValidatingAdmissionPolicyBindingListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/admissionregistration.k8s.io/v1alpha1/watch/validatingadmissionpolicybindings/:name',
      handler: 'k1sControllers.watchAdmissionregistrationV1alpha1ValidatingAdmissionPolicyBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apiextensions.k8s.io',
      handler: 'k1sControllers.getApiextensionsAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apiextensions.k8s.io/v1',
      handler: 'k1sControllers.getApiextensionsV1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/apiextensions.k8s.io/v1/customresourcedefinitions',
      handler: 'k1sControllers.deleteApiextensionsV1CollectionCustomResourceDefinitionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apiextensions.k8s.io/v1/customresourcedefinitions',
      handler: 'k1sControllers.listApiextensionsV1CustomResourceDefinitionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/apiextensions.k8s.io/v1/customresourcedefinitions',
      handler: 'k1sControllers.createApiextensionsV1CustomResourceDefinitionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/apiextensions.k8s.io/v1/customresourcedefinitions/:name',
      handler: 'k1sControllers.deleteApiextensionsV1CustomResourceDefinitionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apiextensions.k8s.io/v1/customresourcedefinitions/:name',
      handler: 'k1sControllers.readApiextensionsV1CustomResourceDefinitionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/apiextensions.k8s.io/v1/customresourcedefinitions/:name',
      handler: 'k1sControllers.patchApiextensionsV1CustomResourceDefinitionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/apiextensions.k8s.io/v1/customresourcedefinitions/:name',
      handler: 'k1sControllers.replaceApiextensionsV1CustomResourceDefinitionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apiextensions.k8s.io/v1/customresourcedefinitions/:name/status',
      handler: 'k1sControllers.readApiextensionsV1CustomResourceDefinitionStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/apiextensions.k8s.io/v1/customresourcedefinitions/:name/status',
      handler: 'k1sControllers.patchApiextensionsV1CustomResourceDefinitionStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/apiextensions.k8s.io/v1/customresourcedefinitions/:name/status',
      handler: 'k1sControllers.replaceApiextensionsV1CustomResourceDefinitionStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apiextensions.k8s.io/v1/watch/customresourcedefinitions',
      handler: 'k1sControllers.watchApiextensionsV1CustomResourceDefinitionListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apiextensions.k8s.io/v1/watch/customresourcedefinitions/:name',
      handler: 'k1sControllers.watchApiextensionsV1CustomResourceDefinitionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apiregistration.k8s.io',
      handler: 'k1sControllers.getApiregistrationAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apiregistration.k8s.io/v1',
      handler: 'k1sControllers.getApiregistrationV1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/apiregistration.k8s.io/v1/apiservices',
      handler: 'k1sControllers.deleteApiregistrationV1CollectionAPIServiceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apiregistration.k8s.io/v1/apiservices',
      handler: 'k1sControllers.listApiregistrationV1APIServiceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/apiregistration.k8s.io/v1/apiservices',
      handler: 'k1sControllers.createApiregistrationV1APIServiceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/apiregistration.k8s.io/v1/apiservices/:name',
      handler: 'k1sControllers.deleteApiregistrationV1APIServiceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apiregistration.k8s.io/v1/apiservices/:name',
      handler: 'k1sControllers.readApiregistrationV1APIServiceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/apiregistration.k8s.io/v1/apiservices/:name',
      handler: 'k1sControllers.patchApiregistrationV1APIServiceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/apiregistration.k8s.io/v1/apiservices/:name',
      handler: 'k1sControllers.replaceApiregistrationV1APIServiceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apiregistration.k8s.io/v1/apiservices/:name/status',
      handler: 'k1sControllers.readApiregistrationV1APIServiceStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/apiregistration.k8s.io/v1/apiservices/:name/status',
      handler: 'k1sControllers.patchApiregistrationV1APIServiceStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/apiregistration.k8s.io/v1/apiservices/:name/status',
      handler: 'k1sControllers.replaceApiregistrationV1APIServiceStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apiregistration.k8s.io/v1/watch/apiservices',
      handler: 'k1sControllers.watchApiregistrationV1APIServiceListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apiregistration.k8s.io/v1/watch/apiservices/:name',
      handler: 'k1sControllers.watchApiregistrationV1APIServiceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps',
      handler: 'k1sControllers.getAppsAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1',
      handler: 'k1sControllers.getAppsV1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/controllerrevisions',
      handler: 'k1sControllers.listAppsV1ControllerRevisionForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/daemonsets',
      handler: 'k1sControllers.listAppsV1DaemonSetForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/deployments',
      handler: 'k1sControllers.listAppsV1DeploymentForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/apps/v1/namespaces/:namespace/controllerrevisions',
      handler: 'k1sControllers.deleteAppsV1CollectionNamespacedControllerRevisionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/namespaces/:namespace/controllerrevisions',
      handler: 'k1sControllers.listAppsV1NamespacedControllerRevisionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/apps/v1/namespaces/:namespace/controllerrevisions',
      handler: 'k1sControllers.createAppsV1NamespacedControllerRevisionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/apps/v1/namespaces/:namespace/controllerrevisions/:name',
      handler: 'k1sControllers.deleteAppsV1NamespacedControllerRevisionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/namespaces/:namespace/controllerrevisions/:name',
      handler: 'k1sControllers.readAppsV1NamespacedControllerRevisionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/apps/v1/namespaces/:namespace/controllerrevisions/:name',
      handler: 'k1sControllers.patchAppsV1NamespacedControllerRevisionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/apps/v1/namespaces/:namespace/controllerrevisions/:name',
      handler: 'k1sControllers.replaceAppsV1NamespacedControllerRevisionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/apps/v1/namespaces/:namespace/daemonsets',
      handler: 'k1sControllers.deleteAppsV1CollectionNamespacedDaemonSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/namespaces/:namespace/daemonsets',
      handler: 'k1sControllers.listAppsV1NamespacedDaemonSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/apps/v1/namespaces/:namespace/daemonsets',
      handler: 'k1sControllers.createAppsV1NamespacedDaemonSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/apps/v1/namespaces/:namespace/daemonsets/:name',
      handler: 'k1sControllers.deleteAppsV1NamespacedDaemonSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/namespaces/:namespace/daemonsets/:name',
      handler: 'k1sControllers.readAppsV1NamespacedDaemonSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/apps/v1/namespaces/:namespace/daemonsets/:name',
      handler: 'k1sControllers.patchAppsV1NamespacedDaemonSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/apps/v1/namespaces/:namespace/daemonsets/:name',
      handler: 'k1sControllers.replaceAppsV1NamespacedDaemonSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/namespaces/:namespace/daemonsets/:name/status',
      handler: 'k1sControllers.readAppsV1NamespacedDaemonSetStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/apps/v1/namespaces/:namespace/daemonsets/:name/status',
      handler: 'k1sControllers.patchAppsV1NamespacedDaemonSetStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/apps/v1/namespaces/:namespace/daemonsets/:name/status',
      handler: 'k1sControllers.replaceAppsV1NamespacedDaemonSetStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/apps/v1/namespaces/:namespace/deployments',
      handler: 'k1sControllers.deleteAppsV1CollectionNamespacedDeploymentCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/namespaces/:namespace/deployments',
      handler: 'k1sControllers.listAppsV1NamespacedDeploymentCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/apps/v1/namespaces/:namespace/deployments',
      handler: 'k1sControllers.createAppsV1NamespacedDeploymentCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/apps/v1/namespaces/:namespace/deployments/:name',
      handler: 'k1sControllers.deleteAppsV1NamespacedDeploymentCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/namespaces/:namespace/deployments/:name',
      handler: 'k1sControllers.readAppsV1NamespacedDeploymentCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/apps/v1/namespaces/:namespace/deployments/:name',
      handler: 'k1sControllers.patchAppsV1NamespacedDeploymentCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/apps/v1/namespaces/:namespace/deployments/:name',
      handler: 'k1sControllers.replaceAppsV1NamespacedDeploymentCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/namespaces/:namespace/deployments/:name/scale',
      handler: 'k1sControllers.readAppsV1NamespacedDeploymentScaleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/apps/v1/namespaces/:namespace/deployments/:name/scale',
      handler: 'k1sControllers.patchAppsV1NamespacedDeploymentScaleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/apps/v1/namespaces/:namespace/deployments/:name/scale',
      handler: 'k1sControllers.replaceAppsV1NamespacedDeploymentScaleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/namespaces/:namespace/deployments/:name/status',
      handler: 'k1sControllers.readAppsV1NamespacedDeploymentStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/apps/v1/namespaces/:namespace/deployments/:name/status',
      handler: 'k1sControllers.patchAppsV1NamespacedDeploymentStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/apps/v1/namespaces/:namespace/deployments/:name/status',
      handler: 'k1sControllers.replaceAppsV1NamespacedDeploymentStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/apps/v1/namespaces/:namespace/replicasets',
      handler: 'k1sControllers.deleteAppsV1CollectionNamespacedReplicaSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/namespaces/:namespace/replicasets',
      handler: 'k1sControllers.listAppsV1NamespacedReplicaSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/apps/v1/namespaces/:namespace/replicasets',
      handler: 'k1sControllers.createAppsV1NamespacedReplicaSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/apps/v1/namespaces/:namespace/replicasets/:name',
      handler: 'k1sControllers.deleteAppsV1NamespacedReplicaSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/namespaces/:namespace/replicasets/:name',
      handler: 'k1sControllers.readAppsV1NamespacedReplicaSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/apps/v1/namespaces/:namespace/replicasets/:name',
      handler: 'k1sControllers.patchAppsV1NamespacedReplicaSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/apps/v1/namespaces/:namespace/replicasets/:name',
      handler: 'k1sControllers.replaceAppsV1NamespacedReplicaSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/namespaces/:namespace/replicasets/:name/scale',
      handler: 'k1sControllers.readAppsV1NamespacedReplicaSetScaleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/apps/v1/namespaces/:namespace/replicasets/:name/scale',
      handler: 'k1sControllers.patchAppsV1NamespacedReplicaSetScaleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/apps/v1/namespaces/:namespace/replicasets/:name/scale',
      handler: 'k1sControllers.replaceAppsV1NamespacedReplicaSetScaleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/namespaces/:namespace/replicasets/:name/status',
      handler: 'k1sControllers.readAppsV1NamespacedReplicaSetStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/apps/v1/namespaces/:namespace/replicasets/:name/status',
      handler: 'k1sControllers.patchAppsV1NamespacedReplicaSetStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/apps/v1/namespaces/:namespace/replicasets/:name/status',
      handler: 'k1sControllers.replaceAppsV1NamespacedReplicaSetStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/apps/v1/namespaces/:namespace/statefulsets',
      handler: 'k1sControllers.deleteAppsV1CollectionNamespacedStatefulSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/namespaces/:namespace/statefulsets',
      handler: 'k1sControllers.listAppsV1NamespacedStatefulSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/apps/v1/namespaces/:namespace/statefulsets',
      handler: 'k1sControllers.createAppsV1NamespacedStatefulSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/apps/v1/namespaces/:namespace/statefulsets/:name',
      handler: 'k1sControllers.deleteAppsV1NamespacedStatefulSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/namespaces/:namespace/statefulsets/:name',
      handler: 'k1sControllers.readAppsV1NamespacedStatefulSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/apps/v1/namespaces/:namespace/statefulsets/:name',
      handler: 'k1sControllers.patchAppsV1NamespacedStatefulSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/apps/v1/namespaces/:namespace/statefulsets/:name',
      handler: 'k1sControllers.replaceAppsV1NamespacedStatefulSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/namespaces/:namespace/statefulsets/:name/scale',
      handler: 'k1sControllers.readAppsV1NamespacedStatefulSetScaleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/apps/v1/namespaces/:namespace/statefulsets/:name/scale',
      handler: 'k1sControllers.patchAppsV1NamespacedStatefulSetScaleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/apps/v1/namespaces/:namespace/statefulsets/:name/scale',
      handler: 'k1sControllers.replaceAppsV1NamespacedStatefulSetScaleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/namespaces/:namespace/statefulsets/:name/status',
      handler: 'k1sControllers.readAppsV1NamespacedStatefulSetStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/apps/v1/namespaces/:namespace/statefulsets/:name/status',
      handler: 'k1sControllers.patchAppsV1NamespacedStatefulSetStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/apps/v1/namespaces/:namespace/statefulsets/:name/status',
      handler: 'k1sControllers.replaceAppsV1NamespacedStatefulSetStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/replicasets',
      handler: 'k1sControllers.listAppsV1ReplicaSetForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/statefulsets',
      handler: 'k1sControllers.listAppsV1StatefulSetForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/watch/controllerrevisions',
      handler: 'k1sControllers.watchAppsV1ControllerRevisionListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/watch/daemonsets',
      handler: 'k1sControllers.watchAppsV1DaemonSetListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/watch/deployments',
      handler: 'k1sControllers.watchAppsV1DeploymentListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/watch/namespaces/:namespace/controllerrevisions',
      handler: 'k1sControllers.watchAppsV1NamespacedControllerRevisionListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/watch/namespaces/:namespace/controllerrevisions/:name',
      handler: 'k1sControllers.watchAppsV1NamespacedControllerRevisionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/watch/namespaces/:namespace/daemonsets',
      handler: 'k1sControllers.watchAppsV1NamespacedDaemonSetListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/watch/namespaces/:namespace/daemonsets/:name',
      handler: 'k1sControllers.watchAppsV1NamespacedDaemonSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/watch/namespaces/:namespace/deployments',
      handler: 'k1sControllers.watchAppsV1NamespacedDeploymentListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/watch/namespaces/:namespace/deployments/:name',
      handler: 'k1sControllers.watchAppsV1NamespacedDeploymentCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/watch/namespaces/:namespace/replicasets',
      handler: 'k1sControllers.watchAppsV1NamespacedReplicaSetListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/watch/namespaces/:namespace/replicasets/:name',
      handler: 'k1sControllers.watchAppsV1NamespacedReplicaSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/watch/namespaces/:namespace/statefulsets',
      handler: 'k1sControllers.watchAppsV1NamespacedStatefulSetListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/watch/namespaces/:namespace/statefulsets/:name',
      handler: 'k1sControllers.watchAppsV1NamespacedStatefulSetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/watch/replicasets',
      handler: 'k1sControllers.watchAppsV1ReplicaSetListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/apps/v1/watch/statefulsets',
      handler: 'k1sControllers.watchAppsV1StatefulSetListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/authentication.k8s.io',
      handler: 'k1sControllers.getAuthenticationAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/authentication.k8s.io/v1',
      handler: 'k1sControllers.getAuthenticationV1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/authentication.k8s.io/v1/tokenreviews',
      handler: 'k1sControllers.createAuthenticationV1TokenReviewCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/authentication.k8s.io/v1alpha1',
      handler: 'k1sControllers.getAuthenticationV1alpha1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/authentication.k8s.io/v1alpha1/selfsubjectreviews',
      handler: 'k1sControllers.createAuthenticationV1alpha1SelfSubjectReviewCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/authorization.k8s.io',
      handler: 'k1sControllers.getAuthorizationAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/authorization.k8s.io/v1',
      handler: 'k1sControllers.getAuthorizationV1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/authorization.k8s.io/v1/namespaces/:namespace/localsubjectaccessreviews',
      handler: 'k1sControllers.createAuthorizationV1NamespacedLocalSubjectAccessReviewCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/authorization.k8s.io/v1/selfsubjectaccessreviews',
      handler: 'k1sControllers.createAuthorizationV1SelfSubjectAccessReviewCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/authorization.k8s.io/v1/selfsubjectrulesreviews',
      handler: 'k1sControllers.createAuthorizationV1SelfSubjectRulesReviewCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/authorization.k8s.io/v1/subjectaccessreviews',
      handler: 'k1sControllers.createAuthorizationV1SubjectAccessReviewCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/autoscaling',
      handler: 'k1sControllers.getAutoscalingAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/autoscaling/v1',
      handler: 'k1sControllers.getAutoscalingV1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/autoscaling/v1/horizontalpodautoscalers',
      handler: 'k1sControllers.listAutoscalingV1HorizontalPodAutoscalerForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/autoscaling/v1/namespaces/:namespace/horizontalpodautoscalers',
      handler: 'k1sControllers.deleteAutoscalingV1CollectionNamespacedHorizontalPodAutoscalerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/autoscaling/v1/namespaces/:namespace/horizontalpodautoscalers',
      handler: 'k1sControllers.listAutoscalingV1NamespacedHorizontalPodAutoscalerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/autoscaling/v1/namespaces/:namespace/horizontalpodautoscalers',
      handler: 'k1sControllers.createAutoscalingV1NamespacedHorizontalPodAutoscalerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/autoscaling/v1/namespaces/:namespace/horizontalpodautoscalers/:name',
      handler: 'k1sControllers.deleteAutoscalingV1NamespacedHorizontalPodAutoscalerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/autoscaling/v1/namespaces/:namespace/horizontalpodautoscalers/:name',
      handler: 'k1sControllers.readAutoscalingV1NamespacedHorizontalPodAutoscalerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/autoscaling/v1/namespaces/:namespace/horizontalpodautoscalers/:name',
      handler: 'k1sControllers.patchAutoscalingV1NamespacedHorizontalPodAutoscalerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/autoscaling/v1/namespaces/:namespace/horizontalpodautoscalers/:name',
      handler: 'k1sControllers.replaceAutoscalingV1NamespacedHorizontalPodAutoscalerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/autoscaling/v1/namespaces/:namespace/horizontalpodautoscalers/:name/status',
      handler: 'k1sControllers.readAutoscalingV1NamespacedHorizontalPodAutoscalerStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/autoscaling/v1/namespaces/:namespace/horizontalpodautoscalers/:name/status',
      handler: 'k1sControllers.patchAutoscalingV1NamespacedHorizontalPodAutoscalerStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/autoscaling/v1/namespaces/:namespace/horizontalpodautoscalers/:name/status',
      handler: 'k1sControllers.replaceAutoscalingV1NamespacedHorizontalPodAutoscalerStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/autoscaling/v1/watch/horizontalpodautoscalers',
      handler: 'k1sControllers.watchAutoscalingV1HorizontalPodAutoscalerListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/autoscaling/v1/watch/namespaces/:namespace/horizontalpodautoscalers',
      handler: 'k1sControllers.watchAutoscalingV1NamespacedHorizontalPodAutoscalerListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/autoscaling/v1/watch/namespaces/:namespace/horizontalpodautoscalers/:name',
      handler: 'k1sControllers.watchAutoscalingV1NamespacedHorizontalPodAutoscalerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/autoscaling/v2',
      handler: 'k1sControllers.getAutoscalingV2APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/autoscaling/v2/horizontalpodautoscalers',
      handler: 'k1sControllers.listAutoscalingV2HorizontalPodAutoscalerForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/autoscaling/v2/namespaces/:namespace/horizontalpodautoscalers',
      handler: 'k1sControllers.deleteAutoscalingV2CollectionNamespacedHorizontalPodAutoscalerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/autoscaling/v2/namespaces/:namespace/horizontalpodautoscalers',
      handler: 'k1sControllers.listAutoscalingV2NamespacedHorizontalPodAutoscalerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/autoscaling/v2/namespaces/:namespace/horizontalpodautoscalers',
      handler: 'k1sControllers.createAutoscalingV2NamespacedHorizontalPodAutoscalerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/autoscaling/v2/namespaces/:namespace/horizontalpodautoscalers/:name',
      handler: 'k1sControllers.deleteAutoscalingV2NamespacedHorizontalPodAutoscalerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/autoscaling/v2/namespaces/:namespace/horizontalpodautoscalers/:name',
      handler: 'k1sControllers.readAutoscalingV2NamespacedHorizontalPodAutoscalerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/autoscaling/v2/namespaces/:namespace/horizontalpodautoscalers/:name',
      handler: 'k1sControllers.patchAutoscalingV2NamespacedHorizontalPodAutoscalerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/autoscaling/v2/namespaces/:namespace/horizontalpodautoscalers/:name',
      handler: 'k1sControllers.replaceAutoscalingV2NamespacedHorizontalPodAutoscalerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/autoscaling/v2/namespaces/:namespace/horizontalpodautoscalers/:name/status',
      handler: 'k1sControllers.readAutoscalingV2NamespacedHorizontalPodAutoscalerStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/autoscaling/v2/namespaces/:namespace/horizontalpodautoscalers/:name/status',
      handler: 'k1sControllers.patchAutoscalingV2NamespacedHorizontalPodAutoscalerStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/autoscaling/v2/namespaces/:namespace/horizontalpodautoscalers/:name/status',
      handler: 'k1sControllers.replaceAutoscalingV2NamespacedHorizontalPodAutoscalerStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/autoscaling/v2/watch/horizontalpodautoscalers',
      handler: 'k1sControllers.watchAutoscalingV2HorizontalPodAutoscalerListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/autoscaling/v2/watch/namespaces/:namespace/horizontalpodautoscalers',
      handler: 'k1sControllers.watchAutoscalingV2NamespacedHorizontalPodAutoscalerListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/autoscaling/v2/watch/namespaces/:namespace/horizontalpodautoscalers/:name',
      handler: 'k1sControllers.watchAutoscalingV2NamespacedHorizontalPodAutoscalerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/batch',
      handler: 'k1sControllers.getBatchAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/batch/v1',
      handler: 'k1sControllers.getBatchV1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/batch/v1/cronjobs',
      handler: 'k1sControllers.listBatchV1CronJobForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/batch/v1/jobs',
      handler: 'k1sControllers.listBatchV1JobForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/batch/v1/namespaces/:namespace/cronjobs',
      handler: 'k1sControllers.deleteBatchV1CollectionNamespacedCronJobCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/batch/v1/namespaces/:namespace/cronjobs',
      handler: 'k1sControllers.listBatchV1NamespacedCronJobCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/batch/v1/namespaces/:namespace/cronjobs',
      handler: 'k1sControllers.createBatchV1NamespacedCronJobCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/batch/v1/namespaces/:namespace/cronjobs/:name',
      handler: 'k1sControllers.deleteBatchV1NamespacedCronJobCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/batch/v1/namespaces/:namespace/cronjobs/:name',
      handler: 'k1sControllers.readBatchV1NamespacedCronJobCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/batch/v1/namespaces/:namespace/cronjobs/:name',
      handler: 'k1sControllers.patchBatchV1NamespacedCronJobCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/batch/v1/namespaces/:namespace/cronjobs/:name',
      handler: 'k1sControllers.replaceBatchV1NamespacedCronJobCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/batch/v1/namespaces/:namespace/cronjobs/:name/status',
      handler: 'k1sControllers.readBatchV1NamespacedCronJobStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/batch/v1/namespaces/:namespace/cronjobs/:name/status',
      handler: 'k1sControllers.patchBatchV1NamespacedCronJobStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/batch/v1/namespaces/:namespace/cronjobs/:name/status',
      handler: 'k1sControllers.replaceBatchV1NamespacedCronJobStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/batch/v1/namespaces/:namespace/jobs',
      handler: 'k1sControllers.deleteBatchV1CollectionNamespacedJobCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/batch/v1/namespaces/:namespace/jobs',
      handler: 'k1sControllers.listBatchV1NamespacedJobCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/batch/v1/namespaces/:namespace/jobs',
      handler: 'k1sControllers.createBatchV1NamespacedJobCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/batch/v1/namespaces/:namespace/jobs/:name',
      handler: 'k1sControllers.deleteBatchV1NamespacedJobCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/batch/v1/namespaces/:namespace/jobs/:name',
      handler: 'k1sControllers.readBatchV1NamespacedJobCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/batch/v1/namespaces/:namespace/jobs/:name',
      handler: 'k1sControllers.patchBatchV1NamespacedJobCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/batch/v1/namespaces/:namespace/jobs/:name',
      handler: 'k1sControllers.replaceBatchV1NamespacedJobCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/batch/v1/namespaces/:namespace/jobs/:name/status',
      handler: 'k1sControllers.readBatchV1NamespacedJobStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/batch/v1/namespaces/:namespace/jobs/:name/status',
      handler: 'k1sControllers.patchBatchV1NamespacedJobStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/batch/v1/namespaces/:namespace/jobs/:name/status',
      handler: 'k1sControllers.replaceBatchV1NamespacedJobStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/batch/v1/watch/cronjobs',
      handler: 'k1sControllers.watchBatchV1CronJobListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/batch/v1/watch/jobs',
      handler: 'k1sControllers.watchBatchV1JobListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/batch/v1/watch/namespaces/:namespace/cronjobs',
      handler: 'k1sControllers.watchBatchV1NamespacedCronJobListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/batch/v1/watch/namespaces/:namespace/cronjobs/:name',
      handler: 'k1sControllers.watchBatchV1NamespacedCronJobCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/batch/v1/watch/namespaces/:namespace/jobs',
      handler: 'k1sControllers.watchBatchV1NamespacedJobListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/batch/v1/watch/namespaces/:namespace/jobs/:name',
      handler: 'k1sControllers.watchBatchV1NamespacedJobCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/certificates.k8s.io',
      handler: 'k1sControllers.getCertificatesAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/certificates.k8s.io/v1',
      handler: 'k1sControllers.getCertificatesV1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/certificates.k8s.io/v1/certificatesigningrequests',
      handler: 'k1sControllers.deleteCertificatesV1CollectionCertificateSigningRequestCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/certificates.k8s.io/v1/certificatesigningrequests',
      handler: 'k1sControllers.listCertificatesV1CertificateSigningRequestCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/certificates.k8s.io/v1/certificatesigningrequests',
      handler: 'k1sControllers.createCertificatesV1CertificateSigningRequestCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/certificates.k8s.io/v1/certificatesigningrequests/:name',
      handler: 'k1sControllers.deleteCertificatesV1CertificateSigningRequestCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/certificates.k8s.io/v1/certificatesigningrequests/:name',
      handler: 'k1sControllers.readCertificatesV1CertificateSigningRequestCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/certificates.k8s.io/v1/certificatesigningrequests/:name',
      handler: 'k1sControllers.patchCertificatesV1CertificateSigningRequestCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/certificates.k8s.io/v1/certificatesigningrequests/:name',
      handler: 'k1sControllers.replaceCertificatesV1CertificateSigningRequestCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/certificates.k8s.io/v1/certificatesigningrequests/:name/approval',
      handler: 'k1sControllers.readCertificatesV1CertificateSigningRequestApprovalCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/certificates.k8s.io/v1/certificatesigningrequests/:name/approval',
      handler: 'k1sControllers.patchCertificatesV1CertificateSigningRequestApprovalCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/certificates.k8s.io/v1/certificatesigningrequests/:name/approval',
      handler: 'k1sControllers.replaceCertificatesV1CertificateSigningRequestApprovalCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/certificates.k8s.io/v1/certificatesigningrequests/:name/status',
      handler: 'k1sControllers.readCertificatesV1CertificateSigningRequestStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/certificates.k8s.io/v1/certificatesigningrequests/:name/status',
      handler: 'k1sControllers.patchCertificatesV1CertificateSigningRequestStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/certificates.k8s.io/v1/certificatesigningrequests/:name/status',
      handler: 'k1sControllers.replaceCertificatesV1CertificateSigningRequestStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/certificates.k8s.io/v1/watch/certificatesigningrequests',
      handler: 'k1sControllers.watchCertificatesV1CertificateSigningRequestListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/certificates.k8s.io/v1/watch/certificatesigningrequests/:name',
      handler: 'k1sControllers.watchCertificatesV1CertificateSigningRequestCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/coordination.k8s.io',
      handler: 'k1sControllers.getCoordinationAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/coordination.k8s.io/v1',
      handler: 'k1sControllers.getCoordinationV1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/coordination.k8s.io/v1/leases',
      handler: 'k1sControllers.listCoordinationV1LeaseForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/coordination.k8s.io/v1/namespaces/:namespace/leases',
      handler: 'k1sControllers.deleteCoordinationV1CollectionNamespacedLeaseCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/coordination.k8s.io/v1/namespaces/:namespace/leases',
      handler: 'k1sControllers.listCoordinationV1NamespacedLeaseCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/coordination.k8s.io/v1/namespaces/:namespace/leases',
      handler: 'k1sControllers.createCoordinationV1NamespacedLeaseCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/coordination.k8s.io/v1/namespaces/:namespace/leases/:name',
      handler: 'k1sControllers.deleteCoordinationV1NamespacedLeaseCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/coordination.k8s.io/v1/namespaces/:namespace/leases/:name',
      handler: 'k1sControllers.readCoordinationV1NamespacedLeaseCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/coordination.k8s.io/v1/namespaces/:namespace/leases/:name',
      handler: 'k1sControllers.patchCoordinationV1NamespacedLeaseCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/coordination.k8s.io/v1/namespaces/:namespace/leases/:name',
      handler: 'k1sControllers.replaceCoordinationV1NamespacedLeaseCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/coordination.k8s.io/v1/watch/leases',
      handler: 'k1sControllers.watchCoordinationV1LeaseListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/coordination.k8s.io/v1/watch/namespaces/:namespace/leases',
      handler: 'k1sControllers.watchCoordinationV1NamespacedLeaseListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/coordination.k8s.io/v1/watch/namespaces/:namespace/leases/:name',
      handler: 'k1sControllers.watchCoordinationV1NamespacedLeaseCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/discovery.k8s.io',
      handler: 'k1sControllers.getDiscoveryAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/discovery.k8s.io/v1',
      handler: 'k1sControllers.getDiscoveryV1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/discovery.k8s.io/v1/endpointslices',
      handler: 'k1sControllers.listDiscoveryV1EndpointSliceForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/discovery.k8s.io/v1/namespaces/:namespace/endpointslices',
      handler: 'k1sControllers.deleteDiscoveryV1CollectionNamespacedEndpointSliceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/discovery.k8s.io/v1/namespaces/:namespace/endpointslices',
      handler: 'k1sControllers.listDiscoveryV1NamespacedEndpointSliceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/discovery.k8s.io/v1/namespaces/:namespace/endpointslices',
      handler: 'k1sControllers.createDiscoveryV1NamespacedEndpointSliceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/discovery.k8s.io/v1/namespaces/:namespace/endpointslices/:name',
      handler: 'k1sControllers.deleteDiscoveryV1NamespacedEndpointSliceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/discovery.k8s.io/v1/namespaces/:namespace/endpointslices/:name',
      handler: 'k1sControllers.readDiscoveryV1NamespacedEndpointSliceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/discovery.k8s.io/v1/namespaces/:namespace/endpointslices/:name',
      handler: 'k1sControllers.patchDiscoveryV1NamespacedEndpointSliceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/discovery.k8s.io/v1/namespaces/:namespace/endpointslices/:name',
      handler: 'k1sControllers.replaceDiscoveryV1NamespacedEndpointSliceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/discovery.k8s.io/v1/watch/endpointslices',
      handler: 'k1sControllers.watchDiscoveryV1EndpointSliceListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/discovery.k8s.io/v1/watch/namespaces/:namespace/endpointslices',
      handler: 'k1sControllers.watchDiscoveryV1NamespacedEndpointSliceListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/discovery.k8s.io/v1/watch/namespaces/:namespace/endpointslices/:name',
      handler: 'k1sControllers.watchDiscoveryV1NamespacedEndpointSliceCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/events.k8s.io',
      handler: 'k1sControllers.getEventsAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/events.k8s.io/v1',
      handler: 'k1sControllers.getEventsV1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/events.k8s.io/v1/events',
      handler: 'k1sControllers.listEventsV1EventForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/events.k8s.io/v1/namespaces/:namespace/events',
      handler: 'k1sControllers.deleteEventsV1CollectionNamespacedEventCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/events.k8s.io/v1/namespaces/:namespace/events',
      handler: 'k1sControllers.listEventsV1NamespacedEventCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/events.k8s.io/v1/namespaces/:namespace/events',
      handler: 'k1sControllers.createEventsV1NamespacedEventCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/events.k8s.io/v1/namespaces/:namespace/events/:name',
      handler: 'k1sControllers.deleteEventsV1NamespacedEventCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/events.k8s.io/v1/namespaces/:namespace/events/:name',
      handler: 'k1sControllers.readEventsV1NamespacedEventCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/events.k8s.io/v1/namespaces/:namespace/events/:name',
      handler: 'k1sControllers.patchEventsV1NamespacedEventCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/events.k8s.io/v1/namespaces/:namespace/events/:name',
      handler: 'k1sControllers.replaceEventsV1NamespacedEventCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/events.k8s.io/v1/watch/events',
      handler: 'k1sControllers.watchEventsV1EventListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/events.k8s.io/v1/watch/namespaces/:namespace/events',
      handler: 'k1sControllers.watchEventsV1NamespacedEventListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/events.k8s.io/v1/watch/namespaces/:namespace/events/:name',
      handler: 'k1sControllers.watchEventsV1NamespacedEventCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io',
      handler: 'k1sControllers.getFlowcontrolApiserverAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2',
      handler: 'k1sControllers.getFlowcontrolApiserverV1beta2APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/flowschemas',
      handler: 'k1sControllers.deleteFlowcontrolApiserverV1beta2CollectionFlowSchemaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/flowschemas',
      handler: 'k1sControllers.listFlowcontrolApiserverV1beta2FlowSchemaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/flowschemas',
      handler: 'k1sControllers.createFlowcontrolApiserverV1beta2FlowSchemaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/flowschemas/:name',
      handler: 'k1sControllers.deleteFlowcontrolApiserverV1beta2FlowSchemaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/flowschemas/:name',
      handler: 'k1sControllers.readFlowcontrolApiserverV1beta2FlowSchemaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/flowschemas/:name',
      handler: 'k1sControllers.patchFlowcontrolApiserverV1beta2FlowSchemaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/flowschemas/:name',
      handler: 'k1sControllers.replaceFlowcontrolApiserverV1beta2FlowSchemaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/flowschemas/:name/status',
      handler: 'k1sControllers.readFlowcontrolApiserverV1beta2FlowSchemaStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/flowschemas/:name/status',
      handler: 'k1sControllers.patchFlowcontrolApiserverV1beta2FlowSchemaStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/flowschemas/:name/status',
      handler: 'k1sControllers.replaceFlowcontrolApiserverV1beta2FlowSchemaStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/prioritylevelconfigurations',
      handler: 'k1sControllers.deleteFlowcontrolApiserverV1beta2CollectionPriorityLevelConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/prioritylevelconfigurations',
      handler: 'k1sControllers.listFlowcontrolApiserverV1beta2PriorityLevelConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/prioritylevelconfigurations',
      handler: 'k1sControllers.createFlowcontrolApiserverV1beta2PriorityLevelConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/prioritylevelconfigurations/:name',
      handler: 'k1sControllers.deleteFlowcontrolApiserverV1beta2PriorityLevelConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/prioritylevelconfigurations/:name',
      handler: 'k1sControllers.readFlowcontrolApiserverV1beta2PriorityLevelConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/prioritylevelconfigurations/:name',
      handler: 'k1sControllers.patchFlowcontrolApiserverV1beta2PriorityLevelConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/prioritylevelconfigurations/:name',
      handler: 'k1sControllers.replaceFlowcontrolApiserverV1beta2PriorityLevelConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/prioritylevelconfigurations/:name/status',
      handler: 'k1sControllers.readFlowcontrolApiserverV1beta2PriorityLevelConfigurationStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/prioritylevelconfigurations/:name/status',
      handler: 'k1sControllers.patchFlowcontrolApiserverV1beta2PriorityLevelConfigurationStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/prioritylevelconfigurations/:name/status',
      handler: 'k1sControllers.replaceFlowcontrolApiserverV1beta2PriorityLevelConfigurationStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/watch/flowschemas',
      handler: 'k1sControllers.watchFlowcontrolApiserverV1beta2FlowSchemaListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/watch/flowschemas/:name',
      handler: 'k1sControllers.watchFlowcontrolApiserverV1beta2FlowSchemaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/watch/prioritylevelconfigurations',
      handler: 'k1sControllers.watchFlowcontrolApiserverV1beta2PriorityLevelConfigurationListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta2/watch/prioritylevelconfigurations/:name',
      handler: 'k1sControllers.watchFlowcontrolApiserverV1beta2PriorityLevelConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3',
      handler: 'k1sControllers.getFlowcontrolApiserverV1beta3APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/flowschemas',
      handler: 'k1sControllers.deleteFlowcontrolApiserverV1beta3CollectionFlowSchemaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/flowschemas',
      handler: 'k1sControllers.listFlowcontrolApiserverV1beta3FlowSchemaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/flowschemas',
      handler: 'k1sControllers.createFlowcontrolApiserverV1beta3FlowSchemaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/flowschemas/:name',
      handler: 'k1sControllers.deleteFlowcontrolApiserverV1beta3FlowSchemaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/flowschemas/:name',
      handler: 'k1sControllers.readFlowcontrolApiserverV1beta3FlowSchemaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/flowschemas/:name',
      handler: 'k1sControllers.patchFlowcontrolApiserverV1beta3FlowSchemaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/flowschemas/:name',
      handler: 'k1sControllers.replaceFlowcontrolApiserverV1beta3FlowSchemaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/flowschemas/:name/status',
      handler: 'k1sControllers.readFlowcontrolApiserverV1beta3FlowSchemaStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/flowschemas/:name/status',
      handler: 'k1sControllers.patchFlowcontrolApiserverV1beta3FlowSchemaStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/flowschemas/:name/status',
      handler: 'k1sControllers.replaceFlowcontrolApiserverV1beta3FlowSchemaStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/prioritylevelconfigurations',
      handler: 'k1sControllers.deleteFlowcontrolApiserverV1beta3CollectionPriorityLevelConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/prioritylevelconfigurations',
      handler: 'k1sControllers.listFlowcontrolApiserverV1beta3PriorityLevelConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/prioritylevelconfigurations',
      handler: 'k1sControllers.createFlowcontrolApiserverV1beta3PriorityLevelConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/prioritylevelconfigurations/:name',
      handler: 'k1sControllers.deleteFlowcontrolApiserverV1beta3PriorityLevelConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/prioritylevelconfigurations/:name',
      handler: 'k1sControllers.readFlowcontrolApiserverV1beta3PriorityLevelConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/prioritylevelconfigurations/:name',
      handler: 'k1sControllers.patchFlowcontrolApiserverV1beta3PriorityLevelConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/prioritylevelconfigurations/:name',
      handler: 'k1sControllers.replaceFlowcontrolApiserverV1beta3PriorityLevelConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/prioritylevelconfigurations/:name/status',
      handler: 'k1sControllers.readFlowcontrolApiserverV1beta3PriorityLevelConfigurationStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/prioritylevelconfigurations/:name/status',
      handler: 'k1sControllers.patchFlowcontrolApiserverV1beta3PriorityLevelConfigurationStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/prioritylevelconfigurations/:name/status',
      handler: 'k1sControllers.replaceFlowcontrolApiserverV1beta3PriorityLevelConfigurationStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/watch/flowschemas',
      handler: 'k1sControllers.watchFlowcontrolApiserverV1beta3FlowSchemaListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/watch/flowschemas/:name',
      handler: 'k1sControllers.watchFlowcontrolApiserverV1beta3FlowSchemaCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/watch/prioritylevelconfigurations',
      handler: 'k1sControllers.watchFlowcontrolApiserverV1beta3PriorityLevelConfigurationListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/flowcontrol.apiserver.k8s.io/v1beta3/watch/prioritylevelconfigurations/:name',
      handler: 'k1sControllers.watchFlowcontrolApiserverV1beta3PriorityLevelConfigurationCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/internal.apiserver.k8s.io',
      handler: 'k1sControllers.getInternalApiserverAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/internal.apiserver.k8s.io/v1alpha1',
      handler: 'k1sControllers.getInternalApiserverV1alpha1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/internal.apiserver.k8s.io/v1alpha1/storageversions',
      handler: 'k1sControllers.deleteInternalApiserverV1alpha1CollectionStorageVersionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/internal.apiserver.k8s.io/v1alpha1/storageversions',
      handler: 'k1sControllers.listInternalApiserverV1alpha1StorageVersionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/internal.apiserver.k8s.io/v1alpha1/storageversions',
      handler: 'k1sControllers.createInternalApiserverV1alpha1StorageVersionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/internal.apiserver.k8s.io/v1alpha1/storageversions/:name',
      handler: 'k1sControllers.deleteInternalApiserverV1alpha1StorageVersionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/internal.apiserver.k8s.io/v1alpha1/storageversions/:name',
      handler: 'k1sControllers.readInternalApiserverV1alpha1StorageVersionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/internal.apiserver.k8s.io/v1alpha1/storageversions/:name',
      handler: 'k1sControllers.patchInternalApiserverV1alpha1StorageVersionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/internal.apiserver.k8s.io/v1alpha1/storageversions/:name',
      handler: 'k1sControllers.replaceInternalApiserverV1alpha1StorageVersionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/internal.apiserver.k8s.io/v1alpha1/storageversions/:name/status',
      handler: 'k1sControllers.readInternalApiserverV1alpha1StorageVersionStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/internal.apiserver.k8s.io/v1alpha1/storageversions/:name/status',
      handler: 'k1sControllers.patchInternalApiserverV1alpha1StorageVersionStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/internal.apiserver.k8s.io/v1alpha1/storageversions/:name/status',
      handler: 'k1sControllers.replaceInternalApiserverV1alpha1StorageVersionStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/internal.apiserver.k8s.io/v1alpha1/watch/storageversions',
      handler: 'k1sControllers.watchInternalApiserverV1alpha1StorageVersionListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/internal.apiserver.k8s.io/v1alpha1/watch/storageversions/:name',
      handler: 'k1sControllers.watchInternalApiserverV1alpha1StorageVersionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io',
      handler: 'k1sControllers.getNetworkingAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1',
      handler: 'k1sControllers.getNetworkingV1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/networking.k8s.io/v1/ingressclasses',
      handler: 'k1sControllers.deleteNetworkingV1CollectionIngressClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1/ingressclasses',
      handler: 'k1sControllers.listNetworkingV1IngressClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/networking.k8s.io/v1/ingressclasses',
      handler: 'k1sControllers.createNetworkingV1IngressClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/networking.k8s.io/v1/ingressclasses/:name',
      handler: 'k1sControllers.deleteNetworkingV1IngressClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1/ingressclasses/:name',
      handler: 'k1sControllers.readNetworkingV1IngressClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/networking.k8s.io/v1/ingressclasses/:name',
      handler: 'k1sControllers.patchNetworkingV1IngressClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/networking.k8s.io/v1/ingressclasses/:name',
      handler: 'k1sControllers.replaceNetworkingV1IngressClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1/ingresses',
      handler: 'k1sControllers.listNetworkingV1IngressForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/ingresses',
      handler: 'k1sControllers.deleteNetworkingV1CollectionNamespacedIngressCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/ingresses',
      handler: 'k1sControllers.listNetworkingV1NamespacedIngressCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/ingresses',
      handler: 'k1sControllers.createNetworkingV1NamespacedIngressCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/ingresses/:name',
      handler: 'k1sControllers.deleteNetworkingV1NamespacedIngressCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/ingresses/:name',
      handler: 'k1sControllers.readNetworkingV1NamespacedIngressCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/ingresses/:name',
      handler: 'k1sControllers.patchNetworkingV1NamespacedIngressCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/ingresses/:name',
      handler: 'k1sControllers.replaceNetworkingV1NamespacedIngressCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/ingresses/:name/status',
      handler: 'k1sControllers.readNetworkingV1NamespacedIngressStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/ingresses/:name/status',
      handler: 'k1sControllers.patchNetworkingV1NamespacedIngressStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/ingresses/:name/status',
      handler: 'k1sControllers.replaceNetworkingV1NamespacedIngressStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/networkpolicies',
      handler: 'k1sControllers.deleteNetworkingV1CollectionNamespacedNetworkPolicyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/networkpolicies',
      handler: 'k1sControllers.listNetworkingV1NamespacedNetworkPolicyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/networkpolicies',
      handler: 'k1sControllers.createNetworkingV1NamespacedNetworkPolicyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/networkpolicies/:name',
      handler: 'k1sControllers.deleteNetworkingV1NamespacedNetworkPolicyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/networkpolicies/:name',
      handler: 'k1sControllers.readNetworkingV1NamespacedNetworkPolicyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/networkpolicies/:name',
      handler: 'k1sControllers.patchNetworkingV1NamespacedNetworkPolicyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/networkpolicies/:name',
      handler: 'k1sControllers.replaceNetworkingV1NamespacedNetworkPolicyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/networkpolicies/:name/status',
      handler: 'k1sControllers.readNetworkingV1NamespacedNetworkPolicyStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/networkpolicies/:name/status',
      handler: 'k1sControllers.patchNetworkingV1NamespacedNetworkPolicyStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/networking.k8s.io/v1/namespaces/:namespace/networkpolicies/:name/status',
      handler: 'k1sControllers.replaceNetworkingV1NamespacedNetworkPolicyStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1/networkpolicies',
      handler: 'k1sControllers.listNetworkingV1NetworkPolicyForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1/watch/ingressclasses',
      handler: 'k1sControllers.watchNetworkingV1IngressClassListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1/watch/ingressclasses/:name',
      handler: 'k1sControllers.watchNetworkingV1IngressClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1/watch/ingresses',
      handler: 'k1sControllers.watchNetworkingV1IngressListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1/watch/namespaces/:namespace/ingresses',
      handler: 'k1sControllers.watchNetworkingV1NamespacedIngressListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1/watch/namespaces/:namespace/ingresses/:name',
      handler: 'k1sControllers.watchNetworkingV1NamespacedIngressCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1/watch/namespaces/:namespace/networkpolicies',
      handler: 'k1sControllers.watchNetworkingV1NamespacedNetworkPolicyListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1/watch/namespaces/:namespace/networkpolicies/:name',
      handler: 'k1sControllers.watchNetworkingV1NamespacedNetworkPolicyCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1/watch/networkpolicies',
      handler: 'k1sControllers.watchNetworkingV1NetworkPolicyListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1alpha1',
      handler: 'k1sControllers.getNetworkingV1alpha1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/networking.k8s.io/v1alpha1/clustercidrs',
      handler: 'k1sControllers.deleteNetworkingV1alpha1CollectionClusterCIDRCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1alpha1/clustercidrs',
      handler: 'k1sControllers.listNetworkingV1alpha1ClusterCIDRCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/networking.k8s.io/v1alpha1/clustercidrs',
      handler: 'k1sControllers.createNetworkingV1alpha1ClusterCIDRCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/networking.k8s.io/v1alpha1/clustercidrs/:name',
      handler: 'k1sControllers.deleteNetworkingV1alpha1ClusterCIDRCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1alpha1/clustercidrs/:name',
      handler: 'k1sControllers.readNetworkingV1alpha1ClusterCIDRCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/networking.k8s.io/v1alpha1/clustercidrs/:name',
      handler: 'k1sControllers.patchNetworkingV1alpha1ClusterCIDRCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/networking.k8s.io/v1alpha1/clustercidrs/:name',
      handler: 'k1sControllers.replaceNetworkingV1alpha1ClusterCIDRCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1alpha1/watch/clustercidrs',
      handler: 'k1sControllers.watchNetworkingV1alpha1ClusterCIDRListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/networking.k8s.io/v1alpha1/watch/clustercidrs/:name',
      handler: 'k1sControllers.watchNetworkingV1alpha1ClusterCIDRCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/node.k8s.io',
      handler: 'k1sControllers.getNodeAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/node.k8s.io/v1',
      handler: 'k1sControllers.getNodeV1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/node.k8s.io/v1/runtimeclasses',
      handler: 'k1sControllers.deleteNodeV1CollectionRuntimeClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/node.k8s.io/v1/runtimeclasses',
      handler: 'k1sControllers.listNodeV1RuntimeClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/node.k8s.io/v1/runtimeclasses',
      handler: 'k1sControllers.createNodeV1RuntimeClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/node.k8s.io/v1/runtimeclasses/:name',
      handler: 'k1sControllers.deleteNodeV1RuntimeClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/node.k8s.io/v1/runtimeclasses/:name',
      handler: 'k1sControllers.readNodeV1RuntimeClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/node.k8s.io/v1/runtimeclasses/:name',
      handler: 'k1sControllers.patchNodeV1RuntimeClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/node.k8s.io/v1/runtimeclasses/:name',
      handler: 'k1sControllers.replaceNodeV1RuntimeClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/node.k8s.io/v1/watch/runtimeclasses',
      handler: 'k1sControllers.watchNodeV1RuntimeClassListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/node.k8s.io/v1/watch/runtimeclasses/:name',
      handler: 'k1sControllers.watchNodeV1RuntimeClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/policy',
      handler: 'k1sControllers.getPolicyAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/policy/v1',
      handler: 'k1sControllers.getPolicyV1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/policy/v1/namespaces/:namespace/poddisruptionbudgets',
      handler: 'k1sControllers.deletePolicyV1CollectionNamespacedPodDisruptionBudgetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/policy/v1/namespaces/:namespace/poddisruptionbudgets',
      handler: 'k1sControllers.listPolicyV1NamespacedPodDisruptionBudgetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/policy/v1/namespaces/:namespace/poddisruptionbudgets',
      handler: 'k1sControllers.createPolicyV1NamespacedPodDisruptionBudgetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/policy/v1/namespaces/:namespace/poddisruptionbudgets/:name',
      handler: 'k1sControllers.deletePolicyV1NamespacedPodDisruptionBudgetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/policy/v1/namespaces/:namespace/poddisruptionbudgets/:name',
      handler: 'k1sControllers.readPolicyV1NamespacedPodDisruptionBudgetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/policy/v1/namespaces/:namespace/poddisruptionbudgets/:name',
      handler: 'k1sControllers.patchPolicyV1NamespacedPodDisruptionBudgetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/policy/v1/namespaces/:namespace/poddisruptionbudgets/:name',
      handler: 'k1sControllers.replacePolicyV1NamespacedPodDisruptionBudgetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/policy/v1/namespaces/:namespace/poddisruptionbudgets/:name/status',
      handler: 'k1sControllers.readPolicyV1NamespacedPodDisruptionBudgetStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/policy/v1/namespaces/:namespace/poddisruptionbudgets/:name/status',
      handler: 'k1sControllers.patchPolicyV1NamespacedPodDisruptionBudgetStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/policy/v1/namespaces/:namespace/poddisruptionbudgets/:name/status',
      handler: 'k1sControllers.replacePolicyV1NamespacedPodDisruptionBudgetStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/policy/v1/poddisruptionbudgets',
      handler: 'k1sControllers.listPolicyV1PodDisruptionBudgetForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/policy/v1/watch/namespaces/:namespace/poddisruptionbudgets',
      handler: 'k1sControllers.watchPolicyV1NamespacedPodDisruptionBudgetListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/policy/v1/watch/namespaces/:namespace/poddisruptionbudgets/:name',
      handler: 'k1sControllers.watchPolicyV1NamespacedPodDisruptionBudgetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/policy/v1/watch/poddisruptionbudgets',
      handler: 'k1sControllers.watchPolicyV1PodDisruptionBudgetListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io',
      handler: 'k1sControllers.getRbacAuthorizationAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1',
      handler: 'k1sControllers.getRbacAuthorizationV1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/rbac.authorization.k8s.io/v1/clusterrolebindings',
      handler: 'k1sControllers.deleteRbacAuthorizationV1CollectionClusterRoleBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/clusterrolebindings',
      handler: 'k1sControllers.listRbacAuthorizationV1ClusterRoleBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/rbac.authorization.k8s.io/v1/clusterrolebindings',
      handler: 'k1sControllers.createRbacAuthorizationV1ClusterRoleBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/rbac.authorization.k8s.io/v1/clusterrolebindings/:name',
      handler: 'k1sControllers.deleteRbacAuthorizationV1ClusterRoleBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/clusterrolebindings/:name',
      handler: 'k1sControllers.readRbacAuthorizationV1ClusterRoleBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/rbac.authorization.k8s.io/v1/clusterrolebindings/:name',
      handler: 'k1sControllers.patchRbacAuthorizationV1ClusterRoleBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/rbac.authorization.k8s.io/v1/clusterrolebindings/:name',
      handler: 'k1sControllers.replaceRbacAuthorizationV1ClusterRoleBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/rbac.authorization.k8s.io/v1/clusterroles',
      handler: 'k1sControllers.deleteRbacAuthorizationV1CollectionClusterRoleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/clusterroles',
      handler: 'k1sControllers.listRbacAuthorizationV1ClusterRoleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/rbac.authorization.k8s.io/v1/clusterroles',
      handler: 'k1sControllers.createRbacAuthorizationV1ClusterRoleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/rbac.authorization.k8s.io/v1/clusterroles/:name',
      handler: 'k1sControllers.deleteRbacAuthorizationV1ClusterRoleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/clusterroles/:name',
      handler: 'k1sControllers.readRbacAuthorizationV1ClusterRoleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/rbac.authorization.k8s.io/v1/clusterroles/:name',
      handler: 'k1sControllers.patchRbacAuthorizationV1ClusterRoleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/rbac.authorization.k8s.io/v1/clusterroles/:name',
      handler: 'k1sControllers.replaceRbacAuthorizationV1ClusterRoleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/rolebindings',
      handler: 'k1sControllers.deleteRbacAuthorizationV1CollectionNamespacedRoleBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/rolebindings',
      handler: 'k1sControllers.listRbacAuthorizationV1NamespacedRoleBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/rolebindings',
      handler: 'k1sControllers.createRbacAuthorizationV1NamespacedRoleBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/rolebindings/:name',
      handler: 'k1sControllers.deleteRbacAuthorizationV1NamespacedRoleBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/rolebindings/:name',
      handler: 'k1sControllers.readRbacAuthorizationV1NamespacedRoleBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/rolebindings/:name',
      handler: 'k1sControllers.patchRbacAuthorizationV1NamespacedRoleBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/rolebindings/:name',
      handler: 'k1sControllers.replaceRbacAuthorizationV1NamespacedRoleBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/roles',
      handler: 'k1sControllers.deleteRbacAuthorizationV1CollectionNamespacedRoleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/roles',
      handler: 'k1sControllers.listRbacAuthorizationV1NamespacedRoleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/roles',
      handler: 'k1sControllers.createRbacAuthorizationV1NamespacedRoleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/roles/:name',
      handler: 'k1sControllers.deleteRbacAuthorizationV1NamespacedRoleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/roles/:name',
      handler: 'k1sControllers.readRbacAuthorizationV1NamespacedRoleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/roles/:name',
      handler: 'k1sControllers.patchRbacAuthorizationV1NamespacedRoleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/roles/:name',
      handler: 'k1sControllers.replaceRbacAuthorizationV1NamespacedRoleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/rolebindings',
      handler: 'k1sControllers.listRbacAuthorizationV1RoleBindingForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/roles',
      handler: 'k1sControllers.listRbacAuthorizationV1RoleForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/watch/clusterrolebindings',
      handler: 'k1sControllers.watchRbacAuthorizationV1ClusterRoleBindingListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/watch/clusterrolebindings/:name',
      handler: 'k1sControllers.watchRbacAuthorizationV1ClusterRoleBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/watch/clusterroles',
      handler: 'k1sControllers.watchRbacAuthorizationV1ClusterRoleListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/watch/clusterroles/:name',
      handler: 'k1sControllers.watchRbacAuthorizationV1ClusterRoleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/watch/namespaces/:namespace/rolebindings',
      handler: 'k1sControllers.watchRbacAuthorizationV1NamespacedRoleBindingListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/watch/namespaces/:namespace/rolebindings/:name',
      handler: 'k1sControllers.watchRbacAuthorizationV1NamespacedRoleBindingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/watch/namespaces/:namespace/roles',
      handler: 'k1sControllers.watchRbacAuthorizationV1NamespacedRoleListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/watch/namespaces/:namespace/roles/:name',
      handler: 'k1sControllers.watchRbacAuthorizationV1NamespacedRoleCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/watch/rolebindings',
      handler: 'k1sControllers.watchRbacAuthorizationV1RoleBindingListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/rbac.authorization.k8s.io/v1/watch/roles',
      handler: 'k1sControllers.watchRbacAuthorizationV1RoleListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io',
      handler: 'k1sControllers.getResourceAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1',
      handler: 'k1sControllers.getResourceV1alpha1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/podschedulings',
      handler: 'k1sControllers.deleteResourceV1alpha1CollectionNamespacedPodSchedulingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/podschedulings',
      handler: 'k1sControllers.listResourceV1alpha1NamespacedPodSchedulingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/podschedulings',
      handler: 'k1sControllers.createResourceV1alpha1NamespacedPodSchedulingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/podschedulings/:name',
      handler: 'k1sControllers.deleteResourceV1alpha1NamespacedPodSchedulingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/podschedulings/:name',
      handler: 'k1sControllers.readResourceV1alpha1NamespacedPodSchedulingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/podschedulings/:name',
      handler: 'k1sControllers.patchResourceV1alpha1NamespacedPodSchedulingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/podschedulings/:name',
      handler: 'k1sControllers.replaceResourceV1alpha1NamespacedPodSchedulingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/podschedulings/:name/status',
      handler: 'k1sControllers.readResourceV1alpha1NamespacedPodSchedulingStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/podschedulings/:name/status',
      handler: 'k1sControllers.patchResourceV1alpha1NamespacedPodSchedulingStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/podschedulings/:name/status',
      handler: 'k1sControllers.replaceResourceV1alpha1NamespacedPodSchedulingStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/resourceclaims',
      handler: 'k1sControllers.deleteResourceV1alpha1CollectionNamespacedResourceClaimCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/resourceclaims',
      handler: 'k1sControllers.listResourceV1alpha1NamespacedResourceClaimCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/resourceclaims',
      handler: 'k1sControllers.createResourceV1alpha1NamespacedResourceClaimCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/resourceclaims/:name',
      handler: 'k1sControllers.deleteResourceV1alpha1NamespacedResourceClaimCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/resourceclaims/:name',
      handler: 'k1sControllers.readResourceV1alpha1NamespacedResourceClaimCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/resourceclaims/:name',
      handler: 'k1sControllers.patchResourceV1alpha1NamespacedResourceClaimCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/resourceclaims/:name',
      handler: 'k1sControllers.replaceResourceV1alpha1NamespacedResourceClaimCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/resourceclaims/:name/status',
      handler: 'k1sControllers.readResourceV1alpha1NamespacedResourceClaimStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/resourceclaims/:name/status',
      handler: 'k1sControllers.patchResourceV1alpha1NamespacedResourceClaimStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/resourceclaims/:name/status',
      handler: 'k1sControllers.replaceResourceV1alpha1NamespacedResourceClaimStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/resourceclaimtemplates',
      handler: 'k1sControllers.deleteResourceV1alpha1CollectionNamespacedResourceClaimTemplateCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/resourceclaimtemplates',
      handler: 'k1sControllers.listResourceV1alpha1NamespacedResourceClaimTemplateCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/resourceclaimtemplates',
      handler: 'k1sControllers.createResourceV1alpha1NamespacedResourceClaimTemplateCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/resourceclaimtemplates/:name',
      handler: 'k1sControllers.deleteResourceV1alpha1NamespacedResourceClaimTemplateCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/resourceclaimtemplates/:name',
      handler: 'k1sControllers.readResourceV1alpha1NamespacedResourceClaimTemplateCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/resourceclaimtemplates/:name',
      handler: 'k1sControllers.patchResourceV1alpha1NamespacedResourceClaimTemplateCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/resource.k8s.io/v1alpha1/namespaces/:namespace/resourceclaimtemplates/:name',
      handler: 'k1sControllers.replaceResourceV1alpha1NamespacedResourceClaimTemplateCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/podschedulings',
      handler: 'k1sControllers.listResourceV1alpha1PodSchedulingForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/resourceclaims',
      handler: 'k1sControllers.listResourceV1alpha1ResourceClaimForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/resourceclaimtemplates',
      handler: 'k1sControllers.listResourceV1alpha1ResourceClaimTemplateForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/resource.k8s.io/v1alpha1/resourceclasses',
      handler: 'k1sControllers.deleteResourceV1alpha1CollectionResourceClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/resourceclasses',
      handler: 'k1sControllers.listResourceV1alpha1ResourceClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/resource.k8s.io/v1alpha1/resourceclasses',
      handler: 'k1sControllers.createResourceV1alpha1ResourceClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/resource.k8s.io/v1alpha1/resourceclasses/:name',
      handler: 'k1sControllers.deleteResourceV1alpha1ResourceClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/resourceclasses/:name',
      handler: 'k1sControllers.readResourceV1alpha1ResourceClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/resource.k8s.io/v1alpha1/resourceclasses/:name',
      handler: 'k1sControllers.patchResourceV1alpha1ResourceClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/resource.k8s.io/v1alpha1/resourceclasses/:name',
      handler: 'k1sControllers.replaceResourceV1alpha1ResourceClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/watch/namespaces/:namespace/podschedulings',
      handler: 'k1sControllers.watchResourceV1alpha1NamespacedPodSchedulingListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/watch/namespaces/:namespace/podschedulings/:name',
      handler: 'k1sControllers.watchResourceV1alpha1NamespacedPodSchedulingCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/watch/namespaces/:namespace/resourceclaims',
      handler: 'k1sControllers.watchResourceV1alpha1NamespacedResourceClaimListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/watch/namespaces/:namespace/resourceclaims/:name',
      handler: 'k1sControllers.watchResourceV1alpha1NamespacedResourceClaimCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/watch/namespaces/:namespace/resourceclaimtemplates',
      handler: 'k1sControllers.watchResourceV1alpha1NamespacedResourceClaimTemplateListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/watch/namespaces/:namespace/resourceclaimtemplates/:name',
      handler: 'k1sControllers.watchResourceV1alpha1NamespacedResourceClaimTemplateCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/watch/podschedulings',
      handler: 'k1sControllers.watchResourceV1alpha1PodSchedulingListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/watch/resourceclaims',
      handler: 'k1sControllers.watchResourceV1alpha1ResourceClaimListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/watch/resourceclaimtemplates',
      handler: 'k1sControllers.watchResourceV1alpha1ResourceClaimTemplateListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/watch/resourceclasses',
      handler: 'k1sControllers.watchResourceV1alpha1ResourceClassListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/resource.k8s.io/v1alpha1/watch/resourceclasses/:name',
      handler: 'k1sControllers.watchResourceV1alpha1ResourceClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/scheduling.k8s.io',
      handler: 'k1sControllers.getSchedulingAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/scheduling.k8s.io/v1',
      handler: 'k1sControllers.getSchedulingV1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/scheduling.k8s.io/v1/priorityclasses',
      handler: 'k1sControllers.deleteSchedulingV1CollectionPriorityClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/scheduling.k8s.io/v1/priorityclasses',
      handler: 'k1sControllers.listSchedulingV1PriorityClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/scheduling.k8s.io/v1/priorityclasses',
      handler: 'k1sControllers.createSchedulingV1PriorityClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/scheduling.k8s.io/v1/priorityclasses/:name',
      handler: 'k1sControllers.deleteSchedulingV1PriorityClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/scheduling.k8s.io/v1/priorityclasses/:name',
      handler: 'k1sControllers.readSchedulingV1PriorityClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/scheduling.k8s.io/v1/priorityclasses/:name',
      handler: 'k1sControllers.patchSchedulingV1PriorityClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/scheduling.k8s.io/v1/priorityclasses/:name',
      handler: 'k1sControllers.replaceSchedulingV1PriorityClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/scheduling.k8s.io/v1/watch/priorityclasses',
      handler: 'k1sControllers.watchSchedulingV1PriorityClassListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/scheduling.k8s.io/v1/watch/priorityclasses/:name',
      handler: 'k1sControllers.watchSchedulingV1PriorityClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io',
      handler: 'k1sControllers.getStorageAPIGroupCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1',
      handler: 'k1sControllers.getStorageV1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/storage.k8s.io/v1/csidrivers',
      handler: 'k1sControllers.deleteStorageV1CollectionCSIDriverCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/csidrivers',
      handler: 'k1sControllers.listStorageV1CSIDriverCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/storage.k8s.io/v1/csidrivers',
      handler: 'k1sControllers.createStorageV1CSIDriverCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/storage.k8s.io/v1/csidrivers/:name',
      handler: 'k1sControllers.deleteStorageV1CSIDriverCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/csidrivers/:name',
      handler: 'k1sControllers.readStorageV1CSIDriverCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/storage.k8s.io/v1/csidrivers/:name',
      handler: 'k1sControllers.patchStorageV1CSIDriverCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/storage.k8s.io/v1/csidrivers/:name',
      handler: 'k1sControllers.replaceStorageV1CSIDriverCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/storage.k8s.io/v1/csinodes',
      handler: 'k1sControllers.deleteStorageV1CollectionCSINodeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/csinodes',
      handler: 'k1sControllers.listStorageV1CSINodeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/storage.k8s.io/v1/csinodes',
      handler: 'k1sControllers.createStorageV1CSINodeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/storage.k8s.io/v1/csinodes/:name',
      handler: 'k1sControllers.deleteStorageV1CSINodeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/csinodes/:name',
      handler: 'k1sControllers.readStorageV1CSINodeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/storage.k8s.io/v1/csinodes/:name',
      handler: 'k1sControllers.patchStorageV1CSINodeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/storage.k8s.io/v1/csinodes/:name',
      handler: 'k1sControllers.replaceStorageV1CSINodeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/csistoragecapacities',
      handler: 'k1sControllers.listStorageV1CSIStorageCapacityForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/storage.k8s.io/v1/namespaces/:namespace/csistoragecapacities',
      handler: 'k1sControllers.deleteStorageV1CollectionNamespacedCSIStorageCapacityCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/namespaces/:namespace/csistoragecapacities',
      handler: 'k1sControllers.listStorageV1NamespacedCSIStorageCapacityCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/storage.k8s.io/v1/namespaces/:namespace/csistoragecapacities',
      handler: 'k1sControllers.createStorageV1NamespacedCSIStorageCapacityCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/storage.k8s.io/v1/namespaces/:namespace/csistoragecapacities/:name',
      handler: 'k1sControllers.deleteStorageV1NamespacedCSIStorageCapacityCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/namespaces/:namespace/csistoragecapacities/:name',
      handler: 'k1sControllers.readStorageV1NamespacedCSIStorageCapacityCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/storage.k8s.io/v1/namespaces/:namespace/csistoragecapacities/:name',
      handler: 'k1sControllers.patchStorageV1NamespacedCSIStorageCapacityCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/storage.k8s.io/v1/namespaces/:namespace/csistoragecapacities/:name',
      handler: 'k1sControllers.replaceStorageV1NamespacedCSIStorageCapacityCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/storage.k8s.io/v1/storageclasses',
      handler: 'k1sControllers.deleteStorageV1CollectionStorageClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/storageclasses',
      handler: 'k1sControllers.listStorageV1StorageClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/storage.k8s.io/v1/storageclasses',
      handler: 'k1sControllers.createStorageV1StorageClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/storage.k8s.io/v1/storageclasses/:name',
      handler: 'k1sControllers.deleteStorageV1StorageClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/storageclasses/:name',
      handler: 'k1sControllers.readStorageV1StorageClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/storage.k8s.io/v1/storageclasses/:name',
      handler: 'k1sControllers.patchStorageV1StorageClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/storage.k8s.io/v1/storageclasses/:name',
      handler: 'k1sControllers.replaceStorageV1StorageClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/storage.k8s.io/v1/volumeattachments',
      handler: 'k1sControllers.deleteStorageV1CollectionVolumeAttachmentCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/volumeattachments',
      handler: 'k1sControllers.listStorageV1VolumeAttachmentCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/storage.k8s.io/v1/volumeattachments',
      handler: 'k1sControllers.createStorageV1VolumeAttachmentCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/storage.k8s.io/v1/volumeattachments/:name',
      handler: 'k1sControllers.deleteStorageV1VolumeAttachmentCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/volumeattachments/:name',
      handler: 'k1sControllers.readStorageV1VolumeAttachmentCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/storage.k8s.io/v1/volumeattachments/:name',
      handler: 'k1sControllers.patchStorageV1VolumeAttachmentCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/storage.k8s.io/v1/volumeattachments/:name',
      handler: 'k1sControllers.replaceStorageV1VolumeAttachmentCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/volumeattachments/:name/status',
      handler: 'k1sControllers.readStorageV1VolumeAttachmentStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/storage.k8s.io/v1/volumeattachments/:name/status',
      handler: 'k1sControllers.patchStorageV1VolumeAttachmentStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/storage.k8s.io/v1/volumeattachments/:name/status',
      handler: 'k1sControllers.replaceStorageV1VolumeAttachmentStatusCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/watch/csidrivers',
      handler: 'k1sControllers.watchStorageV1CSIDriverListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/watch/csidrivers/:name',
      handler: 'k1sControllers.watchStorageV1CSIDriverCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/watch/csinodes',
      handler: 'k1sControllers.watchStorageV1CSINodeListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/watch/csinodes/:name',
      handler: 'k1sControllers.watchStorageV1CSINodeCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/watch/csistoragecapacities',
      handler: 'k1sControllers.watchStorageV1CSIStorageCapacityListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/watch/namespaces/:namespace/csistoragecapacities',
      handler: 'k1sControllers.watchStorageV1NamespacedCSIStorageCapacityListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/watch/namespaces/:namespace/csistoragecapacities/:name',
      handler: 'k1sControllers.watchStorageV1NamespacedCSIStorageCapacityCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/watch/storageclasses',
      handler: 'k1sControllers.watchStorageV1StorageClassListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/watch/storageclasses/:name',
      handler: 'k1sControllers.watchStorageV1StorageClassCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/watch/volumeattachments',
      handler: 'k1sControllers.watchStorageV1VolumeAttachmentListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1/watch/volumeattachments/:name',
      handler: 'k1sControllers.watchStorageV1VolumeAttachmentCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1beta1',
      handler: 'k1sControllers.getStorageV1beta1APIResourcesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1beta1/csistoragecapacities',
      handler: 'k1sControllers.listStorageV1beta1CSIStorageCapacityForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/storage.k8s.io/v1beta1/namespaces/:namespace/csistoragecapacities',
      handler: 'k1sControllers.deleteStorageV1beta1CollectionNamespacedCSIStorageCapacityCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1beta1/namespaces/:namespace/csistoragecapacities',
      handler: 'k1sControllers.listStorageV1beta1NamespacedCSIStorageCapacityCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'POST',
      path: '/apis/storage.k8s.io/v1beta1/namespaces/:namespace/csistoragecapacities',
      handler: 'k1sControllers.createStorageV1beta1NamespacedCSIStorageCapacityCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'DELETE',
      path: '/apis/storage.k8s.io/v1beta1/namespaces/:namespace/csistoragecapacities/:name',
      handler: 'k1sControllers.deleteStorageV1beta1NamespacedCSIStorageCapacityCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1beta1/namespaces/:namespace/csistoragecapacities/:name',
      handler: 'k1sControllers.readStorageV1beta1NamespacedCSIStorageCapacityCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PATCH',
      path: '/apis/storage.k8s.io/v1beta1/namespaces/:namespace/csistoragecapacities/:name',
      handler: 'k1sControllers.patchStorageV1beta1NamespacedCSIStorageCapacityCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'PUT',
      path: '/apis/storage.k8s.io/v1beta1/namespaces/:namespace/csistoragecapacities/:name',
      handler: 'k1sControllers.replaceStorageV1beta1NamespacedCSIStorageCapacityCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1beta1/watch/csistoragecapacities',
      handler: 'k1sControllers.watchStorageV1beta1CSIStorageCapacityListForAllNamespacesCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1beta1/watch/namespaces/:namespace/csistoragecapacities',
      handler: 'k1sControllers.watchStorageV1beta1NamespacedCSIStorageCapacityListCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/apis/storage.k8s.io/v1beta1/watch/namespaces/:namespace/csistoragecapacities/:name',
      handler: 'k1sControllers.watchStorageV1beta1NamespacedCSIStorageCapacityCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/logs',
      handler: 'k1sControllers.logFileListHandlerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/logs/:logpath',
      handler: 'k1sControllers.logFileHandlerCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/openid/v1/jwks',
      handler: 'k1sControllers.getServiceAccountIssuerOpenIDKeysetCtl',
      config: {
        auth: false,
        policies: []
      }
    },
    {
      method: 'GET',
      path: '/version',
      handler: 'k1sControllers.getCodeVersionCtl',
      config: {
        auth: false,
        policies: []
      }
    },
];

export default routes;
