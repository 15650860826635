import {
    Sheet,
    ModalClose,
    Drawer,
    DialogTitle,
    Divider,
    DialogContent,
    FormControl,
    FormLabel,
    RadioGroup,
    Card,
    CardContent,
    Typography,
    Radio,
    List,
    ListItem,
    Checkbox,
    FormHelperText,
    Switch,
    Stack,
    Button,
    Box,
    SvgIcon,
    Input,
    Autocomplete
} from "@mui/joy"
import { DrawerProps } from "@mui/joy"
import React from "react"
import MultipleStopIcon from '@mui/icons-material/MultipleStop'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded'
import MeetingRoomRoundedIcon from '@mui/icons-material/MeetingRoomRounded'
import HotelRoundedIcon from '@mui/icons-material/HotelRounded'
import { AspectRatio, Done } from "@mui/icons-material"

export const drawerWidth = 350

export default function ClusterDrawer(props: DrawerProps) {
    const [cluster, setCluster] = React.useState('cluster1')
    const [clusterData, setClusterData] = React.useState({})
    const [type, setType] = React.useState('Guesthouse')
    const [amenities, setAmenities] = React.useState([0, 6])
    const [selectedPort, setSelectedPort] = React.useState(6443)
    const [availablePorts, setAvailablePorts] = React.useState([80, 8080, 443, 6443, 8443])
    const [inputValue, setInputValue] = React.useState('')

    const handleClose = () => {
        props.onClose?.({}, 'backdropClick')
    }
    
    const handleBlur = (event: any) => {
      const newPort = Number(event.target.value)
      if (isNaN(newPort) || newPort < 1000 || newPort > 65535) {
        event.target.value = ''
        return
      }
      if (!availablePorts.includes(newPort)) {
        setAvailablePorts([...availablePorts, newPort])
      }
      setInputValue(event.target.value)
    }
    const handleInputChange = (event: any, newInputValue: any) => {
        setInputValue(newInputValue)
    }
    // @fixme: this is not working when the user types a new port, this is NOT autoselected on Blur
    const PortSelect = () => (
        <Autocomplete
            options={availablePorts}
            getOptionLabel={(option) => option.toString()}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            // inputValue={inputValue}
            // fired when the input loses focus
            onBlur={handleBlur}
            // fired when the value changes
            // onChange={handleInputChange}
            freeSolo
            selectOnFocus
            // clearOnBlur
            handleHomeEndKeys
            id="available-ports"
            sx={{ width: 300 }}
        />
    )

    return (
        <Drawer
            {...props}
            size="md"
            variant="plain"
            slotProps={{
                content: {
                    sx: {
                        bgcolor: 'transparent',
                        p: { md: 3, sm: 0 },
                        boxShadow: 'none',
                    },
                },
            }}
        >
            <Sheet
                sx={{
                    borderRadius: 'md',
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    height: '100%',
                    overflow: 'auto',
                }}
            >
                <DialogTitle>Cluster</DialogTitle>
                <ModalClose />
                <Divider sx={{ mt: 'auto' }} />
                <DialogContent sx={{ gap: 2 }}>
                    <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input placeholder="Enter cluster name" />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Description</FormLabel>
                        <Input placeholder="Enter cluster description" />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Access IP/FQDN</FormLabel>
                        <Input placeholder="Enter access IP" />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Access Port</FormLabel>
                        <PortSelect />
                    </FormControl>

                    <Button color="neutral" variant="outlined">
                        <MultipleStopIcon />
                        Test
                    </Button>

                    <FormControl orientation="horizontal">
                        <Box sx={{ flex: 1, mt: 1, mr: 1 }}>
                            <FormLabel sx={{ typography: 'title-sm' }}>Add to Favs</FormLabel>
                            <FormHelperText sx={{ typography: 'body-sm' }}>
                                Check if you want this cluster in your favorites list.
                            </FormHelperText>
                        </Box>
                        <Switch />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Tags</FormLabel>
                        <Input placeholder="Enter tags" />
                    </FormControl>
                </DialogContent>

                <Divider sx={{ mt: 'auto' }} />
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    useFlexGap
                    spacing={1}
                >
                    <Button
                        variant="outlined"
                        color="neutral"
                        onClick={() => {
                            setType('')
                            setAmenities([])
                        }}
                    >
                        Clear
                    </Button>
                    <Button onClick={handleClose}>Save</Button>
                </Stack>
            </Sheet>
        </Drawer>
    )
}