import React from "react";
import PricingCard from "../cards/PricingCard";
// @ts-ignore
import bg from "../../assets/img/bg/cta-bg.jpeg";
import Link from "@mui/material/Link";

function Pricing() {
  return (
    <section className="aai-price-tables pb-120 py-5"
            id="get-started"
            style={{
            background: `url(${bg}) no-repeat center center/cover`,
    }}>
      <div className="container">
        <div
          className="row align-items-center mb-5"
          data-aos="fade-right"
          data-aos-delay="100"
        >
          <div className="col-xl-5">
            <div>
              <h2 className="section-title">
                The Right Plan for <span className="position-relative aai-cta-line z-3">DevOps Heros</span>
              </h2>
            </div>
          </div>
          <div className="col-xl-7">
            <div>
              <p className="section-desc">
                We have several powerful plans to accelerate your innovation 
                as Developer and productivity as Operator, to &nbsp;
                <span className="gradient-text">become a DevOps hero</span>. 
                <br/>Choose the one that fits your needs and get started.
              </p>
              <p className="section-desc">
                <br />&nbsp;
                You can also <Link className="aai-footer-nav-link" href="https://www.buymeacoffee.com/larebelion">
                  add caffeine</Link> to my nights and weekends while in Beta. ☕
              </p>
              
            </div>
          </div>
        </div>
        <PricingCard
          options={[
            "Access to Terminal",
            "K1s Serverless while in Alpha",
            "Up to 3 clusters",
          ]}
          title="Sidekick"
          desc="Ideal for Solo-developers and small teams taking their first steps into DevOps."
          status="Free"
          btnClass="aai-btn btn-pill-solid"
          href="/register"
        />
        <PricingCard
          options={[
            "Access to Terminal",
            "Up to 3 Users",
            "Up to 10 clusters",
            "Collaborate with other colleagues",
            "Email support",
          ]}
          title="Champion"
          desc="Designed for growing teams ready to take their DevOps efforts to the next level."
          // status="$29.99"
          status="TBD after Beta"
          // btnClass="aai-btn btn-pill-solid"
          href="https://www.buymeacoffee.com/larebelion"
        />
        <PricingCard
          options={[
            "Access to Terminal",
            "Up to 10 Users",
            "Unlimited clusters",
            "Collaborate with other colleagues",
            "QBot, your Kubepilot assistant",
            "Email and Chat Support",
          ]}
          title="DevOps Hero"
          desc="Perfect for growing teams and medium-sized businesses looking to level up their DevOps game."
          // status="$99.99"
          status="TBD"
          href="https://www.buymeacoffee.com/larebelion"
        />
        <PricingCard
          options={[
            "Includes all DevOps Hero features",
            "SOPs & Knowledge Management",
            "Dedicated Account Management", 
            "24/7 Premium Support.",
          ]}
          title="League of Justice"
          desc="Tailored for enterprise-level organizations with complex infrastructure and high-performance requirements."
          status="Custom Pricing"
          href="https://www.buymeacoffee.com/larebelion"
        />
        <PricingCard
          options={[
            // "Dedicated training sessions: Starting from $500/session",
            // "Custom integrations: Pricing upon request",
            "Dedicated training sessions",
            "Custom integrations",
            "RBAC & SSO",
            // "helm support",
          ]}
          title="Addons"
          desc="Assign & track your team's progress visually"
          status="Upon request"
          href="https://www.buymeacoffee.com/larebelion"
        />
      </div>
    </section>
  );
}

export default Pricing;
