import React from "react"
import IFrameForm from "../components/IFrameFormEarlyAccess"
import * as amplitude from '@amplitude/analytics-browser'

const ChallengesPage = (): JSX.Element => {
    // initialize amplitude with user's api key on app load
    React.useEffect(() => {
      amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY!, {
        defaultTracking: true,
      });
      // .catch((e: Error) => console.log(e));
      amplitude.track('Page View');
    }, []);
    return (
        <div>
            <h1>Challenges</h1>
            <IFrameForm src="https://go.rebelion.la/k1s-t" width="100%" height="700px" />
        </div>
    );
}

export default ChallengesPage