import React, { SyntheticEvent, useEffect, useState } from "react"
import { makeStyles } from '@mui/styles'
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles'

import MuiAlert, { AlertColor } from '@mui/material/Alert'

import Layout from "../components/cluster/Layout"
import Navigation from "../components/cluster/Navigation"
import {
  AccordionGroup,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  ChipDelete,
  CssBaseline,
  CssVarsProvider,
  Divider,
  Dropdown,
  IconButton,
  List,
  ListItem,
  ListItemContent,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Radio,
  RadioGroup,
  Sheet,
  Slider,
  Snackbar,
  Tooltip,
  Typography
} from "@mui/joy"
import Accordion from '@mui/joy/Accordion'
import AccordionDetails, {
  accordionDetailsClasses,
} from '@mui/joy/AccordionDetails'
import AccordionSummary, {
  accordionSummaryClasses,
} from '@mui/joy/AccordionSummary'

import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import StorageIcon from '@mui/icons-material/StorageRounded'
import TokenIcon from '@mui/icons-material/Token'
import ViewInArIcon from '@mui/icons-material/ViewInAr'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import CloudSyncIcon from '@mui/icons-material/CloudSync'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import ClusterDrawer from "../components/cluster/ClusterDrawer"

import { Cluster, ClusterExt, ClusterDAO } from '../model/ClusterModel'
import { useServer } from "../context/TerminalContext"
import { debug } from "../Util"
import { setServerInstance } from "../backend-integration/server-options/ServerOptions"
import { useUser } from "../context/AuthContext"
import { UserOptionsDAO } from "../model/UserOptionsModel"
import { SnackbarCloseReason } from "@mui/material/Snackbar"

// Use only in production mode
// import * as amplitude from '@amplitude/analytics-browser'
let amplitude: any
if (process.env.NODE_ENV === 'production') {
  amplitude = require('@amplitude/analytics-browser')
}

/*

@goodtoknow

JSX.Element is the return type of a React component. It represents a virtual DOM element that can be rendered to the page.
React.FC is a type that describes a functional component in React. It stands for "Function Component". It is a generic type that takes in a set of props and returns a JSX.Element.
So, JSX.Element is the type of the output of a component, while React.FC is a type of the component itself.
In general, you can use React.FC to define a functional component and JSX.Element to describe the output of a component.
However, there are some cases where you should not use React.FC. For example, if you want to use defaultProps or propTypes, you should use a function component instead of React.FC.
Also, if you want to use children props, you should use React.FC instead of a function component.
Finally, if you want to use a component as a prop, you should use React.FC instead of a function component.

Prompted by Adrian:
Generate a Page Component for the Clusters View with MUI, this is a Page with different Cards,
each card represents a Kubernetes cluster with the summary of each Cluster, shuch as Master 
Nodes, Worker nodes, name, access IP/FQDN status, state, number of Pods, resources (like Mem, 
CPUs, Storage). If the card space is not enough you can add a Collapsible with downward 
chevron to view the details. User can "star" favorites, so add a CardAction, in the top, 
the card header includes a MoreVert action button to allow the user edit or delete the cluster. 
Use a table or whaterver you consider better for excelent user experience. Be really creative to create something amazing!
*/

const theTheme = createTheme()

const useStyles = makeStyles((theTheme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  card: {
    width: "30%",
    //   marginBottom: theme.spacing(2),
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  table: {
    minWidth: 650,
  },
}))

const ClustersPage = (): JSX.Element => {
  const [expanded, setExpanded] = useState<number | null>(null)
  const [clusters, setClusters] = useState<ClusterExt[]>([])
  const [alertMessage, setAlertMessage] = useState({ message: '', severity: 'info' })
  const theme = useTheme()
  const classes = useStyles(theme)
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const { selectedServer, setSelectedServer } = useServer()
  const { user } = useUser()

  const handleExpandClick = (id: number) => {
    setExpanded(expanded === id ? null : id)
  }

  const handleFavoriteClick = (id: number) => {
    const index = clusters.findIndex((cluster: Cluster) => cluster.id === id)
    const newClusters = [...clusters]
    // update cache
    newClusters[index].isFavorite = !newClusters[index].isFavorite
    // update the database
    ClusterDAO.save(newClusters[index])
    setClusters(newClusters)
  }

  const handleDeleteClick = (id: number) => {
    const newClusters = clusters.filter((cluster: Cluster) => cluster.id !== id)
    //@fixme - this is not working, the clusters are not re-rendering as per the new state
    setClusters(newClusters)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  // initialize amplitude with user's api key on app load
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY!, {
        defaultTracking: true,
      })
      // .catch((e: Error) => console.log(e))
      amplitude.track('Page View')
    }
    const clustersFromDB = async () => {
      const theClusters = await ClusterDAO.getClusters()
      setClusters(theClusters)
    }
    clustersFromDB()
  }, [])

  // useEffect for selectedServer, when the user selects/change server
  useEffect(() => {
    const fetchDefaultCluster = async () => {
      // THE default server MUST be defined in the database, it is part of the initial data
      const theCluster = await ClusterDAO.getDefaultCluster()
      setSelectedServer(theCluster!)
      debug('👀 Using the default Server ', theCluster!.serverName)
    }
    // if selectedServer is null or undefined, then fetch the default server
    if (!selectedServer) {
      fetchDefaultCluster()
    }
    // cascade the selected server to the backend
    setServerInstance(selectedServer)
    console.log('👀 selectedServer', selectedServer)
    // save the selected server to the database, we don't know the namespace yet, so clear it
    UserOptionsDAO.save(new UserOptionsDAO(user!.username, selectedServer.id as number))
  }, [selectedServer])

  /**
   * Invoked when the user clicks on the "Use" button of a cluster card
   * @param clusterName the name of the cluster that the user wants to use
   */
  async function handleUseClick(clusterName: string): Promise<void> {
    const clickedCluster = await ClusterDAO.getClusterByName(clusterName)
    // if the selected cluster is not null and is not the same as the current selected cluster
    if (clickedCluster && clickedCluster !== selectedServer) {
      // update the state to Connected, not InUse anymore
      selectedServer.connectionStatus = 'Connected'
      await ClusterDAO.save(selectedServer)
      // update the selected server in the database
      clickedCluster.connectionStatus = 'InUse'
      await ClusterDAO.save(clickedCluster)
      setSelectedServer(clickedCluster)

      // update the cache
      const newClusters = [...clusters]
      // find the cluster by name in the cache
      let index = newClusters.findIndex((cluster: Cluster) => cluster.name === clusterName)
      // update cache
      newClusters[index].connectionStatus = 'InUse'
      // update the selected server in the cache
      index = newClusters.findIndex((cluster: Cluster) => cluster.name === selectedServer.name)
      newClusters[index].connectionStatus = 'Connected'
      setClusters(newClusters)
      setAlertMessage({ message: `Using the selected cluster ${clusterName}`, severity: 'success' })
      // ensuring we have only one InUse cluster, otherwise, "flusing" the InUse status in the other clusters
      const inUseClusters = await ClusterDAO.findByConnectionStatus('InUse')
      if (inUseClusters.length > 1) {
        setAlertMessage({ message: `More than one cluster is in use, running a cleanup`, severity: 'warning' })
        inUseClusters.forEach(async (cluster) => {
          if (cluster.id !== clickedCluster.id) {
            cluster.connectionStatus = 'Connected'
            await ClusterDAO.save(cluster)
          }
        })
      }
    }
    else {
      const msgStr = '❌ error getting the selected cluster ' + clusterName
      console.error(msgStr)
      setAlertMessage({ message: msgStr, severity: 'error' })
    }
  }

  function handleAlertMessageClose(event: Event | SyntheticEvent<any, Event> | null, reason: SnackbarCloseReason): void {
    setAlertMessage(prevState => ({ ...prevState, message: '' }))
  }

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Layout.Root sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        {/* Not used for now, until we add more core features */}
        <Layout.SideNav style={{ display: 'none' }}>
          <Navigation />
        </Layout.SideNav>
        {/* Filter Pane */}
        <Layout.SidePane style={{ display: 'none' }}>
          <Box
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography level="title-md">Filters</Typography>
            <Button size="sm" variant="plain">
              Clear
            </Button>
          </Box>
          <AccordionGroup
            sx={{
              [`& .${accordionDetailsClasses.content}`]: {
                px: 2,
              },
              [`& .${accordionSummaryClasses.button}`]: {
                px: 2,
              },
            }}
          >
            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography level="title-sm">Keywords</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ my: 2 }}>
                  <Autocomplete
                    size="sm"
                    placeholder="Node characteristics"
                    options={[
                      {
                        category: 'Node Type',
                        title: 'Master Node',
                      },
                      {
                        category: 'Node Type',
                        title: 'Worker Node',
                      },
                      {
                        category: 'Tag',
                        title: 'Dev',
                      },
                      {
                        category: 'Tag',
                        title: 'Prod',
                      },
                      {
                        category: 'Tag',
                        title: 'Staging',
                      },
                      {
                        category: 'Tag',
                        title: 'QA',
                      },
                      {
                        category: 'Tag',
                        title: 'East',
                      },
                      {
                        category: 'Tag',
                        title: 'West',
                      },
                    ]}
                    groupBy={(option) => option.category}
                    getOptionLabel={(option) => option.title}
                  />
                  <Box sx={{ my: 2, display: 'flex', gap: 1 }}>
                    <Chip
                      variant="soft"
                      size="sm"
                      endDecorator={<ChipDelete variant="soft" />}
                    >
                      Worker Node
                    </Chip>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography level="title-sm">Node type</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ my: 2 }}>
                  <RadioGroup name="nodeType" defaultValue="any">
                    <Radio label="Any" value="any" size="sm" />
                    <Radio label="Control Plane" value="master" size="sm" />
                    <Radio label="Data Plane" value="worker" size="sm" />
                  </RadioGroup>
                </Box>
                <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column' }}>
                  <Typography level="title-sm">Pods</Typography>
                  <Slider
                    size="sm"
                    variant="solid"
                    valueLabelFormat={(value) => `${value} pods`}
                    defaultValue={[300, 600]}
                    step={10}
                    min={0}
                    max={1000}
                    valueLabelDisplay="on"
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          </AccordionGroup>
        </Layout.SidePane>
        {/* Main Content */}
        <Layout.Main sx={{ width: '100%' }}>
          <Snackbar
            variant="outlined"
            color="primary"
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={alertMessage.message !== ''}
            autoHideDuration={6000}
            onClose={handleAlertMessageClose}
          >
            {/* <MuiAlert severity={alertMessage.severity as AlertColor ?? 'info'} elevation={6} variant="filled"> */}
              {alertMessage.message}
            {/* </MuiAlert> */}
          </Snackbar>

          <Box
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography level="title-lg" textColor="text.secondary">
              Clusters | {selectedServer?.serverName || 'No server selected'}
            </Typography>
            <Button startDecorator={<StorageIcon />} size="sm" onClick={() => setDrawerOpen(true)}>
              Add new
            </Button>
          </Box>
          <List
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
              gap: 2,
            }}
          >
            {clusters.map((cluster, index) => (
              <Sheet
                key={index}
                component="li"
                variant="outlined"
                sx={{
                  borderRadius: 'sm',
                  p: 2,
                  listStyle: 'none',
                  backgroundColor: cluster.id === selectedServer.id ? 'primary.100' : 'background.paper',
                  borderColor: cluster.id === selectedServer.id ? 'rebeccapurple' : 'background.paper',
                }}
              >
                <Box sx={{
                  display: 'flex', gap: 2, alignItems: 'center', mb: 2,
                }}>
                  <Avatar
                    variant="outlined"
                    src={cluster.avatar2x}
                    srcSet={`${cluster.avatar2x} 2x`}
                    sx={{ borderRadius: '50%', backgroundColor: '#BAC1E7' }}
                  />
                  <div>
                    <Typography level="title-md">
                      {/* Only display the ID in dev mode */}
                      {process.env.NODE_ENV !== 'production' ? cluster.id : ''} {cluster.name}
                    </Typography>
                    <Typography level="body-xs">{cluster.description!}</Typography>
                    <Box sx={{ mt: 1.5, display: 'flex', gap: 1 }}>
                      {(cluster.isFavorite && (
                        <IconButton
                          size="sm"
                          variant="plain"
                          color="primary"
                          onClick={() => handleFavoriteClick(cluster.id as number)}
                        >
                          <StarRoundedIcon />
                        </IconButton>
                      ))}
                      {/* ONLY one should be 'InUse' at a time, but more than one can be connected, meaning there is a token or credential provided */}
                      {(selectedServer.id === cluster.id && cluster.connectionStatus === 'InUse' && (
                        <Tooltip title="In use">
                          <IconButton
                            size="sm"
                            variant="plain"
                            color="primary"
                            onClick={() => handleFavoriteClick(cluster.id as number)}
                          >
                            <CloudSyncIcon />
                          </IconButton>
                        </Tooltip>
                      ))}
                      {(cluster.connectionStatus === 'Connected' && (
                        <Tooltip title="Connected">
                          <IconButton
                            size="sm"
                            variant="plain"
                            color="primary"
                            onClick={() => handleFavoriteClick(cluster.id as number)}
                          >
                            <ElectricalServicesIcon />
                          </IconButton>
                        </Tooltip>
                      ))}
                    </Box>
                  </div>
                  <Box sx={{ ml: 'auto' }}>
                    <Dropdown>
                      <MenuButton
                        variant="plain"
                        size="sm"
                        sx={{
                          maxWidth: '32px',
                          maxHeight: '32px',
                          borderRadius: '9999999px',
                          marginLeft: 'auto',
                        }}
                      >
                        <IconButton
                          component="span"
                          variant="plain"
                          color="neutral"
                          size="sm"
                        >
                          <MoreVertRoundedIcon />
                        </IconButton>
                      </MenuButton>
                      <Menu
                        placement="bottom-end"
                        size="sm"
                        sx={{
                          zIndex: '99999',
                          p: 1,
                          gap: 1,
                          '--ListItem-radius': 'var(--joy-radius-sm)',
                        }}
                      >
                        {/* if in use, then, implicitly is connected too */}
                        <MenuItem disabled={cluster.connectionStatus === 'Connected' || cluster.connectionStatus === 'InUse'}>
                          <ElectricalServicesIcon />
                          Connect cluster
                        </MenuItem>
                        <MenuItem disabled={cluster.connectionStatus === 'InUse'} onClick={() => handleUseClick(cluster.name as string)}>
                          <CloudSyncIcon />
                          Use cluster
                        </MenuItem>
                        {(!cluster.isFavorite && (
                          <MenuItem onClick={() => handleFavoriteClick(cluster.id as number)}>
                            <StarRoundedIcon />
                            Add to favorites
                          </MenuItem>
                        ))}
                        <MenuItem>
                          <EditRoundedIcon />
                          Rename cluster
                        </MenuItem>
                        <MenuItem>
                          <ShareRoundedIcon />
                          Share cluster
                        </MenuItem>
                        <MenuItem sx={{ textColor: 'danger.500' }} onClick={() => handleDeleteClick(cluster.id as number)}>
                          <DeleteRoundedIcon color="error" />
                          Delete cluster
                        </MenuItem>
                      </Menu>
                    </Dropdown>
                  </Box>
                </Box>
                <Divider component="div" sx={{ my: 2 }} />
                <List sx={{ '--ListItemDecorator-size': '40px', gap: 2 }}>
                  {/* Master nodes */}
                  <ListItem key={`master${cluster.id}`} sx={{ alignItems: 'flex-start' }}>
                    <ListItemDecorator
                      sx={{
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          height: '100%',
                          width: '1px',
                          bgcolor: 'divider',
                          left: 'calc(var(--ListItem-paddingLeft) + 12px)',
                          top: '50%',
                        },
                      }}
                    >
                      <Avatar
                        alt="Master nodes"
                        sx={{ '--Avatar-size': '24px' }}
                      >
                        <TokenIcon />
                      </Avatar>
                    </ListItemDecorator>
                    <ListItemContent>
                      <Typography level="title-sm">Master Nodes</Typography>
                      <Typography level="body-xs">{cluster.state}</Typography>
                    </ListItemContent>
                    <Typography level="body-xs">{cluster.masterNodes}</Typography>
                  </ListItem>
                  {/* Worker nodes */}
                  <ListItem key={`worker${cluster.id}`} sx={{ alignItems: 'flex-start' }}>
                    <ListItemDecorator
                      sx={{
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          height: '100%',
                          width: '1px',
                          bgcolor: 'divider',
                          left: 'calc(var(--ListItem-paddingLeft) + 12px)',
                          top: '50%',
                        },
                      }}
                    >
                      <Avatar
                        alt="Worker nodes"
                        sx={{ '--Avatar-size': '24px' }}
                      >
                        <ViewInArIcon />
                      </Avatar>
                    </ListItemDecorator>
                    <ListItemContent>
                      <Typography level="title-sm">Worker Nodes</Typography>
                      <Typography level="body-xs">{cluster.state}</Typography>
                    </ListItemContent>
                    <Typography level="body-xs">{cluster.workerNodes}</Typography>
                  </ListItem>
                </List>
                <Button
                  size="sm"
                  variant="plain"
                  endDecorator={<KeyboardArrowRightRoundedIcon fontSize="small" />}
                  sx={{ px: 1, mt: 1 }}
                >
                  Details
                </Button>
                <Divider component="div" sx={{ my: 2 }} />
                <Typography level="title-sm">Tags:</Typography>
                <Box sx={{ mt: 1.5, display: 'flex', gap: 1 }}>
                  {cluster.tags.map((tag, tagIndex) => (
                    <Chip
                      key={tagIndex}
                      variant="outlined"
                      color="neutral"
                      size="sm"
                    >
                      {tag}
                    </Chip>
                  ))}
                </Box>
              </Sheet>
            ))}
          </List>
        </Layout.Main>
        {/* Add Cluster Drawer */}
        <ClusterDrawer
          open={drawerOpen}
          onClose={handleDrawerClose}
          anchor="right"
        />
      </Layout.Root>

    </CssVarsProvider>
  )
}

export default ClustersPage
