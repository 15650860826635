import db from '../DBConfig'

/*
With this schema, we can easily query the database for commands based on their type, 
verb, and resource. For example, to get all kubectl commands that operate on pods, 
you can use the following query:
```ts
const commands = await db.commands
  .where('type')
  .equals('kubectl')
  .and((command) => command.resource === 'pod')
  .toArray()
```
*/

export interface Command {
    id?: number
    type: string       // type represents the type of command (e.g. kubectl, helm, kustomize)
    verb: string       // verb represents the verb of the command (e.g. get, describe, create, delete)
    resource: string   // resource represents the resource of the command (e.g. pod, deployment, service)
    namespace?: string // namespace represents the namespace of the command (e.g. default, kube-system)
    args: string[]     // args represents the arguments of the command (e.g. pod name, deployment name, service name)
    command: string      // command represents the full command (e.g. kubectl get pods)
    createdAt: Date
}

// to filter commands by type
export interface FilterProps {
    type?: string
    resource?: string
    verb?: string
    namespace?: string
}

export class CommandDAO implements Command {
    id?: number | undefined
    type: string = ''
    verb: string = ''
    resource: string = ''
    namespace?: string | undefined
    args: string[] = []
    command: string = ''
    createdAt: Date = new Date()
    description: string = ''
    handler: string = ''

    constructor(command: Command) {
        Object.assign(this, command)
    }
    // Prototype method
    save() {
        return db.commands.put(this) // Will only save own props.
    }

    public static async getCommandsFiltered(filter: FilterProps): Promise<Command[]> {
        try {
            if (!db.commands) { // Check if the db.commands object is defined, just in case
              return [];
            }
            // called when the app is loaded, then ! means that the commands table is not null or undefined (at runtime)
            return db.commands!
                .where(filter)
                .limit(10) // Limit the results to the first 10 records
                .toArray()
        } catch (error) {
            console.error('❌ error getting commands', error)
            return []
        }
    }
}