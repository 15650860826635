import React, { createContext, useState, useContext, useEffect } from 'react'
import { ClusterExt, ClusterDAO } from '../model/ClusterModel'

/**
 * ServerContext is the React Context that we use to manage the server and connection context related state.
 * It is used to pass the selected server to the terminal.
 *      ServerContext is the context that will hold the selected server.
 *      ServerProvider holds the state for the selected server and provides a function to update it.
 *      useServer is a custom hook that components can use to access the selected server and the function to update it.
 */

interface ServerContextProps {
    selectedServer: ClusterExt
    setSelectedServer: (server: ClusterExt) => void
}

const ServerContext = createContext<ServerContextProps | undefined>(undefined)

export const ServerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [selectedServer, setSelectedServer] = useState<ClusterExt>(null as unknown as ClusterExt)
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)        
        ClusterDAO.getDefaultCluster().then((cluster) => {
            setSelectedServer(cluster)
            setIsLoading(false)
        })
    }, [])  // after first render, let's set the default server

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <ServerContext.Provider value={{ selectedServer, setSelectedServer }}>
            {children}
        </ServerContext.Provider>
    )
}

export const useServer = (): ServerContextProps => {
    const context = useContext(ServerContext)
    if (!context) {
        throw new Error('useServer must be used within a ServerProvider')
    }
    return context
}