import React from 'react'
import { lazy } from 'react'

// project import
import Loadable from '../components/Loadable'
import MinimalLayout from '../layout/MinimalLayout'
import AboutPage from '../pages/AboutPage'

// render - login
const AuthLogin = Loadable(lazy(() => import('../pages/authentication/LoginPage')))

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,

  children: [
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'register',
      element: <AuthLogin />
    },
    {
      path: 'about',
      element: <AboutPage />
    }
  ]
}

export default LoginRoutes
