import { debug } from '../Util'
import db from '../DBConfig'

// interface to store the clusters information in the database
export interface Cluster {
    id?: number
    name?: string
    serverName?: string
    protocol: string
    hostname: string
    port: number
    path: string | undefined
    createdAt?: Date
    updatedAt?: Date
    // auth method by default is 'none' (no authentication)
    authMethod?: Auth | undefined
}

// valid authentication methods
export type AuthMethod = 'basic' | 'token' | 'clientCertificate' | 'none'

// parent interface to store the valid authentication methods
export interface Auth {
    id?: number
    // a name to identify the certificate
    name: string
    createdAt?: Date
    updatedAt?: Date
}

// interface to store the SSL client certificates information in the database (base64 encoded)
export interface SSLClientCertificate extends Auth {
    // Client Certificate
    certificate: string
    // Client Key
    key: string
    // Certificate Authority
    ca: string
}

// interface to store the clusters extended information in the database
export interface ClusterExt extends Cluster {
    description?: string
    avatar2x: string
    masterNodes: number | 1
    workerNodes: number | 1
    port: number | 6443
    status: string
    state: string
    // ONLY one cluster should be 'InUse' at a time, but more than one can be connected, 
    // meaning there is a token or credential provided
    connectionStatus: string | 'Connected' | 'Disconnected' | 'InUse'
    pods: number | 0
    memory: string | '0 GB'
    cpu: string | '0 vCPU'
    storage: string | '0 GB'
    isFavorite: boolean | false
    tags: string[] | []
}

/**
 * function that takes a partial ClusterExt object as an argument and merges it with the default values
 * @param overrides optional properties to override the default values
 * @returns 
 */
export function createDefaultClusterExt(overrides: Partial<ClusterExt> = {}): ClusterExt {
    const defaultClusterExt: ClusterExt = {
        name: 'docker-desktop',
        serverName: 'docker-desktop',
        protocol: 'https',
        hostname: 'kubernetes.docker.internal',
        port: 6443,
        path: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        avatar2x: 'https://www.vectorlogo.zone/logos/kubernetes/kubernetes-icon.svg',
        masterNodes: 1,
        workerNodes: 1,
        status: 'Unknown',
        state: 'Unknown',
        pods: 0,
        connectionStatus: 'Disconnected',
        memory: '0 GB',
        cpu: '0 vCPU',
        storage: '0 GB',
        isFavorite: false,
        tags: [],
    }

    return { ...defaultClusterExt, ...overrides }
}
export function createDefaultCluster(overrides: Partial<Cluster> = {}): Cluster {
    const defaultCluster: Cluster = {
        name: 'k8s-local',
        serverName: 'k8s-local',
        protocol: 'http',
        hostname: 'localhost',
        port: 8080,
        path: '',
        createdAt: new Date(),
        updatedAt: new Date(),
    }

    return { ...defaultCluster, ...overrides }
}
const defaultCluster = async (): Promise<ClusterExt> => {
    let cluster: ClusterExt | undefined
    // #region production
    if (process.env.NODE_ENV === 'production') {
        try {
            // k1s.me is the default server for production if user is not
            cluster = await ClusterDAO.getClusterByName('k1s')
        }
        catch (error) {
            console.error('⚠️ error getting clusters, using a default one', error)
        }        
        return cluster || createDefaultClusterExt({
                name: 'k1s',
                serverName: 'k1s',
                protocol: 'https',
                hostname: 'api.k1s.me',
                port: 443,
                path: '/kubernetes',
            })
    }
    // #endregion production
    // #region development server
    try {
        cluster = await ClusterDAO.getClusterByName('k1s')
    }
    catch (error) {
        console.error('⚠️ error getting clusters, using a default one', error)
    }
    return cluster || createDefaultClusterExt({
        name: 'k8s-local',    // through nginx proxy manager (to handle CORS)
        serverName: 'k8s-local',    // through nginx proxy manager (to handle CORS)
        protocol: 'http',
        hostname: 'localhost',
        port: 8080,
    })
    // #endregion development server
}

export class ClusterDAO implements ClusterExt {
    // #region ClusterExt properties (from ClusterExt interface)
    id?: number | undefined
    name?: string | undefined
    serverName?: string | undefined
    protocol: string = ''
    hostname: string = ''
    port: number = 0
    path: string = ''
    createdAt?: Date = new Date()
    updatedAt?: Date = new Date()
    // extended properties, added to the Cluster interface with default values
    description?: string | undefined
    avatar2x: string = 'https://www.vectorlogo.zone/logos/kubernetes/kubernetes-icon.svg'
    masterNodes: number = 1
    workerNodes: number = 1
    status: string = 'Unknown'
    state: string = 'Unknown'
    connectionStatus: string = 'Disconnected'
    pods: number = 0
    memory: string = '0 GB'
    cpu: string = '0 vCPU'
    storage: string = '0 GB'
    isFavorite: boolean = false
    tags: string[] = []
    // #endregion ClusterExt properties

    constructor(cluster: Cluster, extended: Partial<ClusterExt> = {}) {
        Object.assign(this, cluster, extended)
    }
    // Prototype method
    public static async save(theCluster: ClusterExt) {
        try {
        return await db.clusters.put(theCluster) // Will only save own props
      } catch (error: any) {
        if (error.name === 'ConstraintError') {
          debug('Cluster already exists. Updating the selected user options', 'ConstraintError (ignored)')
          // update the existing object (based on username)
          return db.userOptions.where('id').equals(theCluster.id!).modify(theCluster)
        }
      }
    }

    public static async getClusters(): Promise<ClusterExt[]> {
        try {
            if (!db.clusters) { // Check if the db.clusters object is defined, just in case
                return []
            }
            // called when the app is loaded, then ! means that the clusters table is not null or undefined (at runtime)
            return db.clusters!
                .limit(20) // Limit the results to the first 10 records
                .toArray()
        } catch (error) {
            console.error('❌ error getting clusters', error)
            return []
        }
    }
    public static async getClusterByName(name: string | undefined): Promise<ClusterExt | undefined> {
        try {
            if (!db.clusters) { // Check if the db.clusters object is defined, just in case
                return undefined
            }
            // if name is undefined, we return the default cluster
            if (!name) {
                return ClusterDAO.getDefaultCluster()
            }
            // called when the app is loaded, then ! means that the clusters 
            // table is not null or undefined (at runtime)
            return db.clusters!
                .where('name').equals(name)
                .first()
        } catch (error) {
            console.error('❌ error getting clusters for name "%s"', name, error)
            return undefined
        }
    }
    public static async findByConnectionStatus(connectionStatus: string): Promise<ClusterExt[]> {
        try {
            if (!db.clusters) { // Check if the db.clusters object is defined, just in case
                return []
            }
            // called when the app is loaded, then ! means that the clusters table is not null or undefined (at runtime)
            return db.clusters
                .where('connectionStatus').equals(connectionStatus)
                .toArray()
        } catch (error) {
            console.error('❌ error getting clusters for connectionStatus "%s"', connectionStatus, error)
            return []
        }
    }
    static getDefaultCluster(): Promise<ClusterExt> {
        return defaultCluster()
    }
}