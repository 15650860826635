import React, { lazy } from 'react'

import Loadable from '../components/Loadable'
import MainLayout from '../layout/MainLayout'
import PrivateRoute from './PrivateRoute'

// render - Home
const TerminalPage = Loadable(lazy(() => import('../pages/TerminalPage')))
const HomePage = Loadable(lazy(() => import('../pages/HomePage')))

// render - Features
const ChallengesPage = Loadable(lazy(() => import('../pages/ChallengesPage')))
const ClustersPage = Loadable(lazy(() => import('../pages/ClustersPage')))

// render - utilities

const SettingsPage = Loadable(lazy(() => import('../pages/SettingsPage')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      index: true,
      element: <TerminalPage />
    },
    {
      path: 'challenges',
      element: (
        <PrivateRoute roles={[]}>
          <ChallengesPage />
        </PrivateRoute>
      )
    },
    {
      path: 'clusters',
      element: <ClustersPage />
    },
    {
      path: 'favorites',
      element: <HomePage />
    },
    {
      path: 'teams',
      element: <HomePage />
    },
    {
      path: 'recents',
      element: <HomePage />
    },
    {
      path: 'settings',
      element: (
        <PrivateRoute roles={['admin']}>
          <SettingsPage />
        </PrivateRoute>
      )
    }
  ]
}

export default MainRoutes
