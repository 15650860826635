import db from '../DBConfig'
import { clustersData } from '../data'
import { debug } from '../Util'

export async function populate() {    
    // db.version(2).stores({
    //   kubernetesOutput: '++id, line, [kind, name&]',
    // }).upgrade(tx => {
    //   console.log('📦 upgrade database %s', tx)
    //   tx.table("kubernetesOutput").toCollection().modify(kubernetesOutput => {
    //     // cleaning the line number
    //     kubernetesOutput.line = -1
    //   })
    // })
    // check if we need to init the database (first time for testing)
    await db.commands.count().then(count => {
        if (count === 0 && process.env.REACT_NODE_ENV !== 'production') {
            db.commands.bulkAdd([
                { type: 'kubectl', verb: 'get', resource: 'pods', args: [], command: 'kubectl get pods', createdAt: new Date() },
                { type: 'kubectl', verb: 'get', resource: 'pods', args: ['--all-namespaces'], command: 'kubectl get pods --all-namespaces', createdAt: new Date() },
                { type: 'kubectl', verb: 'get', resource: 'pods', args: ['--all-namespaces', '-o', 'wide'], command: 'kubectl get pods --all-namespaces -o wide', createdAt: new Date() },
                { type: 'kubectl', verb: 'get', resource: 'pods', args: ['--all-namespaces', '-o', 'wide', '--show-labels'], command: 'kubectl get pods --all-namespaces -o wide --show-labels', createdAt: new Date() },
                { type: 'kubectl', verb: 'apply', resource: '-f', args: ['https://k8s.io/examples/application/guestbook/redis-master-deployment.yaml'], command: 'kubectl apply -f https://k8s.io/examples/application/guestbook/redis-master-deployment.yaml', createdAt: new Date() },
                { type: 'helm', verb: 'list', resource: '', args: [], command: 'helm list', createdAt: new Date() },
                { type: 'helm', verb: 'list', resource: '', args: ['--all'], command: 'helm list --all', createdAt: new Date() },
                { type: 'helm', verb: 'install', resource: '', args: ['stable/nginx-ingress'], command: 'helm install stable/nginx-ingress', createdAt: new Date() },
            ])
        }
    })
    await db.clusters.count().then(count => {
        if (count === 0 && process.env.REACT_NODE_ENV !== 'production') {
            debug('📦 populate clusters 💻')
            db.clusters.bulkAdd(clustersData)
        }
    })
}
