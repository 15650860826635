import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/joy/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'

import MenuIcon from '@mui/icons-material/Menu'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import LoginIcon from '@mui/icons-material/Login'
import CloudCircleOutlinedIcon from '@mui/icons-material/CloudCircleOutlined'
import InfoIcon from '@mui/icons-material/InfoOutlined'

import { LearnByExamplesDrawerProps } from '../../components/LearnByExamplesDrawer'
import { Divider } from '@mui/material'
import K1sAppContext from '../../AppContext'
import { useUser } from '../../context/AuthContext'
import Cookies from 'js-cookie'
import { unsetToken } from '../../context/AuthHelper'
import { useNavigate } from 'react-router-dom'

const settings = [['Profile', '/profile'], ['Account', '/profile'], ['🧑🏽‍💻 Terminal', '/']]

const K1sAppBar = (): JSX.Element => {
  const theme = useTheme()
  const { isHistoryDrawerOpen,
    isBotDrawerOpen,
    drawerBotProps,
    drawerHistoryProps
  } = React.useContext(K1sAppContext)
  const { isAuthenticated, setIsAuthenticated, setUser, user } = useUser()

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)
  const userMenuRef = React.useRef<HTMLButtonElement>(null)
  const navigate = useNavigate()

  React.useEffect(() => {
    if (Cookies.get('jwt')) {
      // @todo - check if the token is still valid
      setIsAuthenticated(true)
      setUser({ username: Cookies.get('username'), email: '', provider: '', id: Cookies.get('id') })
    }
  }, [])

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
    // setAnchorElUser(userMenuRef.current)
  }

  const getDrawer = (): LearnByExamplesDrawerProps => {
    const drawer = isBotDrawerOpen ? drawerBotProps() : isHistoryDrawerOpen ? drawerHistoryProps() : { drawerWidth: 0, open: false }
    return drawer
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogout = () => {
    setIsAuthenticated(false)
    unsetToken(navigate)
  }

  interface K1sAppBarProps extends AppBarProps {
    open?: boolean
  }
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<K1sAppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    ...(open && {
      width: `calc(100% - ${getDrawer().drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      // marginRight: getDrawer().drawerWidth,
    }),
  }))

  return (
    <AppBar position="static" open={isBotDrawerOpen || isHistoryDrawerOpen}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Avatar alt="K1s Terminal" src="/k1s-txt-180.png" sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          {/* <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            K1s
          </Typography> */}

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            {/* <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu> */}
          </Box>
          <CloudCircleOutlinedIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <b>K1s</b>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {/* <IFrameForm src="https://github.com/sponsors/la-rebelion/button" width="114" height="32" title="Sponsor la-rebelion" /> */}
            {/* @todo Placeholder in case we need a top menu */}
            {/* {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))} */}
            <Button 
              component="a" 
              variant='plain'
              key={'about'}
              href="/about"
              startDecorator={<InfoIcon/>}
              sx={{ m: 2, color: 'white', 
              '&:hover': {
                background: '#109BD1',
              }, }}
              >About K1s</Button>
          </Box>

            {/* Is user authenticated? {auth ? 'Yes' : 'No'} */}
          {isAuthenticated ? (
          <Box sx={{
                    flexGrow: 0,
                    position: 'relative', 
                  }}
                  ref={userMenuRef}
          >
            <Tooltip title="Open settings">
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ p: 0 }}
                ref={userMenuRef}
              >
                {/* <Avatar alt="Anonymous Usr" src="https://images.unsplash.com/photo-1569516449771-41c89ee14ca3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200" /> */}
                {/* <Avatar alt="Anonymous Usr" src="https://i.pravatar.cc/200" /> */}
                <Avatar alt="Anonymous Usr Avatar" sx={{ width: 32, height: 32 }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: getDrawer().drawerWidth + 100,
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Typography variant='body1' align='center' >
                <small>{ user!.username }</small>
              </Typography>
              <Divider />
              {settings.map((setting) => (
                <MenuItem key={setting[0]} onClick={handleCloseUserMenu} component="a" href={setting[1]}>
                  <Typography textAlign="center">{setting[0]}</Typography>
                </MenuItem>
              ))}
                <MenuItem key="logout" onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              <Divider />
              <Typography variant='body1' sx={{ display: { xs: 'none', md: 'flex' } }}>
                <small>{process.env.REACT_APP_VERSION} {process.env.NODE_ENV} mode</small>
              </Typography>
            </Menu>
          </Box>
          ) : (
            <>
            <Button 
              component="a" 
              href="/login"
              startDecorator={<LoginIcon/>}
              sx={{ outline: '1px solid yellow', m: 2,
              '&:hover': {
                background: '#109BD1',
              }, }}
              >Login</Button>
            <Button 
              component="a" 
              sx={{ 
                    my: 2, 
                    color: 'white', 
                    background: '#755EE7',
                    '&:hover': {
                      background: '#109BD1',
                    },
              }}
              href="/register"
              startDecorator={<PersonAddIcon/>}
            >Sign Up</Button>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default K1sAppBar