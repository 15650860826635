import { token } from "../../Util"
import { Cluster, ClusterDAO } from "../../model/ClusterModel"
import routes, { Route } from "./routes"
import { debug } from "../../Util"

// Interfaces based on reqs for fetch: https://developer.mozilla.org/en-US/docs/Web/API/fetch
// this module extends and uses the routes defined in the K1s API

/**
 * The options to use in the fetch call.
 * handler is the name of the key to use in the routes, with that we can get 
 * the path, method and extra headers for the call.
 * @todo - add support for credentials and mode
 */
interface FetchOptions {
    method: string,
    // credentials: string,
    // mode: string,
    path: string,
    handler: string,
    headers: {
        'Content-Type': string,
        'Authorization'?: string,
        'Accept': string,
        // 'Access-Control-Allow-Origin': string,
        // 'Access-Control-Allow-Methods': string,
        // 'Access-Control-Allow-Headers': string,
    },
}
const fetchHeaders: FetchOptions['headers'] = {
    'Content-Type': 'application/json',
    // 'Authorization': `Bearer ${token}`,
    'Accept': 'application/json',
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    // 'Access-Control-Allow-Headers': '*' //'Origin, X-Requested-With, Content-Type, Accept, Authorization',
}

let serverInstance: Cluster
ClusterDAO.getDefaultCluster().then((cluster) => {
    serverInstance = cluster
})

export const setServerInstance = (server: Cluster) => {
    serverInstance = server
}

const getSelectedServer = () => {
    if (!serverInstance) {
        console.error('No server selected - I am using the default server')
        ClusterDAO.getDefaultCluster().then((cluster) => {
            setServerInstance(cluster)
        })
        // throw new Error('No server selected - the request cannot be completed. Please select a server.')
    }
    debug(serverInstance, 'Server selected')
    return serverInstance
}

/**
 * Gets the resource and options to use for the fetch call
 * @param serverOptions The server to connect to
 * @param route the controller to connect to
 * @param params the parameters to use in the URL
 * @returns the URL resource to connect to as defined 
 *          in fetch (https://developer.mozilla.org/en-US/docs/Web/API/fetch) 
 *          and the options to use
 * 
 * @example
 * const namespace = 'my-namespace'
const name = 'my-name'
const path = 'my-path'

const url1 = replaceUrlParams('/api/v1/namespaces/:namespace/services/:name/proxy/:path', { namespace, name, path })
const url2 = replaceUrlParams('/api/v1/namespaces/:namespace/serviceaccounts/:name', { namespace, name })
const url3 = replaceUrlParams('/api/v1/namespaces/:namespace/secrets/:name', { namespace, name })
 */
export const getResourceAndOptions = (routeName: string, params?: { [key: string]: string }) => {
    const serverInstance = getSelectedServer()
    const route2Use = routes.map((route: Route) => route).find((route: Route) => route.handler === routeName)!
    const mappedPath = replaceUrlParams(route2Use.path, params)
    
    const serverPathPrefix = serverInstance.path ?? ''
    const options: FetchOptions = {
        method: route2Use.method,
        path: `${serverPathPrefix}${mappedPath}`,
        handler: route2Use.handler,
        headers: fetchHeaders,
    }
    // console.log(options, 'options')
    return { url: `${serverInstance.protocol}://${serverInstance.hostname}:${serverInstance.port}${options.path}`, options }
}

/**
 * Maps a parameterized URL to a static URL using the parameters provided (see this as a PreparedStatement in Java)
 * @param url the URL to replace the parameters in
 * @param params the parameters to use in the URL in the format { key: value }
 * @returns the mapped URL
 */
function replaceUrlParams(url: string, params?: { [key: string]: string }): string {
    if (!params) return url
    for (const [key, value] of Object.entries(params)) {
        url = url.replace(`:${key}`, value)
    }
    return url
}

/**
 * 
 * @param url the API endpoint to connect to
 * @param options the FetchOptions to use in the call
 * @returns the response from the server in JSON format
 */
export async function getDataFromServer(url: string, options: any) {
    const response = await fetch(url, { ...options })
    // was response OK?
    if (!response.ok) {
        response.text().then((text) => {
            console.log(text)
        })
        console.log(`Error: ${response.statusText} ${response.status} ${response.body}`)
        // @todo - handle error
        throw new Error(`HTTP error! status: ${response.status}`)
    }
    const data = await response.json()
    return data
}


