import { debug } from '../Util'
import db from '../DBConfig'

// Because we can have multiple users, we need to store the user options in the database
export interface UserOptions {
  id?: number
  username: string
  selectedCluster: number
  selectedNamespace?: string
  // placeholder for additional context, metadata, etc.
  additionalContext?: any
}

export class UserOptionsDAO implements UserOptions {
  id?: number | undefined
  username: string
  selectedCluster: number
  selectedNamespace?: string | undefined
  additionalContext?: any

  // username and selectedCluster are required
  constructor(username: string, selectedCluster: number, userOptions: Partial<UserOptions> = {}) {
    this.username = username
    this.selectedCluster = selectedCluster
    Object.assign(this, userOptions)
  }
  // Prototype method
  public static async save(theObject: UserOptions) {
    try {
      debug(`Saving user options ${JSON.stringify(theObject)}`)
      return await db.userOptions.put(theObject) // Will only save own props.
    } catch (error: any) {
      if (error.name === 'ConstraintError') {
        debug('User options already exists. Updating the selected user options', 'ConstraintError (ignored)')
        // update the existing object (based on username)
        return db.userOptions.where('username').equals(theObject.username).modify({
          selectedCluster: theObject.selectedCluster,
          selectedNamespace: theObject.selectedNamespace,
          additionalContext: theObject.additionalContext,
        })
      }
    }
  }
  public static async getUserOptions(username: string): Promise<UserOptions> {
    try {
      if (!db.userOptions) {
        // Check if the db.userOptions object is defined, just in case
        return {
          username: username,
          selectedCluster: 0,
        }
      }
      // called when the app is loaded, then ! means that the userOptions table is not null or undefined (at runtime)
      const userOptions = await db.userOptions
        .where('username')
        .equals(username)
        .first()
      if (userOptions) {
        return userOptions
      }
      return {
        username: username,
        selectedCluster: 0,
      }
    } catch (error) {
      console.error('❌ error getting user options %s', error)
      return {
        username: username,
        selectedCluster: 0,
      }
    }
  }
}