import React from "react";
import ProtoTypes from "prop-types";
import addUserIco from "../../assets/img/icons/Add-User.svg";
import homeIco from "../../assets/img/icons/Home.svg";
import walletIco from "../../assets/img/icons/Wallet.svg";
//import Image from "next/image";
import bg from "../../assets/img/bg/awards-bg.jpeg";

function WritingStep({ className }) {
  return (
    <section className={`writing-steps-area ${className}`}
    style={{
      background: `url(${bg}) no-repeat center center/cover`,
    }} >
      <div className="container">
        <div className="writing-steps-wrapper" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-4">
              <div className="writing-step">
                <img
                  height={addUserIco.height}
                  width={addUserIco.width}
                  src={addUserIco}
                  className="writing-step-icon"
                  alt=""
                />
                <h3 className="writing-step-title">Define Clusters</h3>
                <p className="writing-step-desc">
                  Define your clusters and manage your different environments with ease.
                </p>
                <div className="writing-step-arrow d-none d-lg-grid">
                  <svg
                    width="13"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L11.4211 9.52632L1 19"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="writing-step">
                <img
                  height={homeIco.height}
                  width={homeIco.width}
                  src={homeIco}
                  className="writing-step-icon"
                  alt=""
                />
                <h3 className="writing-step-title">KX (K8s eXperience)</h3>
                <p className="writing-step-desc">
                  A better experience for developers and operators, all in one place.
                  <br/>Both, UX and DX together for the best experience, the KX!
                </p>
                <div className="writing-step-arrow d-none d-lg-grid">
                  <svg
                    width="13"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L11.4211 9.52632L1 19"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="writing-step border-0 pe-0">
                <img
                  height={walletIco.height}
                  width={walletIco.width}
                  src={walletIco}
                  className="writing-step-icon"
                  alt=""
                />
                <h3 className="writing-step-title">Generate SOPs</h3>
                <p className="writing-step-desc">
                  Never worry about process documentation, team collaboration, and Knowledge Management again. We got you covered.
                  <br/>Abstraction of the complexity of the cloud, and the ability to manage your cloud resources with ease.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

WritingStep.propTypes = {
  className: ProtoTypes.string,
};

export default WritingStep;
