import React from "react";
import bg from "../../assets/img/bg/footer-bg.jpeg";
import headset from "../../assets/img/icons/headset.svg";
import logoF from "../../assets/img/logo/favicon-32x32.png";
import Link from "@mui/material/Link";
//import Image from "next/image";

function Footer({ handleModal }) {
  return (
    <footer
      className="aai-footer pt-120"
      style={{
        background: `url(${bg}) no-repeat center center/cover`,
      }}
    >
      <div className="container">
        <div className="aai-footer-support">
          <div className="row g-4 align-items-center">
            <div className="col-lg-6">
              <div className="aai-support-info d-flex align-items-center">
                <div>
                  <img
                    height={headset.height}
                    width={headset.width}
                    src={headset}
                    alt=""
                  />
                </div>
                <div className="aai-support-contact">
                  <p className="aai-support-text mb-1">
                    Have a question? Drop us a note
                  </p>
                  <Link className="nav-link" onClick={handleModal} >
                    {/* href="tel:+1707797 0462" */}
                    {/* +1 (707) 797 0462 */}
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="aai-newsletter">
                <form>
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your email"
                    />
                    <button className="aai-newsletter-btn">Subscribe</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="aai-footer-navigation-widgets py-80">
          <div className="row">
            <div
              className="col-xl-4 col-lg-4 col-md-12"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-delay="50"
            >
              <div className="aai-footer-info">
                <Link href="/" className="aai-footer-logo">
                  <img
                    height={logoF.height}
                    width={logoF.width}
                    src={logoF}
                    alt=""
                  />
                </Link>
                <p className="aai-footer-desc">
                  We provide one-stop solutions for DevOps. <br />
                  <b>K1s Terminal</b> is a platform that provides a complete solution
                </p>
                <div className="aai-social-links d-flex">
                  <a href="https://rebelion.la" target="_blank">
                    <i className="fas fa-blog"></i>
                  </a>
                  <a href="https://twitter.com/LaRebelionLabs" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                  <a href="https://www.youtube.com/@LaRebelion" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-youtube"></i>
                  </a>
                  <a href="https://www.linkedin.com/company/la-rebelion-labs" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-linkedin"></i>
                  </a>
                  <a href="https://k1s.me" target="_blank">
                    <i className="fas fa-server"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-12 mt-5 mt-lg-0">
              <div className="row">
                <div
                  className="col-xl-4 col-lg-4 col-md-4 mb-5 mb-lg-0"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <nav className="aai-footer-nav">
                    <h2 className="aai-footer-nav-title">Links</h2>
                    <ul className="aai-footer-nav-list">
                      <li className="aai-footer-nav-list-item">
                        <Link href="https://docs.k1s.me" className="aai-footer-nav-link">
                          K1s Docs
                        </Link>
                      </li>
                      <li className="aai-footer-nav-list-item">
                        <Link href="/" className="aai-footer-nav-link">
                          K1s Terminal
                        </Link>
                      </li>
                      <li className="aai-footer-nav-list-item">
                        <Link href="https://k1s.me" className="aai-footer-nav-link">
                          K1s Serverless
                        </Link>
                      </li>
                      <li className="aai-footer-nav-list-item">
                        <Link href="https://rebelion.la" className="aai-footer-nav-link">
                          Blog
                        </Link>
                      </li>
                      <li className="aai-footer-nav-list-item">
                        <Link href="https://apicove.com" className="aai-footer-nav-link">
                          API Cove
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                {/* <div
                  className="col-xl-4 col-lg-4 col-md-4 mb-5 mb-lg-0"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="1500"
                >
                  <nav className="aai-footer-nav">
                    <h3 className="aai-footer-nav-title">Artworks</h3>
                    <ul className="aai-footer-nav-list">
                      <li className="aai-footer-nav-list-item">
                        <Link href="/services" className="aai-footer-nav-link">
                          3D Artworks
                        </Link>
                      </li>
                      <li className="aai-footer-nav-list-item">
                        <Link href="/services" className="aai-footer-nav-link">
                          Photography
                        </Link>
                      </li>
                      <li className="aai-footer-nav-list-item">
                        <Link href="/services" className="aai-footer-nav-link">
                          PriFlat Illustrationcing
                        </Link>
                      </li>
                      <li className="aai-footer-nav-list-item">
                        <Link href="/services" className="aai-footer-nav-link">
                          Intro Videos
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div> */}
                <div
                  className="col-xl-4 col-lg-4 col-md-4"
                  data-aos="fade-up"
                  data-aos-delay="500"
                  data-aos-duration="1500"
                >
                  <nav className="aai-footer-nav">
                    <h3 className="aai-footer-nav-title">Community</h3>
                    <ul className="aai-footer-nav-list">
                      <li className="aai-footer-nav-list-item">
                        <Link href="#" className="aai-footer-nav-link">
                          Global Partners
                        </Link>
                      </li>
                      <li className="aai-footer-nav-list-item">
                        <Link href="#" className="aai-footer-nav-link">
                          Forum
                        </Link>
                      </li>
                      <li className="aai-footer-nav-list-item">
                        <Link href="https://www.linkedin.com/company/la-rebelion-labs" className="aai-footer-nav-link">
                          LinkedIn Page
                        </Link>
                      </li>
                      <li className="aai-footer-nav-list-item">
                        <Link href="#" className="aai-footer-nav-link">
                          Community
                        </Link>
                      </li>
                      {/* <li className="aai-footer-nav-list-item">
                        <Link href="/services" className="aai-footer-nav-link">
                          Virtual World
                        </Link>
                      </li>
                      <li className="aai-footer-nav-list-item">
                        <Link href="/services" className="aai-footer-nav-link">
                          Brand Assets
                        </Link>
                      </li> */}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="aai-footer-copyright text-center">
          <p className="aai-copyright-text">Copyright @{String(new Date().getFullYear())}: La Rebelion Labs.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
