import React, { Dispatch, SetStateAction } from 'react'
import { LearnByExamplesDrawerProps } from './components/LearnByExamplesDrawer'

// these are the props that we are going to use in the whole App context for the UI behavior
export interface AppContextType {
  dontShowWelcomeAgain: boolean
  setDontShowWelcomeAgain: Dispatch<SetStateAction<boolean>>
  llm?: string
  isHistoryDrawerOpen?: boolean
  isBotDrawerOpen: boolean
  setIsHistoryDrawerOpen?: Dispatch<SetStateAction<boolean>>
  setIsBotDrawerOpen: Dispatch<SetStateAction<boolean>>
  drawerHistoryProps: () => LearnByExamplesDrawerProps
  drawerBotProps: () => LearnByExamplesDrawerProps
  // @todo - define an enum for the drawer names (history, bot)... in case we want to add more drawer's types
  drawerNames: { [key: string]: (open?: boolean) => void }
}

const K1sAppContext = React.createContext<AppContextType>({
  dontShowWelcomeAgain: false,
  isHistoryDrawerOpen: false,
  isBotDrawerOpen: false,
  setIsHistoryDrawerOpen: () => { },
  setIsBotDrawerOpen: () => { },
  setDontShowWelcomeAgain: () => { },
  llm: 'CodeLlama',
  drawerHistoryProps: () => ({
    open: false,
    handleDrawerOpen: () => { },
    handleDrawerClose: () => { },
    drawerWidth: 0,
  }),
  drawerBotProps: () => ({
    open: false,
    handleDrawerOpen: () => { },
    handleDrawerClose: () => { },
    drawerWidth: 0,
  }),
  // depending on the drawer name, we can have different actions
  drawerNames: {},
})

export default K1sAppContext