import React from "react";
// @ts-ignore
import Hero from "../../component/homeOne/Hero";
// @ts-ignore
import WritingStep from "../../component/homeOne/WritingStep";
// @ts-ignore
import Service from "../../component/homeOne/Service";
// @ts-ignore
import Features from "../../component/homeOne/Features";
// @ts-ignore
import Awards from "../../component/homeOne/Awards";
// @ts-ignore
import Testimonials from "../../component/homeOne/Testimonials";
// @ts-ignore
import Pricing from "../../component/homeOne/Pricing";
// @ts-ignore
import Faq from "../../component/homeOne/Faq";
// @ts-ignore
import Cta from "../../component/homeOne/Cta";
import Header from "../../component/Header";
import Footer from "../../component/homeOne/Footer";
import ContactModal from "../../component/forms/ContactModal";
export const metadata = {
    title: "K1s",
    description: "K1s ",
    viewport: "width=device-width, initial-scale=1",
};
function Home() {
  const [open, setOpen] = React.useState(false);
  const handleLinkClick = (event) => {
    event.preventDefault();
    // always shown when link is clicked
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  }
  return (
    <>
      <Header handleModal={handleLinkClick} />
      <Hero />
      <WritingStep className="py-5" />
      {/* <Service /> */}
      <Features className="py-5" />
      {/* <Awards /> */}
      {/* <Testimonials className="py-5" /> */}
      <Pricing />
      <Faq />
      {/* <Cta /> */}
      <Footer handleModal={handleLinkClick} />
      {/* To display the contac form when links in "child" components are clicked */}
      <ContactModal isOpen={open} handleClose={handleClose} />
    </>
  );
}

export default Home;
